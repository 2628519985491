var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"updateEvent"},[_c('a-modal',{attrs:{"width":900,"visible":_vm.visible,"title":"Add event","onOk":"handleSubmit","keyboard":true,"maskClosable":false},on:{"change":_vm.onChangeModal}},[_c('template',{slot:"footer"},[_c('a-button',{key:"back",on:{"click":_vm.onCancel}},[_vm._v("Cancel")]),_c('a-button',{key:"submit",attrs:{"type":"primary","loading":_vm.loading},on:{"click":_vm.handleSubmit}},[_vm._v("Add")])],1),_c('a-form',{attrs:{"form":_vm.form},on:{"submit":_vm.handleSubmit}},[_c('a-row',{attrs:{"gutter":16}},[_c('a-col',{attrs:{"span":8}},[_c('a-form-item',{attrs:{"label":"Event name"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'name',
                {
                  rules: [
                    { required: true, message: 'Please input event name!' } ],
                } ]),expression:"[\n                'name',\n                {\n                  rules: [\n                    { required: true, message: 'Please input event name!' },\n                  ],\n                },\n              ]"}],attrs:{"placeholder":"Input event name"}})],1)],1),_c('a-col',{attrs:{"span":8}},[_c('a-form-item',{attrs:{"label":"Code"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'code',
                {
                  rules: [{ required: true, message: 'Please input code!' }],
                } ]),expression:"[\n                'code',\n                {\n                  rules: [{ required: true, message: 'Please input code!' }],\n                },\n              ]"}],staticStyle:{"width":"100%"},attrs:{"placeholder":"Input code"}})],1)],1),_c('a-col',{attrs:{"span":8}},[_c('a-form-item',{attrs:{"label":"Event type"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'type',
                {
                  rules: [
                    { required: true, message: 'Please select event type!' } ],
                } ]),expression:"[\n                'type',\n                {\n                  rules: [\n                    { required: true, message: 'Please select event type!' },\n                  ],\n                },\n              ]"}],attrs:{"placeholder":"Select event type"}},_vm._l((_vm.EVENT_TYPE),function(val){return _c('a-select-option',{key:val.type,attrs:{"value":val.type}},[_vm._v(_vm._s(val.title))])}),1)],1)],1)],1),_c('a-row',{attrs:{"gutter":16}},[_c('a-col',{attrs:{"span":8}},[_c('a-form-item',{attrs:{"label":"Campaign code"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'campaign_code',
                {
                  rules: [
                    {
                      required: true,
                      message: 'Please select campaign code!',
                    } ],
                } ]),expression:"[\n                'campaign_code',\n                {\n                  rules: [\n                    {\n                      required: true,\n                      message: 'Please select campaign code!',\n                    },\n                  ],\n                },\n              ]"}],attrs:{"showSearch":"","optionFilterProp":"children","filterOption":_vm.filterOption,"placeholder":"Select campaign code"}},_vm._l((_vm.campaigns),function(val){return _c('a-select-option',{key:val.id,attrs:{"value":val.code}},[_vm._v(_vm._s(val.id)+" - "+_vm._s(val.name))])}),1)],1)],1),_c('a-col',{attrs:{"span":8}},[_c('a-form-item',{attrs:{"label":"Department"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'department',
                {
                  rules: [
                    { required: true, message: 'Please input department!' } ],
                } ]),expression:"[\n                'department',\n                {\n                  rules: [\n                    { required: true, message: 'Please input department!' },\n                  ],\n                },\n              ]"}],attrs:{"placeholder":"Input department"}})],1)],1),_c('a-col',{attrs:{"span":8}},[_c('a-form-item',{attrs:{"label":"Team"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'team',
                {
                  rules: [{ required: true, message: 'Please input team!' }],
                } ]),expression:"[\n                'team',\n                {\n                  rules: [{ required: true, message: 'Please input team!' }],\n                },\n              ]"}],attrs:{"placeholder":"Input team"}})],1)],1)],1),_c('a-row',{attrs:{"gutter":16}},[_c('a-col',{attrs:{"span":8}},[_c('a-form-item',{attrs:{"label":"Ticket Prefix"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'ticket_prefix',
                {
                  rules: [
                    {
                      required: true,
                      message: 'Please input ticket prefix!',
                    } ],
                } ]),expression:"[\n                'ticket_prefix',\n                {\n                  rules: [\n                    {\n                      required: true,\n                      message: 'Please input ticket prefix!',\n                    },\n                  ],\n                },\n              ]"}],attrs:{"placeholder":"Input ticket prefix"}})],1)],1),_c('a-col',{attrs:{"span":8}},[_c('a-form-item',{attrs:{"label":"Ticket Number Digit Incr"}},[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'ticket_number_digit_incr',
                {
                  rules: [
                    {
                      required: true,
                      message: 'Please input ticket number digit incr!',
                    } ],
                } ]),expression:"[\n                'ticket_number_digit_incr',\n                {\n                  rules: [\n                    {\n                      required: true,\n                      message: 'Please input ticket number digit incr!',\n                    },\n                  ],\n                },\n              ]"}],staticStyle:{"width":"100%"},attrs:{"placeholder":"Input ticket number digit incr","min":1}})],1)],1),_c('a-col',{attrs:{"span":8}},[_c('a-form-item',{attrs:{"label":"Ticket New Offset"}},[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'ticket_new_offset',
                {
                  rules: [
                    {
                      required: true,
                      message: 'Please input ticket new offset!',
                    } ],
                } ]),expression:"[\n                'ticket_new_offset',\n                {\n                  rules: [\n                    {\n                      required: true,\n                      message: 'Please input ticket new offset!',\n                    },\n                  ],\n                },\n              ]"}],staticStyle:{"width":"100%"},attrs:{"placeholder":"Input ticket new offset"}})],1)],1)],1),_c('a-row',{attrs:{"gutter":16}},[_c('a-col',{attrs:{"span":8}},[_c('a-form-item',{attrs:{"label":"Start"}},[_c('a-date-picker',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'start',
                {
                  rules: [
                    {
                      type: 'object',
                      required: true,
                      message: 'Please input start date!',
                    } ],
                } ]),expression:"[\n                'start',\n                {\n                  rules: [\n                    {\n                      type: 'object',\n                      required: true,\n                      message: 'Please input start date!',\n                    },\n                  ],\n                },\n              ]"}],staticStyle:{"width":"100%"},attrs:{"showTime":true,"format":_vm.dateFormat,"placeholder":"Select start date"}})],1)],1),_c('a-col',{attrs:{"span":8}},[_c('a-form-item',{attrs:{"label":"End"}},[_c('a-date-picker',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'end',
                {
                  rules: [
                    {
                      type: 'object',
                      required: true,
                      message: 'Please input end date!',
                    } ],
                } ]),expression:"[\n                'end',\n                {\n                  rules: [\n                    {\n                      type: 'object',\n                      required: true,\n                      message: 'Please input end date!',\n                    },\n                  ],\n                },\n              ]"}],staticStyle:{"width":"100%"},attrs:{"format":_vm.dateFormat,"placeholder":"Select end date","showTime":true}})],1)],1),_c('a-col',{attrs:{"span":8}},[_c('a-form-item',{attrs:{"label":"Status"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'status',
                {
                  rules: [
                    { required: true, message: 'Please select status!' } ],
                } ]),expression:"[\n                'status',\n                {\n                  rules: [\n                    { required: true, message: 'Please select status!' },\n                  ],\n                },\n              ]"}],attrs:{"placeholder":"Select status"}},_vm._l((_vm.statusArray),function(status){return _c('a-select-option',{key:status.value,attrs:{"value":status.value}},[_vm._v(_vm._s(status.text))])}),1)],1)],1)],1),_c('a-row',{attrs:{"gutter":16}},[_c('a-col',{attrs:{"span":8}},[_c('a-form-item',{attrs:{"label":"Location"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['location']),expression:"['location']"}],attrs:{"placeholder":"Input location"}})],1)],1),_c('a-col',{attrs:{"span":16}},[_c('a-form-item',[_c('label',[_vm._v("Actions ")]),_c('Tag',{attrs:{"data":_vm.tags,"handleGetTags":_vm.handleGetActionTags}})],1)],1)],1),_c('a-row',{attrs:{"gutter":16}},[_c('a-col',{attrs:{"span":8}},[_c('a-form-item',[_c('a-checkbox',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'display_public',
                {
                  valuePropName: 'checked',
                  initialValue: false,
                } ]),expression:"[\n                'display_public',\n                {\n                  valuePropName: 'checked',\n                  initialValue: false,\n                },\n              ]"}]},[_vm._v(" Display public ")])],1)],1),_c('a-col',{attrs:{"span":8}},[_c('a-form-item',[_c('a-checkbox',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'display_internal',
                {
                  valuePropName: 'checked',
                  initialValue: false,
                } ]),expression:"[\n                'display_internal',\n                {\n                  valuePropName: 'checked',\n                  initialValue: false,\n                },\n              ]"}]},[_vm._v(" Display internal ")])],1)],1)],1),_c('label',[_vm._v(" Image ")]),_c('UploadMultipleImage',{attrs:{"urls":_vm.urls,"handleGetUrls":_vm.handleGetUrls}}),_c('br'),_c('label',[_vm._v(" Content ")]),_c('ckeditor',{attrs:{"config":_vm.editorConfig},model:{value:(_vm.content),callback:function ($$v) {_vm.content=$$v},expression:"content"}})],1)],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }