<template>
  <div>
    <a-modal
      :visible="visible"
      title="Update voucher"
      onOk="handleUpdateVoucher"
      :closable="false"
      :maskClosable="false"
      @change="onChangeModal"
    >
      <template slot="footer">
        <a-button key="back" @click="onCancel">Cancel</a-button>
        <a-button
          key="submit"
          type="primary"
          :loading="loading"
          @click="handleUpdateVoucher"
          >Update</a-button
        >
      </template>

      <a-form :form="form" @submit="handleUpdateVoucher">
        <a-row :gutter="16">
          <a-col :span="24">
            <a-form-item label="Code">
              <a-input
                :disabled="true"
                v-decorator="[
                  'code',
                  {
                    initialValue: item.code,
                    rules: [{ required: true, message: 'Please input code!' }],
                  },
                ]"
              />
            </a-form-item>
          </a-col>
        </a-row>

        <a-row :gutter="16">
          <a-col :span="12">
            <a-form-item label="Master code">
              <a-input
                :disabled="true"
                v-decorator="[
                  'master_code',
                  {
                    initialValue: item.master_code,
                    rules: [
                      { required: true, message: 'Please input master_code!' },
                    ],
                  },
                ]"
              />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="Status">
              <a-select
                v-decorator="[
                  'status',
                  {
                    initialValue: item.status,
                    rules: [
                      { required: true, message: 'Please select status!' },
                    ],
                  },
                ]"
                placeholder="Select status"
              >
                <a-select-option
                  v-for="status in statusArray"
                  :key="status.value"
                  :value="status.value"
                  >{{ status.text }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>
        <a-form-item label="Note">
          <a-textarea
            placeholder="Add note"
            v-decorator="['note', { initialValue: item.note }]"
          >
          </a-textarea>
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>
<script>
/* eslint-disable no-console */
import voucher from "../../api/voucher";
import moment from "moment";
import { mapState } from "vuex";
import { getCurrentUser } from "../../utils/authentication";
import { statusArray, dateFormat } from "../../utils/help";

export default {
  data() {
    return {
      isMultiple: false,
      statusArray,
      user: getCurrentUser(),
      dateFormat,
      loading: false,
      form: this.$form.createForm(this, { name: "update_voucher_form" }),
    };
  },

  computed: {
    ...mapState({
      total: (state) => state.voucher.total,
      events: (state) => state.event.data,
    }),
  },
  model: {
    prop: "visible",
    event: "change",
  },
  props: {
    visible: Boolean,
    handleCancel: Function,
    showModal: Function,
    item: Object,
    pageSize: Number,
  },
  methods: {
    moment,
    onChangeModal() {
      this.form.resetFields();
      this.$emit("change", false);
    },
    onCancel() {
      this.handleCancel();
      this.form.resetFields();
    },
    handleChangeMultiple(e) {
      this.isMultiple = e.target.checked;
    },
    handleUpdateVoucher(e) {
      e.preventDefault();
      this.form.validateFields(async (err, values) => {
        if (!err) {
          this.loading = true;
          let body = {
            id: this.item.id,
            created_by: this.item.created_by,
          };

          body = Object.assign(body, values);

          const data = await voucher.updateVoucher(body);
          if (data) {
            this.$message.success(`Update successfully`);
            this.onCancel();
            this.$store.dispatch("voucher/getVouchersByVoucherMaster", {
              limit: 10,
              offset: 0,
              master_code: this.item.master_code,
            });
          }

          this.loading = false;
        }
      });
    },
  },
};
</script>
