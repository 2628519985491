/* eslint-disable no-console */
import axios from "axios";
import { API_URL } from "../config";
import { getCurrentUser } from "./authentication";

async function upload(file) {
  const user = getCurrentUser();
  const config = {
    headers: {
      Authorization: user ? user.access_token : "",
    },
  };
  try {
    const response = await axios.post(`${API_URL}/file/upload`, file, config);
    return response.data;
  } catch (error) {
    console.error(error);
  }
}

export default upload;
