var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('a-modal',{attrs:{"visible":_vm.visible,"title":"Update ticket","closable":false,"maskClosable":false},on:{"change":_vm.onChangeModal}},[_c('template',{slot:"footer"},[_c('a-button',{key:"back",on:{"click":_vm.onCancel}},[_vm._v("Cancel")]),_c('a-button',{key:"submit",attrs:{"type":"primary","loading":_vm.loading},on:{"click":_vm.handleUpdateTicket}},[_vm._v("Update")])],1),_c('a-form',{attrs:{"form":_vm.form},on:{"submit":_vm.handleUpdateTicket}},[_c('a-row',{attrs:{"gutter":16}},[_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"Event id"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'event_id',
                {
                  rules: [
                    { required: true, message: 'Please select event_id!' } ],
                  initialValue: _vm.item.event_id,
                } ]),expression:"[\n                'event_id',\n                {\n                  rules: [\n                    { required: true, message: 'Please select event_id!' },\n                  ],\n                  initialValue: item.event_id,\n                },\n              ]"}],attrs:{"disabled":true,"placeholder":"Select event"}},_vm._l((_vm.events),function(val){return _c('a-select-option',{key:val.id,attrs:{"value":val.id}},[_vm._v(" "+_vm._s(val.id)+" - "+_vm._s(val.name))])}),1)],1)],1),_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"Campaign code"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'campaign_code',
                {
                  rules: [
                    {
                      required: true,
                      message: 'Please select campaign code!',
                    } ],
                  initialValue: _vm.item.campaign_id,
                } ]),expression:"[\n                'campaign_code',\n                {\n                  rules: [\n                    {\n                      required: true,\n                      message: 'Please select campaign code!',\n                    },\n                  ],\n                  initialValue: item.campaign_id,\n                },\n              ]"}],attrs:{"showSearch":"","optionFilterProp":"children","filterOption":_vm.filterOption,"placeholder":"Select campaign code"}},_vm._l((_vm.campaigns),function(val){return _c('a-select-option',{key:val.id,attrs:{"value":val.id.toString()}},[_vm._v(" "+_vm._s(val.id)+" - "+_vm._s(val.name))])}),1)],1)],1)],1),_c('a-row',{attrs:{"gutter":16}},[_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"Phone"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'cus_phone',
                {
                  initialValue: _vm.item.cus_phone,
                } ]),expression:"[\n                'cus_phone',\n                {\n                  initialValue: item.cus_phone,\n                },\n              ]"}],attrs:{"placeholder":"Input customer phone"}})],1)],1),_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"Fullname"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'cus_fullname',
                {
                  initialValue: _vm.item.cus_fullname,
                  rules: [
                    {
                      required: true,
                      message: 'Please input customer fullname!',
                    } ],
                } ]),expression:"[\n                'cus_fullname',\n                {\n                  initialValue: item.cus_fullname,\n                  rules: [\n                    {\n                      required: true,\n                      message: 'Please input customer fullname!',\n                    },\n                  ],\n                },\n              ]"}],attrs:{"placeholder":"Input customer fullname"}})],1)],1)],1),_c('a-row',{attrs:{"gutter":16}},[_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"Customer Type"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['cus_type', { initialValue: _vm.item.cus_type }]),expression:"['cus_type', { initialValue: item.cus_type }]"}],attrs:{"placeholder":"Input customer type"}})],1)],1),_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"Status"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'status',
                {
                  rules: [
                    { required: true, message: 'Please select status!' } ],
                  initialValue: _vm.item.status,
                } ]),expression:"[\n                'status',\n                {\n                  rules: [\n                    { required: true, message: 'Please select status!' },\n                  ],\n                  initialValue: item.status,\n                },\n              ]"}],attrs:{"placeholder":"Select status"}},_vm._l((_vm.statusArray),function(status){return _c('a-select-option',{key:status.value,attrs:{"value":status.value}},[_vm._v(_vm._s(status.text)+" ")])}),1)],1)],1)],1)],1)],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }