<template>
  <div>
    <a-spin tip="Loading..." :spinning="fetching">
      <a-form
        class="ant-advanced-search-form"
        :form="form"
        @submit="handleSearch"
      >
        <a-row :gutter="16">
          <a-col :span="6">
            <a-form-item label="Event">
              <a-select
                allowClear
                @change="handleChangeEvent"
                v-decorator="[
                  'event_id',
                  {
                    initialValue: this.$route.params.event,
                    rules: [
                      { required: false, message: 'Please select event' },
                    ],
                  },
                ]"
                placeholder="Select event"
              >
                <a-select-option
                  v-for="val in events"
                  :key="val.id"
                  :value="val.id"
                  >{{ val.id }} - {{ val.name }}</a-select-option
                >
              </a-select>
            </a-form-item>
          </a-col>

          <a-col :span="6" v-if="event_id">
            <a-form-item label="Master code">
              <a-select
                @change="handleChangeMasterCode"
                allowClear
                v-decorator="[
                  'master_code',
                  {
                    initialValue: master_code,
                    rules: [
                      {
                        required: false,
                        message: 'Please voucher select master code',
                      },
                    ],
                  },
                ]"
                placeholder="Select voucher master code"
              >
                <a-select-option
                  v-for="val in vouchersMaster"
                  :key="val.code"
                  :value="val.code"
                  >{{ val.code }} - {{ val.title }}</a-select-option
                >
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="6" v-else>
            <a-form-item label="Master code">
              <a-input
                v-decorator="[
                  'master_code',
                  {
                    initialValue: master_code,
                    rules: [
                      {
                        required: false,
                        message: 'Please voucher select master code',
                      },
                    ],
                  },
                ]"
                placeholder="Select voucher master code"
              />
            </a-form-item>
          </a-col>
          <a-col :span="6">
            <a-form-item label="Voucher code">
              <a-input placeholder="Voucher code" v-decorator="['code']" />
            </a-form-item>
          </a-col>
          <a-col :span="6">
            <a-form-item label="Customer phone">
              <a-input
                placeholder="Customer phone"
                v-decorator="['cus_phone']"
              />
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="16" :style="{ display: expand ? 'block' : 'none' }">
          <a-col :span="6">
            <a-form-item label="Invoice ID">
              <a-input placeholder="Invoice ID" v-decorator="['invoice_id']" />
            </a-form-item>
          </a-col>
          <a-col :span="6">
            <a-form-item label="Status">
              <a-select v-decorator="['status']" placeholder="Select status">
                <a-select-option value="">Tất cả</a-select-option>
                <a-select-option
                  v-for="status in statusArray"
                  :key="status.value"
                  :value="status.value"
                  >{{ status.text }}</a-select-option
                >
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>

        <a-row>
          <a-col :span="24" :style="{ textAlign: 'right' }">
            <a-button type="primary" html-type="submit">Search</a-button>
            <a-button
              :style="{ marginLeft: '8px' }"
              @click="handleExport"
              :disabled="vouchers.length === 0"
              >Export</a-button
            >
            <a-button :style="{ marginLeft: '8px' }" @click="handleReset"
              >Clear</a-button
            >
            <a :style="{ marginLeft: '8px', fontSize: '12px' }" @click="toggle">
              Collapse <a-icon :type="expand ? 'up' : 'down'" />
            </a>
          </a-col>
        </a-row>
      </a-form>

      <Table
        style="padding:0 25px 0 25px"
        title="Vouchers"
        :data="vouchers"
        :columns="voucherColumns"
        :scrollX="scrollX"
        :scrollY="700"
        :isDelete="false"
        :isApprove="false"
        :isAdd="true"
        :isUpdate="true"
        :handleUpdateVisible="handleUpdateVisible"
        :handleAddVisible="handleAddVisible"
        :handleSettingColunmVisible="handleSettingColunmVisible"
        :isShowError="error !== null"
        :errorMessage="error"
      />
      <br />
      <a-pagination
        v-show="vouchers.length > 0"
        :current="current"
        :pageSizeOptions="pageSizeOptions"
        showSizeChanger
        :pageSize="pageSize"
        @showSizeChange="onShowSizeChange"
        @change="handleChangePage"
        :total="total"
      >
        <template slot="buildOptionText" slot-scope="props">
          <span>{{ props.value }} / page</span>
        </template>
      </a-pagination>

      <UpdateVoucher
        v-model="updateModalVisible"
        :handleCancel="handleUpdateCancel"
        :showModal="handleUpdateVisible"
        :item="item"
      />

      <AddVoucher
        v-model="addModalVisible"
        :handleCancel="handleAddModalCancel"
        :showModal="handleAddVisible"
        :handleChangePage="handleChangePage"
        :master_code="master_code"
        :pageSize="pageSize"
      />
      <SettingColumn
        v-model="settingColumnModalVisible"
        :handleCancel="handleSettingColunmCancel"
        :showModal="handleSettingColunmVisible"
      />
    </a-spin>
  </div>
</template>

<script>
/* eslint-disable no-console */

import { mapState } from "vuex";
import Table from "../components/Table";
import UpdateVoucher from "../components/Voucher/UpdateVoucher";
import AddVoucher from "../components/Voucher/AddVoucher";
import { exportFile } from "../api";
import { removeUndefined } from "../utils/help";
import SettingColumn from "../components/Voucher/SettingColumn";
// import _ from "lodash";
import { statusArray } from "../utils/help";

export default {
  name: "voucher",
  components: {
    Table,
    UpdateVoucher,
    AddVoucher,
    SettingColumn,
  },
  created() {
    this.getVouchers();
  },
  computed: {
    ...mapState({
      vouchers: (state) => state.voucher.vouchersDetailData,
      fetching: (state) => state.voucher.vouchersDetailFetching,
      total: (state) => state.voucher.vouchersDetailTotal,
      events: (state) => state.event.data,
      vouchersMaster: (state) => state.voucher.data,
      error: (state) => state.voucher.error,
      voucherColumns: (state) => state.voucher.vouchersDetailColumns,
    }),
    setItem: function() {
      return this.item;
    },
    scrollX: function() {
      return this.voucherColumns.length * 150 + 200;
    },
  },
  data() {
    return {
      statusArray,
      form: this.$form.createForm(this, { name: "search_form" }),
      item: {},
      updateModalVisible: false,
      addModalVisible: false,
      settingColumnModalVisible: false,
      master_code: undefined,
      pageSizeOptions: ["10", "20", "30", "40", "50"],
      current: 1,
      pageSize: 10,
      expand: false,
      event_id: "",
    };
  },
  methods: {
    toggle() {
      this.expand = !this.expand;
    },
    handleUpdateVisible(item) {
      this.updateModalVisible = true;
      this.item = item;
    },
    handleUpdateCancel() {
      this.updateModalVisible = false;
    },

    handleAddVisible() {
      this.addModalVisible = true;
    },
    handleAddModalCancel() {
      this.addModalVisible = false;
    },
    handleSettingColunmVisible() {
      this.settingColumnModalVisible = true;
    },
    handleSettingColunmCancel() {
      this.settingColumnModalVisible = false;
    },
    handleChangePage(currentPage) {
      this.current = currentPage;
      const params = {
        limit: this.pageSize,
        offset: (currentPage - 1) * this.pageSize,
        master_code: this.master_code,
      };

      this.$store.dispatch("voucher/getVouchersByVoucherMaster", params);
    },
    onShowSizeChange(currentPage, pageSize) {
      this.current = 1;
      this.pageSize = pageSize;
      const params = {
        limit: pageSize,
        offset: 0,
        master_code: this.master_code,
      };
      this.$store.dispatch("voucher/getVouchersByVoucherMaster", params);
    },
    getVouchers() {
      //get voucher from voucher master
      const { code, event } = this.$route.params;
      if (code !== undefined) {
        this.master_code = code;
        const params = {
          limit: this.pageSize,
          offset: 0,
          master_code: code,
        };
        this.event_id = event;
        this.$store.dispatch("voucher/getAllVouchersMaster", {
          event_id: event,
        }); // get all vouchers master for selected input
        this.$store.dispatch("voucher/getVouchersByVoucherMaster", params); // get vouchers detail
      }
    },
    handleReset() {
      this.form.resetFields();
      this.master_code = undefined;
    },
    handleExport(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          values = removeUndefined(values);
          let fields = [];
          this.voucherColumns.forEach((item) =>
            item.dataIndex !== undefined ? fields.push(item.dataIndex) : null
          );
          fields = fields.toString();
          values.fields = fields;
          exportFile("vouchers/export_file_data", values);
        }
      });
    },
    handleChangeMasterCode(code) {
      this.master_code = code;
    },
    handleChangeEvent(event) {
      this.form.setFieldsValue({
        master_code: undefined,
      });
      this.event_id = event;

      if (event) {
        const params = { event_id: event };
        this.$store.dispatch("voucher/getAllVouchersMaster", params); // get all vouchers master for selected input
      }
    },
    handleSearch(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          let params = {};
          if (values.code) {
            params.code = values.code;
          }
          if (values.cus_phone) {
            params.cus_phone = values.cus_phone;
          }
          if (values.invoice_id) {
            params.invoice_id = values.invoice_id;
          }
          if (values.status) {
            params.status = values.status;
          }
          if (values.master_code) {
            params.limit = this.pageSize;
            params.offset = 0;
            params.master_code = values.master_code;
          }

          this.$store.dispatch("voucher/getVouchersByVoucherMaster", params); // get vouchers detail
        }
      });
    },
  },
  destroyed() {
    this.$store.dispatch("voucher/resetVoucher");
  },
};
</script>
