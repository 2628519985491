<template>
  <div>
    <template v-for="(tag) in tags">
      <a-tooltip v-if="tag.length > 20" :key="tag" :title="tag">
        <a-tag :key="tag" :closable="true" @close="() => handleClose(tag)">
          {{`${tag.slice(0, 20)}...`}}
        </a-tag>
      </a-tooltip>
      <a-tag v-else :key="tag" :closable="true" @close="() => handleClose(tag)">
        {{tag}}
      </a-tag>
    </template>
    <a-input
      v-if="inputVisible"
      ref="input"
      type="text"
      size="small"
      :style="{ width: '78px' }"
      :value="inputValue"
      @change="handleInputChange"
      @blur="handleInputConfirm"
      @keyup.enter="handleInputConfirm"/>
    <a-tag v-else @click="showInput" style="background: #fff; borderStyle: dashed;">
      <a-icon type="plus" /> New Tag
    </a-tag>
  </div>
</template>
<script>
/* eslint-disable no-console */

  export default {
    data() {
      return {
        tags: this.$props.data,
        inputVisible: false,
        inputValue: '',
      };
    },
    props: {
      data: Array,
      handleGetTags: Function
    },
    watch: {
      data: {
        // the callback will be called immediately after the start of the observation
        immediate: true, 
        handler (nextProps) {
         this.tags = nextProps
  
        }
      }
    },
    methods: {
      handleClose(removedTag) {
        const tags = this.tags.filter(tag => tag !== removedTag);
        // console.log(tags);
        this.tags = tags;
        this.$props.handleGetTags(tags)
      },

      showInput() {
        this.inputVisible = true;
        this.$nextTick(function() {
          this.$refs.input.focus();
        });
      },

      handleInputChange(e) {
        this.inputValue = e.target.value;
      },

      handleInputConfirm() {
        const inputValue = parseInt(this.inputValue);
  
        let tags = this.tags;
        if (inputValue && tags.indexOf(inputValue) === -1) {
          tags = [...tags, inputValue];
        }
        this.$props.handleGetTags(tags)
        Object.assign(this, {
          tags,
          inputVisible: false,
          inputValue: '',
        });
        
      },
    },
  };
</script>