<template>
  <div>
    <a-spin tip="Loading..." :spinning="fileUploading || ticketUploading">
      <a-row style="background-color: rgb(241,242,245)">
        <a-col :span="6" class="left">
          <h2>Upload Ticket</h2>
          <a-upload
            :fileList="fileList"
            :remove="handleRemove"
            @change="handleChangeFile"
            :beforeUpload="beforeUpload"
          >
            <a-button> <a-icon type="upload" />Select File </a-button>
          </a-upload>

          <a-button
            type="primary"
            @click="handleUploadFile"
            :disabled="fileList.length === 0"
            :loading="fileUploading"
            style="margin: 16px 0px 16px 0px"
            >{{ fileUploading ? "Uploading" : "Start Upload" }}</a-button
          >

          <h2>Create Ticket</h2>
          <a-form :form="form" @submit="handleUploadTicket">
            <a-form-item label="Event">
              <a-select
                v-decorator="[
                  'event',
                  {
                    rules: [
                      { required: true, message: 'Please select event!' },
                    ],
                  },
                ]"
                placeholder="Select event"
              >
                <a-select-option
                  v-for="val in events"
                  :key="val.id"
                  :value="val.id"
                  >{{ val.id }} - {{ val.name }}</a-select-option
                >
              </a-select>
            </a-form-item>

            <a-form-item>
              <a-checkbox
                v-decorator="[
                  'sync_phone',
                  { valuePropName: 'checked', initialValue: false },
                ]"
                >Sync phone</a-checkbox
              >
            </a-form-item>

            <a-form-item label="Status">
              <a-select
                v-decorator="[
                  'status',
                  {
                    rules: [
                      { required: true, message: 'Please select status!' },
                    ],
                  },
                ]"
                placeholder="Select status"
              >
                <a-select-option
                  v-for="status in statusArray"
                  :key="status.value"
                  :value="status.value"
                  >{{ status.text }}</a-select-option
                >
              </a-select>
            </a-form-item>

            <a-button
              style="margin-top: 16px"
              type="primary"
              key="submit"
              @click="handleUploadTicket"
              :disabled="customers.length === 0"
              :loading="ticketUploading"
              >Create Ticket</a-button
            >
          </a-form>
        </a-col>

        <a-col :span="18" class="right">
          <Table
            title="Customers"
            :pagination="pagination"
            :data="customers"
            :columns="columns"
            :isDelete="false"
            :isUpdate="false"
            :isAdd="false"
            :handleAddVisible="() => null"
            :scrollX="2000"
            :isShowError="createError"
            :errorMessage="errorMessage"
            :handleSettingColunmVisible="() => null"
          />
        </a-col>
      </a-row>
    </a-spin>
  </div>
</template>
<script>
/* eslint-disable no-console */
import upload from "../../utils/upload";
import { mapState } from "vuex";
import { code } from "../../api";
import Table from "../../components/Table";
import ticket from "../../api/ticket";
import { getCurrentUser } from "../../utils/authentication";
import { statusArray, renderTableColumns } from "../../utils/help";

export default {
  components: {
    Table,
  },
  computed: mapState({
    events: (state) => state.event.data,
  }),
  data() {
    return {
      statusArray,
      user: getCurrentUser(),
      form: this.$form.createForm(this, { name: "coordinated" }),
      fileList: [],
      fileUploading: false,
      ticketUploading: false,
      file: null,
      pagination: { pageSize: 10 },
      customers: [],
      columns: [],
      uploadTicketFile: null,
      createError: false,
      errorMessage: "",
    };
  },
  methods: {
    handleRemove(file) {
      const index = this.fileList.indexOf(file);
      const newFileList = this.fileList.slice();
      newFileList.splice(index, 1);
      this.fileList = newFileList;
    },
    beforeUpload(file) {
      this.fileList = [...this.fileList, file];
      return false;
    },
    handleChangeFile(info) {
      let fileList = [...info.fileList];

      this.fileList = fileList.slice(-1); //limit file uplaod

      this.file = fileList[0].originFileObj;
      this.createError = false;
    },
    async handleUploadFile() {
      const { file } = this;
      var formData = new FormData();
      formData.append("file", file);
      this.fileUploading = true;

      const response = await upload(formData);
      if (response.code === code.success) {
        if (response.data) {
          this.customers = response.data.data;
          this.customers.map((customer, index) => (customer["key"] = index));
          this.uploadTicketFile = response.data.file;
          this.columns = renderTableColumns(response.data.data);
        } else {
          this.$message.error("Không có dữ liệu");
        }
        this.fileUploading = false;
      }
    },
    async handleUploadTicket(e) {
      e.preventDefault();
      this.form.validateFields(async (err, values) => {
        if (!err) {
          this.ticketUploading = true;
          const data = {
            file: this.uploadTicketFile,
            type: "ticket",
            event_id: values.event,
            status: values.status,
            created_time: new Date().getTime(),
            created_by: this.user.data_token.username,
            sync_phone: values.sync_phone,
          };
          const response = await ticket.uploadTicket(data);
          if (response.code === code.success) {
            this.ticketUploading = false;
            this.createError = false;
            this.$message.success(`Create successfully`);
          } else {
            this.createError = true;
            this.ticketUploading = false;
            this.errorMessage = response.message;
          }
        }
      });
    },
  },
};
</script>

<style scoped>
.left {
  padding: 16px !important;
  background: #f9f0ff;
  border: 1px solid #f9f0ff;
  border-radius: 6px;
  border-left: 10px solid #391085;
  height: 88.1vh;
}
.right {
  border-left: 10px solid #391085;
  border-radius: 6px;
  padding-left: 16px;
  height: 88.1vh;
  background: #fff;
}
h2 {
  color: #391085;
}
</style>
