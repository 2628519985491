/* eslint-disable no-console */
import users from "../../api/user";
import { renderTableColumns } from "../../utils/help";
import { code } from "../../api";

// initial state
const state = {
  data: [],
  fetching: false,
  allColumns: [],
  columns: [],
  error: null,

  roles_assignable: [],
};

// getters
const getters = {};

// actions
const actions = {
  getAllUsers({ commit }, params) {
    commit("userFetching", []);
    users.getUsers(params, (response) => {
      if (response.code === code.success) commit("setUsers", response.data);
      else commit("setError", response);
    });
  },
};

// mutations
const mutations = {
  userFetching(state, users) {
    (state.data = users), (state.fetching = true);
  },
  setUsers(state, users) {
    state.allColumns = renderTableColumns(users);
    state.columns = state.columns.length > 0 ? state.columns : state.allColumns;
    users.map((b) => (b["key"] = b.id)); //set key to render table
    (state.data = users), (state.fetching = false);
  },
  setError(state, response) {
    state.allColumns = [];
    state.columns = [];
    state.data = [];
    state.fetching = false;
    state.error = response.message;
  },

  setUserColumns(state, columns) {
    state.columns = columns;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
