import API from "./index"

/* eslint-disable no-console */

export default {
  getCampaigns(cb) {
    const params = {}
    API.get('campaigns/all', params)
      .then((response) => {
        if (!response) {
          return
        }
        cb(response)
      })
  },
  async createCampaign(values) {
    return await API.post('campaigns', values)
  },

  async updateCampaign(values) {
    return await API.put('campaigns', values)
  }

}