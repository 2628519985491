<template>
  <div>
    <a-spin tip="Loading..." :spinning="fetching">
      <a-form
        class="ant-advanced-search-form"
        :form="form"
        @submit="handleFilter"
      >
        <a-row :gutter="16">
          <a-col :span="6">
            <a-form-item label="Event">
              <a-select
                @change="handleChangeEvent"
                v-decorator="[
                  'event_id',
                  {
                    rules: [{ required: true, message: 'Please select event' }],
                  },
                ]"
                placeholder="Select event"
              >
                <a-select-option
                  v-for="val in events"
                  :key="val.id"
                  :value="val.id"
                  >{{ val.id }} - {{ val.name }}</a-select-option
                >
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="6">
            <a-form-item label="Action Type">
              <a-select
                v-decorator="['action_type']"
                placeholder="Select action type"
              >
                <a-select-option
                  v-for="val in actionsEvent"
                  :key="val.type"
                  :value="val.type"
                  >{{ val.type }} - {{ val.title }}</a-select-option
                >
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="6">
            <a-form-item label="Customer type">
              <a-input v-decorator="['cus_type']" placeholder="Customer type" />
            </a-form-item>
          </a-col>
          <a-col :span="6">
            <a-form-item label="Cus fullname">
              <a-input
                v-decorator="['cus_fullname']"
                placeholder="Customer fullname"
              />
            </a-form-item>
          </a-col>
        </a-row>

        <a-row :gutter="16" v-show="expand">
          <a-col :span="6">
            <a-form-item label="Customer phone">
              <a-input
                v-decorator="['cus_phone']"
                placeholder="Customer phone"
              />
            </a-form-item>
          </a-col>

          <a-col :span="6">
            <a-form-item label="Status">
              <a-select v-decorator="['status']" placeholder="Select status">
                <a-select-option
                  v-for="status in statusArray"
                  :key="status.value"
                  :value="status.value"
                  >{{ status.text }}</a-select-option
                >
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>

        <a-row>
          <a-col :span="24" :style="{ textAlign: 'right' }">
            <a-button type="primary" html-type="submit">Search</a-button>
            <a-button
              :style="{ marginLeft: '8px' }"
              @click="handleExport"
              :disabled="eventId === null"
              >Export</a-button
            >
            <a-button :style="{ marginLeft: '8px' }" @click="handleReset"
              >Clear</a-button
            >
            <a :style="{ marginLeft: '8px', fontSize: '12px' }" @click="toggle">
              Collapse
              <a-icon :type="expand ? 'up' : 'down'" />
            </a>
          </a-col>
        </a-row>
      </a-form>

      <Table
        style="padding:0 25px 0 25px"
        title="Tickets"
        :data="tickets"
        :columns="columns"
        :scrollX="scrollX"
        :scrollY="700"
        :isDelete="false"
        :isApprove="false"
        :isUpdate="true"
        :isAdd="eventId !== null"
        :handleApprove="handleApprove"
        :handleUpdateVisible="handleUpdateVisible"
        :handleAddVisible="handleAddVisible"
        :handleSettingColunmVisible="handleSettingColunmVisible"
      />
      <br />

      <a-pagination
        v-show="tickets.length > 0"
        :current="current"
        :pageSizeOptions="pageSizeOptions"
        showSizeChanger
        :pageSize="pageSize"
        @showSizeChange="onShowSizeChange"
        @change="handleChangePage"
        :total="total"
      >
        <template slot="buildOptionText" slot-scope="props">
          <span>{{ props.value }} / page</span>
        </template>
      </a-pagination>

      <UpdateTicket
        v-model="updateModalVisible"
        :handleCancel="handleUpdateCancel"
        :showModal="handleUpdateVisible"
        :item="setItem"
        :pageSize="pageSize"
        :eventId="eventId"
      />

      <AddTicket
        v-model="addModalVisible"
        :handleCancel="handleAddModalCancel"
        :showModal="handleAddVisible"
        :pageSize="pageSize"
        :eventId="eventId"
      />
      <SettingColumn
        v-model="settingColumnModalVisible"
        :handleCancel="handleSettingColunmCancel"
        :showModal="handleSettingColunmVisible"
      />
    </a-spin>
  </div>
</template>

<script>
/* eslint-disable no-console */

import { mapState } from "vuex";
import Table from "../components/Table";
import AddTicket from "../components/Ticket/AddTicket";
import SettingColumn from "../components/Ticket/SettingColumn";
import UpdateTicket from "../components/Ticket/UpdateTicket";
import { statusArray, removeUndefined } from "../utils/help";
import { exportFile } from "../api";

export default {
  name: "ticket",
  components: {
    Table,
    AddTicket,
    UpdateTicket,
    SettingColumn,
  },
  computed: {
    ...mapState({
      tickets: (state) => state.ticket.data,
      fetching: (state) => state.ticket.fetching,
      events: (state) => state.event.data,
      total: (state) => state.ticket.total,
      columns: (state) => state.ticket.columns,
      actionsEvent: (state) => state.event.actionsEvent,
    }),
    scrollX: function() {
      return this.columns.length * 150 + 150;
    },
    setItem: function() {
      return this.item;
    },
  },
  destroyed() {
    this.$store.dispatch("ticket/resetTicket");
  },
  data() {
    return {
      statusArray,
      expand: false,
      form: this.$form.createForm(this, { name: "search_form" }),
      pageSizeOptions: ["10", "20", "30", "40", "50"],
      current: 1,
      pageSize: 10,
      eventId: null,
      item: {},
      updateModalVisible: false,
      addModalVisible: false,
      settingColumnModalVisible: false,
    };
  },
  methods: {
    handleUpdateVisible(item) {
      this.updateModalVisible = true;
      this.item = item;
    },
    handleUpdateCancel() {
      this.updateModalVisible = false;
    },

    handleAddVisible() {
      this.addModalVisible = true;
    },
    handleAddModalCancel() {
      this.addModalVisible = false;
    },
    handleSettingColunmVisible() {
      this.settingColumnModalVisible = true;
    },
    handleSettingColunmCancel() {
      this.settingColumnModalVisible = false;
    },

    handleChangeEvent(event) {
      this.eventId = event;
      this.current = 1;
      const params = {
        limit: this.pageSize,
        offset: 0,
        event_id: event,
      };
      this.$store.dispatch("ticket/getAllTickets", params);
      this.$store.dispatch("event/getActionEvents", { id: event });
    },
    handleChangePage(currentPage) {
      this.current = currentPage;
      let params = {
        limit: this.pageSize,
        offset: (currentPage - 1) * this.pageSize,
      };
      let formValues = this.form.getFieldsValue();
      formValues = removeUndefined(formValues); //remove undefined value[key]
      params = Object.assign(params, formValues);

      this.$store.dispatch("ticket/getAllTickets", params);
    },
    onShowSizeChange(currentPage, pageSize) {
      this.current = 1;
      this.pageSize = pageSize;
      const params = {
        limit: pageSize,
        offset: 0,
        event_id: this.eventId,
      };
      this.form.resetFields();
      this.form.setFieldsValue({
        event_id: this.eventId,
      });
      this.$store.dispatch("ticket/getAllTickets", params);
    },
    handleApprove() {},
    handleFilter(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          this.eventId = values.event_id;
          this.current = 1;
          let params = {
            limit: this.pageSize,
            offset: 0,
          };

          values = removeUndefined(values); //remove undefined value[key]
          params = Object.assign(params, values);

          this.$store.dispatch("ticket/getAllTickets", params);
        }
      });
    },
    handleReset() {
      this.form.resetFields();
    },

    toggle() {
      this.expand = !this.expand;
    },
    handleExport(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          values = removeUndefined(values);
          exportFile("tickets/export_file_data", values);
        }
      });
    },
  },
};
</script>
