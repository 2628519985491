var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('a-modal',{attrs:{"visible":_vm.visible,"title":"Update voucher","onOk":"handleUpdateVoucher","closable":false,"maskClosable":false},on:{"change":_vm.onChangeModal}},[_c('template',{slot:"footer"},[_c('a-button',{key:"back",on:{"click":_vm.onCancel}},[_vm._v("Cancel")]),_c('a-button',{key:"submit",attrs:{"type":"primary","loading":_vm.loading},on:{"click":_vm.handleUpdateVoucher}},[_vm._v("Update")])],1),_c('a-form',{attrs:{"form":_vm.form},on:{"submit":_vm.handleUpdateVoucher}},[_c('a-row',{attrs:{"gutter":16}},[_c('a-col',{attrs:{"span":24}},[_c('a-form-item',{attrs:{"label":"Code"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'code',
                {
                  initialValue: _vm.item.code,
                  rules: [{ required: true, message: 'Please input code!' }],
                } ]),expression:"[\n                'code',\n                {\n                  initialValue: item.code,\n                  rules: [{ required: true, message: 'Please input code!' }],\n                },\n              ]"}],attrs:{"disabled":true}})],1)],1)],1),_c('a-row',{attrs:{"gutter":16}},[_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"Master code"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'master_code',
                {
                  initialValue: _vm.item.master_code,
                  rules: [
                    { required: true, message: 'Please input master_code!' } ],
                } ]),expression:"[\n                'master_code',\n                {\n                  initialValue: item.master_code,\n                  rules: [\n                    { required: true, message: 'Please input master_code!' },\n                  ],\n                },\n              ]"}],attrs:{"disabled":true}})],1)],1),_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"Status"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'status',
                {
                  initialValue: _vm.item.status,
                  rules: [
                    { required: true, message: 'Please select status!' } ],
                } ]),expression:"[\n                'status',\n                {\n                  initialValue: item.status,\n                  rules: [\n                    { required: true, message: 'Please select status!' },\n                  ],\n                },\n              ]"}],attrs:{"placeholder":"Select status"}},_vm._l((_vm.statusArray),function(status){return _c('a-select-option',{key:status.value,attrs:{"value":status.value}},[_vm._v(_vm._s(status.text)+" ")])}),1)],1)],1)],1),_c('a-form-item',{attrs:{"label":"Note"}},[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:(['note', { initialValue: _vm.item.note }]),expression:"['note', { initialValue: item.note }]"}],attrs:{"placeholder":"Add note"}})],1)],1)],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }