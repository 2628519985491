<template>
  <div>
    <a-modal
      :width="900"
      :visible="visible"
      title="Setting"
      onOk="handleSubmit"
      :keyboard="true"
      :maskClosable="false"
      @change="onChangeModal"
    >
      <template slot="footer">
        <a-button key="back" @click="onCancel">Cancel</a-button>
        <a-button
          key="submit"
          type="primary"
          :loading="loading"
          @click="handleSubmit"
          >Apply</a-button
        >
      </template>
      <a-empty :image="simpleImage" v-show="allColumns.length == 0" />

      <a-form :form="form" @submit="handleSubmit">
        <a-form-item>
          <a-checkbox
            v-show="allColumns.length > 0"
            @change="selectAll"
            v-decorator="[
              'column_select',
              { valuePropName: 'checked', initialValue: false },
            ]"
            >Chọn tất cả</a-checkbox
          >
        </a-form-item>
        <a-form-item class="check-box-group">
          <a-checkbox-group
            v-decorator="['columns', { initialValue: [] }]"
            style="width: 100%"
          >
            <a-row :gutter="16">
              <a-col :span="6" v-for="column in allColumns" :key="column.key">
                <a-checkbox :value="column">{{ column.title }}</a-checkbox>
              </a-col>
            </a-row>
          </a-checkbox-group>
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>
<script>
/* eslint-disable no-console */
import { mapState } from "vuex";
import moment from "moment";
import { getCurrentUser } from "../../utils/authentication";
import { Empty } from "ant-design-vue";

export default {
  beforeCreate() {
    this.simpleImage = Empty.PRESENTED_IMAGE_SIMPLE;
  },
  components: {},
  data() {
    return {
      user: getCurrentUser(),
      form: this.$form.createForm(this, { name: "setting" }),
      loading: false,
    };
  },
  computed: {
    ...mapState({
      columns: (state) => state.role.columns,
      allColumns: (state) => state.role.allColumns,
    }),
  },
  model: {
    prop: "visible",
    event: "change",
  },
  props: {
    visible: Boolean,
    handleCancel: Function,
    showModal: Function,
  },
  methods: {
    moment,
    onChangeModal() {
      this.$emit("change", false);
    },
    onCancel() {
      this.handleCancel();
    },

    handleSubmit(e) {
      e.preventDefault();
      this.loading = true;

      this.form.validateFields(async (err, values) => {
        if (!err) {
          this.$store.commit("role/setRoleColumns", values.columns);
          this.loading = false;
          this.handleCancel();
        }
      });
    },
    selectAll(e) {
      if (e.target.checked) {
        this.form.setFieldsValue({
          columns: this.allColumns,
        });
      } else {
        this.form.resetFields();
      }
    },
  },
};
</script>
