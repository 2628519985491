/* eslint-disable no-console */
import axios from "axios";
import { API_URL, UPLOAD_IMAGE_URL } from "../config";
import { logout, getCurrentUser } from "../utils/authentication";
import { message } from "ant-design-vue";

export const code = {
  success: 1,
  error: -1,
  system_error: -2,
  session_timeout: -104,
  not_found: 404,
  parse_json_error: -20,
};

export function getParams(data) {
  const user = getCurrentUser();
  data.source = "web";
  data.product = user.data_token.product;
  return Object.keys(data)
    .map((key) => `${key}=${encodeURIComponent(data[key])}`)
    .join("&");
}

export function exportFile(path, params) {
  const user = getCurrentUser();
  params.access_token = user.access_token;
  params.source = "web";
  params.product = user.data_token.product;
  const p = getParams(params);
  return window.open(`${API_URL}/${path}?${p}`);
}

function getConfig() {
  const user = getCurrentUser();
  return {
    headers: {
      "Content-Type": "application/json",
      authorization: user ? user.access_token : "",
    },
  };
}

async function get(path, params) {
  var url = `${API_URL}/${path}`;
  if (!params) params = {};

  const p = getParams(params);
  url = `${API_URL}/${path}?${p}`;
  const config = getConfig();

  try {
    const response = await axios.get(url, config);

    if (response.data && response.data.code === code.session_timeout) {
      logout();
      return;
    }
    if (response.data && response.data.code === code.success)
      return response.data;
    else {
      message.error(response.data.message);
      return;
    }
  } catch (error) {
    message.error(error);
    return;
  }
}

async function post(path, body) {
  const url = `${API_URL}/${path}`;
  const config = getConfig();
  const user = getCurrentUser();

  body.source = "web";
  body.product = user.data_token.product;

  try {
    const response = await axios.post(url, body, config);
    if (response.data && response.data.code === code.session_timeout) {
      logout();
      return;
    }
    if (response.data && response.data.code === code.success)
      return response.data;
    else {
      message.error(response.data.message);
      return;
    }
  } catch (error) {
    message.error(error);
    return;
  }
}

async function put(path, body) {
  const url = `${API_URL}/${path}`;
  const user = getCurrentUser();
  const config = getConfig();

  body.source = "web";
  body.product = user.data_token.product;
  try {
    const response = await axios.put(url, body, config);
    if (response.data && response.data.code === code.session_timeout) {
      logout();
      return;
    }
    if (response.data && response.data.code === code.success)
      return response.data;
    else {
      message.error(response.data.message);
      return;
    }
  } catch (error) {
    message.error(error);
    return;
  }
}

async function delete_(path) {
  const url = `${API_URL}/${path}`;
  const user = getCurrentUser();

  const config = getConfig();
  config["params"] = { product: user.data_token.product, source: "web" };

  try {
    const response = await axios.delete(url, config);
    if (response.data && response.data.code === code.success)
      return response.data;
    else {
      message.error(response.data.message);
      return;
    }
  } catch (error) {
    message.error(error);
    return;
  }
}

async function uploadImage(file) {
  const config = {
    headers: { "Content-Type": "application/json" },
  };
  let url = "";

  const form_data = new FormData();
  form_data.append("file1", file, "img.jpg");

  const response = await axios.post(UPLOAD_IMAGE_URL, form_data, config);

  if (
    response.data &&
    response.data.status === 1 &&
    response.data.data.length > 0
  )
    url = response.data.data[0].url;

  return url;
}

async function getServicePromotion() {
  const url = `https://apics.ngocdunggroup.com.vn/api/ZSerProList?TblType=1`;

  try {
    const response = await axios.get(url, {
      headers: {},
    });

    if (response && response.data) return response.data;
    else {
      message.error(response.data.message);
      return [];
    }
  } catch (error) {
    message.error(error);
    return [];
  }
}

const API = {
  get,
  post,
  put,
  delete_,
  uploadImage,
  getServicePromotion,
};
export default API;
