<template>
  <div class="updateEvent">
    <a-modal
      :width="700"
      :visible="visible"
      title="Update Event"
      onOk="handleSubmit"
      :closable="false"
      :maskClosable="false"
      @change="onChangeModal"
    >
      <template slot="footer">
        <a-button key="back" @click="onCancel">Cancel</a-button>
        <a-button
          key="submit"
          type="primary"
          :loading="loading"
          @click="handleSubmit"
          >Update</a-button
        >
      </template>

      <a-form :form="form" @submit="handleSubmit">
        <a-row :gutter="16">
          <a-col :span="8">
            <a-form-item label="Tên Event">
              <a-input
                v-decorator="[
                  'name',
                  {
                    rules: [
                      { required: true, message: 'Please input event name!' },
                    ],
                    initialValue: item.name,
                  },
                ]"
                placeholder="Input event name"
              />
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="Code">
              <a-input
                placeholder="Input code"
                v-decorator="[
                  'code',
                  {
                    initialValue: item.code,
                    rules: [{ required: true, message: 'Please input code!' }],
                  },
                ]"
              />
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="Event type">
              <a-select
                v-decorator="[
                  'type',
                  {
                    initialValue: item.type,
                    rules: [
                      { required: true, message: 'Please select event type!' },
                    ],
                  },
                ]"
                placeholder="Select event type"
              >
                <a-select-option
                  v-for="val in EVENT_TYPE"
                  :key="val.type"
                  :value="val.type"
                  >{{ val.title }}</a-select-option
                >
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>

        <a-row :gutter="16">
          <a-col :span="8">
            <a-form-item label="Campaign code">
              <a-select
                showSearch
                optionFilterProp="children"
                :filterOption="filterOption"
                v-decorator="[
                  'campaign_code',
                  {
                    rules: [
                      {
                        required: true,
                        message: 'Please select campaign code!',
                      },
                    ],
                    initialValue: item.campaign_code,
                  },
                ]"
                placeholder="Select campaign code"
              >
                <a-select-option
                  v-for="val in campaigns"
                  :key="val.id"
                  :value="val.name"
                  >{{ val.id }} - {{ val.name }}</a-select-option
                >
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="Department">
              <a-input
                v-decorator="[
                  'department',
                  {
                    rules: [
                      { required: true, message: 'Please input department!' },
                    ],
                    initialValue: item.department,
                  },
                ]"
                placeholder="Input department"
              />
            </a-form-item>
          </a-col>

          <a-col :span="8">
            <a-form-item label="Team">
              <a-input
                placeholder="Input team"
                v-decorator="[
                  'team',
                  {
                    initialValue: item.team,
                    rules: [{ required: true, message: 'Please input team!' }],
                  },
                ]"
              />
            </a-form-item>
          </a-col>
        </a-row>

        <a-row :gutter="16">
          <a-col :span="8">
            <a-form-item label="Ticket Prefix">
              <a-input
                placeholder="Input ticket prefix"
                v-decorator="[
                  'ticket_prefix',
                  {
                    initialValue: item.ticket_prefix,
                    rules: [
                      {
                        required: true,
                        message: 'Please input ticket prefix!',
                      },
                    ],
                  },
                ]"
              />
            </a-form-item>
          </a-col>

          <a-col :span="8">
            <a-form-item label="Ticket Number Digit Incr">
              <a-input-number
                placeholder="Input ticket number digit incr"
                style="width: 100%"
                v-decorator="[
                  'ticket_number_digit_incr',
                  {
                    initialValue: item.ticket_number_digit_incr,
                    rules: [
                      {
                        required: true,
                        message: 'Please input ticket number digit incr!',
                      },
                    ],
                  },
                ]"
                :min="1"
              />
            </a-form-item>
          </a-col>

          <a-col :span="8">
            <a-form-item label="Ticket New Offset">
              <a-input-number
                style="width: 100%"
                placeholder="Input ticket new offset"
                v-decorator="[
                  'ticket_new_offset',
                  {
                    initialValue: item.ticket_new_offset,
                    rules: [
                      {
                        required: true,
                        message: 'Please input ticket new offset!',
                      },
                    ],
                  },
                ]"
              />
            </a-form-item>
          </a-col>
        </a-row>

        <a-row :gutter="16">
          <a-col :span="8">
            <a-form-item label="Start">
              <a-date-picker
                style="width: 100%"
                :showTime="true"
                :format="dateFormat"
                placeholder="Select start date"
                v-decorator="[
                  'start',
                  {
                    initialValue: item.start
                      ? moment(new Date(item.start), dateFormat)
                      : null,
                    rules: [
                      {
                        type: 'object',
                        required: true,
                        message: 'Please input start date!',
                      },
                    ],
                  },
                ]"
              />
            </a-form-item>
          </a-col>

          <a-col :span="8">
            <a-form-item label="End">
              <a-date-picker
                style="width: 100%"
                :format="dateFormat"
                placeholder="Select end date"
                :showTime="true"
                v-decorator="[
                  'end',
                  {
                    initialValue: item.end
                      ? moment(new Date(item.end), dateFormat)
                      : null,
                    rules: [
                      {
                        type: 'object',
                        required: true,
                        message: 'Please input end date!',
                      },
                    ],
                  },
                ]"
              />
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="Status">
              <a-select
                v-decorator="[
                  'status',
                  {
                    rules: [
                      { required: true, message: 'Please select status!' },
                    ],
                    initialValue: item.status,
                  },
                ]"
                placeholder="Select status"
              >
                <a-select-option
                  v-for="status in statusArray"
                  :key="status.value"
                  :value="status.value"
                  >{{ status.text }}</a-select-option
                >
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="16">
          <a-col :span="8">
            <a-form-item label="Button action">
              <a-select
                v-decorator="[
                  'button_action',
                  {
                    rules: [
                      {
                        required: true,
                        message: 'Please select button action!',
                      },
                    ],
                    initialValue: item.button_action,
                  },
                ]"
                placeholder="Select button_action"
              >
                <a-select-option
                  v-for="status in registerStatusArray"
                  :key="status.value"
                  :value="status.value"
                  >{{ status.text }}</a-select-option
                >
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="End Register">
              <a-date-picker
                style="width: 100%"
                :format="dateFormat"
                placeholder="Select end date"
                :showTime="true"
                v-decorator="[
                  'end_register_date',
                  {
                    initialValue:
                      item.end_register_date == null
                        ? moment(new Date())
                        : moment(new Date(item.end_register_date), dateFormat),
                    rules: [
                      {
                        type: 'object',
                        required: true,
                        message: 'Please input end date!',
                      },
                    ],
                  },
                ]"
              />
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="16">
          <a-col :span="8">
            <a-form-item label="Location">
              <a-input
                v-decorator="[
                  'location',
                  {
                    initialValue: item.location,
                  },
                ]"
                placeholder="Input location"
              />
            </a-form-item>
          </a-col>

          <a-col :span="16">
            <a-form-item>
              <label> Actions </label>
              <Tag :data="getTags" :handleGetTags="handleGetActionTags" />
            </a-form-item>
          </a-col>
        </a-row>

        <a-row :gutter="16">
          <a-col :span="8">
            <a-form-item>
              <a-checkbox
                v-decorator="[
                  'display_public',
                  {
                    valuePropName: 'checked',
                    initialValue: item.display_public,
                  },
                ]"
              >
                Display public
              </a-checkbox>
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item>
              <a-checkbox
                v-decorator="[
                  'display_internal',
                  {
                    valuePropName: 'checked',
                    initialValue: item.display_internal,
                  },
                ]"
              >
                Display internal
              </a-checkbox>
            </a-form-item>
          </a-col>
        </a-row>
        <label>
          Image
        </label>
        <UploadMultipleImage :urls="urls" :handleGetUrls="handleGetUrls" />
        <br />

        <label>
          Content
        </label>
        <ckeditor v-model="content" :config="editorConfig"></ckeditor>
      </a-form>
    </a-modal>
  </div>
</template>
<script>
/* eslint-disable no-console */

import { mapState } from "vuex";
import Tag from "../Tag";
import event from "../../api/event";
import moment from "moment";
import { getCurrentUser } from "../../utils/authentication";
import {
  statusArray,
  dateFormat,
  EVENT_TYPE,
  registerStatusArray,
} from "../../utils/help";
import CKEditor from "ckeditor4-vue";
import UploadMultipleImage from "../UploadMultipleImage";

export default {
  components: {
    Tag,
    UploadMultipleImage,
    ckeditor: CKEditor.component,
  },
  data() {
    return {
      content: "",
      editorConfig: {},
      EVENT_TYPE,
      statusArray,
      registerStatusArray,
      user: getCurrentUser(),
      dateFormat,
      tags: [],
      // isSubmit: false,
      loading: false,
      form: this.$form.createForm(this, { name: "update_event_form" }),
      url_banners: [],
    };
  },

  computed: {
    ...mapState({
      campaigns: (state) => state.campaign.data,
    }),

    getTags: function() {
      return this.item.actions;
    },
    urls: {
      get: function() {
        return this.url_banners;
      },
      set: function(urls) {
        this.url_banners = urls;
      },
    },
  },
  watch: {
    item: {
      // the callback will be called immediately after the start of the observation
      immediate: true,
      handler(nextProps) {
        if (nextProps.actions !== undefined) this.tags = nextProps.actions;
        if (nextProps.html_content !== undefined)
          this.content = nextProps.html_content;
        if (nextProps.url_banners !== undefined)
          this.urls = nextProps.url_banners;
      },
    },
  },
  model: {
    prop: "visible",
    event: "change",
  },
  props: {
    visible: Boolean,
    handleCancel: Function,
    showModal: Function,
    item: Object,
  },
  methods: {
    moment,
    onChangeModal() {
      this.form.resetFields();
      this.tags = [];
      this.urls = [];
      this.$emit("change", false);
    },
    onCancel() {
      this.handleCancel();
      this.form.resetFields();
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    handleGetActionTags(tags) {
      this.tags = tags;
    },
    handleGetUrls(urls) {
      this.urls = urls;
    },
    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields(async (err, values) => {
        if (!err) {
          this.loading = true;
          let body = {
            id: this.item.id,
            created_by: this.item.created_by,
            actions: this.tags,
            html_content: this.content,
            url_banners: this.urls,
          };

          values["start"] = new Date(values.start._d).getTime();
          values["end"] = new Date(values.end._d).getTime();
          values["end_register_date"] = new Date(
            values.end_register_date._d
          ).getTime();

          body = Object.assign(body, values);

          const data = await event.updateEvent(body);

          if (data) {
            this.$message.success(`Update successfully`);
            this.$store.dispatch("event/getAllEvents");
            this.onCancel();
          }

          this.loading = false;
        }
      });
    },
  },
};
</script>
