<template>
  <div>
    <a-spin tip="Loading..." :spinning="fetching">
      <a-form
        class="ant-advanced-search-form"
        :form="form"
        @submit="handleFilter"
      >
        <a-row :gutter="16">
          <a-col :span="6">
            <a-form-item label="Event">
              <a-select
                @change="handleChangeEvent"
                v-decorator="[
                  'event',
                  {
                    rules: [{ required: true, message: 'Please select event' }],
                  },
                ]"
                placeholder="Select event"
              >
                <a-select-option
                  v-for="val in events"
                  :key="val.id"
                  :value="val.id"
                  >{{ val.id }} - {{ val.name }}</a-select-option
                >
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="6">
            <a-form-item label="Action Type">
              <a-select
                v-decorator="[
                  'action_type',
                  {
                    rules: [
                      { required: true, message: 'Please select action type' },
                    ],
                  },
                ]"
                placeholder="Select action type"
              >
                <a-select-option
                  v-for="val in actionsEvent"
                  :key="val.type"
                  :value="val.type"
                  >{{ val.type }} - {{ val.title }}</a-select-option
                >
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="24" :style="{ textAlign: 'left' }">
            <a-button type="primary" html-type="submit">Search</a-button>
            <a-button :style="{ marginLeft: '8px' }" @click="handleReset"
              >Clear</a-button
            >
          </a-col>
        </a-row>
      </a-form>

      <Table
        style="padding:0 25px 0 25px"
        title="Events Action Logs"
        :pagination="pagination"
        :data="logs"
        :columns="columns"
        :scrollX="scrollX"
        :scrollY="700"
        :isDelete="false"
        :isUpdate="false"
        :isAdd="false"
        :handleAddVisible="() => null"
        :isShowError="error !== null"
        :errorMessage="error"
        :handleSettingColunmVisible="handleSettingColunmVisible"
      />
      <SettingColumn
        v-model="settingColumnModalVisible"
        :handleCancel="handleSettingColunmCancel"
        :showModal="handleSettingColunmVisible"
      />
    </a-spin>
  </div>
</template>

<script>
/* eslint-disable no-console */

import { mapState } from "vuex";
import Table from "../components/Table";
import SettingColumn from "../components/EventLogs/SettingColumn";

export default {
  name: "event",
  components: {
    Table,
    SettingColumn,
  },
  computed: {
    ...mapState({
      logs: (state) => state.event.logs,
      events: (state) => state.event.data,
      fetching: (state) => state.event.logsFetching,
      columns: (state) => state.event.logsColumns,
      allColumns: (state) => state.event.logsColumns,
      actionsEvent: (state) => state.event.actionsEvent,
      error: (state) => state.event.error,
    }),
    scrollX: function() {
      return this.columns.length * 150;
    },
  },
  data() {
    return {
      form: this.$form.createForm(this, { name: "search_form" }),
      pagination: { pageSize: 10 },
      settingColumnModalVisible: false,

    };
  },
  methods: {
    handleReset() {
      this.form.resetFields();
    },
    handleFilter(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          const params = {
            id: values.event,
            action_type: values.action_type,
            offset: 0,
          };
          if (values.action_type !== undefined) {
            params.action_type = values.action_type;
          }
          this.$store.dispatch("event/getEventLogs", params);
        }
      });
    },
    handleChangeEvent(event) {
      this.$store.dispatch("event/getActionEvents", { id: event });
    },
    handleSettingColunmVisible() {
      this.settingColumnModalVisible = true;
    },
    handleSettingColunmCancel() {
      this.settingColumnModalVisible = false;
    },
  },
};
</script>
