/* eslint-disable no-console */
import campaigns from '../../api/campaign'
import { renderTableColumns } from '../../utils/help'
import { code } from '../../api'

// initial state
const state = {
  data: [],
  fetching: false,
  columns: [],
  allColumns: [],
  error: null
}

// getters
const getters = {}

// actions
const actions = {
  getAllCampaigns ({ commit }) {
    commit('campaignsFetching', [])
    campaigns.getCampaigns(response => {
      if(response.code === code.success)
        commit('setCampaigns', response.data)
      else
        commit('setError', response)    
    })
  },
  
}

// mutations
const mutations = {
  campaignsFetching (state, campaigns) {
    state.data = campaigns
    state.fetching = true
  },
  setCampaigns (state, campaigns) {
    state.allColumns = renderTableColumns(campaigns)
    state.columns = state.columns.length > 0 ? state.columns : state.allColumns
    campaigns.map((b) => b['key'] = b.id) //set key to render table
    state.data = campaigns
    state.fetching = false
  },
  setError (state, response) {
    state.allColumns = []
    state.columns = []
    state.data = []
    state.fetching = false
    state.error = response.message
  },
  setCampaignColumns(state, columns) {
    state.columns = columns
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
