/* eslint-disable no-console */
import axios from "axios";
import { API_URL } from "../config";

export const ROLES = {
  SUPER_ADMIN: "superadmin",
};
const config = {
  headers: {
    "content-type": "application/json",
    "cache-control": "no-cache",
  },
};
async function login(body) {
  const url = `${API_URL}/admin/login`;
  try {
    const response = await axios.post(url, body, config);
    const { data } = response;
    return data;
  } catch (error) {
    console.error(error);
  }
}

export async function portalLogin(body) {
  const url = `${API_URL}/login/token`;
  try {
    const response = await axios.post(url, body, config);
    const { data } = response;
    return data;
  } catch (error) {
    console.error(error);
  }
}

export function logout() {
  // remove user from local storage to log user out
  try {
    localStorage.removeItem("imapi_login_user");
  } catch (error) {
    console.log(error);
  }
}

export function getCurrentUser() {
  return JSON.parse(localStorage.getItem("imapi_login_user"));
}
export function isAuthenticated() {
  const user = JSON.parse(localStorage.getItem("imapi_login_user"));
  if (user === null) return false;
  else return true;
}

export default login;
