import { sortString } from "./help";

export const RULE_ACTIONS = [
  { key: "read", text: "Read" },
  { key: "create", text: "Create" },
  { key: "update", text: "Update" },
  { key: "delete", text: "Delete" },
].sort((a, b) => sortString(a.key, b.key));

export const RULE_SUBJECT = [
  { key: "VoucherMaster", text: "VoucherMaster" },
  { key: "Campaign", text: "Campaign" },
  { key: "Voucher", text: "Voucher" },
  { key: "Event", text: "Event" },
  { key: "Report", text: "Report" },
  { key: "User", text: "User" },
  { key: "Ticket", text: "Ticket" },
].sort((a, b) => sortString(a.key, b.key));
