var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('a-modal',{attrs:{"visible":_vm.visible,"title":"Add ticket","onOk":"handleAddTicket","closable":false,"maskClosable":false},on:{"change":_vm.onChangeModal}},[_c('template',{slot:"footer"},[_c('a-button',{key:"back",on:{"click":_vm.onCancel}},[_vm._v("Cancel")]),_c('a-button',{key:"submit",attrs:{"type":"primary","loading":_vm.addLoading},on:{"click":_vm.handleAddTicket}},[_vm._v("Add")])],1),_c('a-form',{attrs:{"form":_vm.checkCusForm},on:{"submit":_vm.handleCheckCustomer}},[_c('a-row',{attrs:{"gutter":16}},[_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"Customer id"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'cus_id',
                {
                  rules: [
                    { required: true, message: 'Please input customer id' } ],
                } ]),expression:"[\n                'cus_id',\n                {\n                  rules: [\n                    { required: true, message: 'Please input customer id' },\n                  ],\n                },\n              ]"}],attrs:{"placeholder":"Input customer id"}})],1)],1),_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"colon":false,"label":" "}},[_c('a-button',{key:"submit",staticStyle:{"width":"100%"},attrs:{"type":"primary","loading":_vm.checkCustomerLoading},on:{"click":_vm.handleCheckCustomer}},[_vm._v("Check")])],1)],1)],1)],1),_c('a-form',{attrs:{"form":_vm.form},on:{"submit":_vm.handleAddTicket}},[_c('a-row',{attrs:{"gutter":16}},[_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"Event id"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:(['event_id', { initialValue: this.eventId }]),expression:"['event_id', { initialValue: this.eventId }]"}],attrs:{"disabled":true,"placeholder":"Select event"}},_vm._l((_vm.events),function(val){return _c('a-select-option',{key:val.id,attrs:{"value":val.id}},[_vm._v(" "+_vm._s(val.id)+" - "+_vm._s(val.name))])}),1)],1)],1),_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"Customer Type"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'cus_type',
                {
                  initialValue: _vm.customer_data.cus_type,
                } ]),expression:"[\n                'cus_type',\n                {\n                  initialValue: customer_data.cus_type,\n                },\n              ]"}],attrs:{"placeholder":"Input customer type"}})],1)],1)],1),_c('a-row',{attrs:{"gutter":16}},[_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"Phone"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'cus_phone',
                {
                  initialValue: _vm.customer_data.cus_phone,
                  rules: [
                    {
                      required: true,
                      message: 'Please input customer phone!',
                    } ],
                } ]),expression:"[\n                'cus_phone',\n                {\n                  initialValue: customer_data.cus_phone,\n                  rules: [\n                    {\n                      required: true,\n                      message: 'Please input customer phone!',\n                    },\n                  ],\n                },\n              ]"}],attrs:{"placeholder":"Input phone"}})],1)],1),_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"Fullname"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'cus_fullname',
                {
                  initialValue: _vm.customer_data.cus_fullname,
                  rules: [
                    {
                      required: true,
                      message: 'Please input customer fullname!',
                    } ],
                } ]),expression:"[\n                'cus_fullname',\n                {\n                  initialValue: customer_data.cus_fullname,\n                  rules: [\n                    {\n                      required: true,\n                      message: 'Please input customer fullname!',\n                    },\n                  ],\n                },\n              ]"}],attrs:{"placeholder":"Input customer fullname"}})],1)],1)],1),_c('a-row',{attrs:{"gutter":16}},[_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"Status"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'status',
                {
                  initialValue: 'active',
                  rules: [
                    { required: true, message: 'Please select status!' } ],
                } ]),expression:"[\n                'status',\n                {\n                  initialValue: 'active',\n                  rules: [\n                    { required: true, message: 'Please select status!' },\n                  ],\n                },\n              ]"}],attrs:{"placeholder":"Select status"}},_vm._l((_vm.statusArray),function(status){return _c('a-select-option',{key:status.value,attrs:{"value":status.value}},[_vm._v(_vm._s(status.text)+" ")])}),1)],1)],1)],1)],1)],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }