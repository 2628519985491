<template>
  <div>
    <a-modal
      :visible="visible"
      title="Add ticket"
      onOk="handleAddTicket"
      :closable="false"
      :maskClosable="false"
      @change="onChangeModal"
    >
      <!-- Footer Button -->
      <template slot="footer">
        <a-button key="back" @click="onCancel">Cancel</a-button>
        <a-button
          key="submit"
          type="primary"
          :loading="addLoading"
          @click="handleAddTicket"
          >Add</a-button
        >
      </template>
      <!-- Check customer id form -->
      <a-form :form="checkCusForm" @submit="handleCheckCustomer">
        <a-row :gutter="16">
          <a-col :span="12">
            <a-form-item label="Customer id">
              <a-input
                v-decorator="[
                  'cus_id',
                  {
                    rules: [
                      { required: true, message: 'Please input customer id' },
                    ],
                  },
                ]"
                placeholder="Input customer id"
              />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item :colon="false" label=" ">
              <a-button
                style="width:100%"
                type="primary"
                key="submit"
                :loading="checkCustomerLoading"
                @click="handleCheckCustomer"
                >Check</a-button
              >
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
      <!-- Add form -->
      <a-form :form="form" @submit="handleAddTicket">
        <a-row :gutter="16">
          <a-col :span="12">
            <a-form-item label="Event id">
              <a-select
                :disabled="true"
                v-decorator="['event_id', { initialValue: this.eventId }]"
                placeholder="Select event"
              >
                <a-select-option
                  v-for="val in events"
                  :key="val.id"
                  :value="val.id"
                >
                  {{ val.id }} - {{ val.name }}</a-select-option
                >
              </a-select>
            </a-form-item>
          </a-col>

          <a-col :span="12">
            <a-form-item label="Customer Type">
              <a-input
                placeholder="Input customer type"
                v-decorator="[
                  'cus_type',
                  {
                    initialValue: customer_data.cus_type,
                  },
                ]"
              />
            </a-form-item>
          </a-col>
        </a-row>

        <a-row :gutter="16">
          <a-col :span="12">
            <a-form-item label="Phone">
              <a-input
                placeholder="Input phone"
                v-decorator="[
                  'cus_phone',
                  {
                    initialValue: customer_data.cus_phone,
                    rules: [
                      {
                        required: true,
                        message: 'Please input customer phone!',
                      },
                    ],
                  },
                ]"
              />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="Fullname">
              <a-input
                placeholder="Input customer fullname"
                v-decorator="[
                  'cus_fullname',
                  {
                    initialValue: customer_data.cus_fullname,
                    rules: [
                      {
                        required: true,
                        message: 'Please input customer fullname!',
                      },
                    ],
                  },
                ]"
              />
            </a-form-item>
          </a-col>
        </a-row>

        <a-row :gutter="16">
          <a-col :span="12">
            <a-form-item label="Status">
              <a-select
                v-decorator="[
                  'status',
                  {
                    initialValue: 'active',
                    rules: [
                      { required: true, message: 'Please select status!' },
                    ],
                  },
                ]"
                placeholder="Select status"
              >
                <a-select-option
                  v-for="status in statusArray"
                  :key="status.value"
                  :value="status.value"
                  >{{ status.text }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </a-modal>
  </div>
</template>
<script>
/* eslint-disable no-console */
import ticket from "../../api/ticket";
import moment from "moment";
import customer from "../../api/customer";
import { mapState } from "vuex";
import { getCurrentUser } from "../../utils/authentication";
import { statusArray, dateFormat, hidePhone } from "../../utils/help";
export default {
  data() {
    return {
      statusArray,
      user: getCurrentUser(),
      dateFormat,
      isUpdate: false,
      addLoading: false,
      form: this.$form.createForm(this, { name: "add_ticket_form" }),
      checkCusForm: this.$form.createForm(this, {
        name: "check_customer_form",
      }),
      checkCustomerLoading: false,
      customer_data: {},
    };
  },
  computed: mapState({
    events: (state) => state.event.data,
    campaigns: (state) => state.campaign.data,
    error: (state) => state.ticket.error,
  }),
  model: {
    prop: "visible",
    event: "change",
  },
  props: {
    visible: Boolean,
    handleCancel: Function,
    showModal: Function,
    pageSize: Number,
    eventId: Number,
  },
  methods: {
    moment,
    onChangeModal() {
      this.form.resetFields();
      this.checkCusForm.resetFields();
      this.customer_data = {};
      this.$emit("change", false);
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    onCancel() {
      this.handleCancel();
      this.form.resetFields();
      this.checkCusForm.resetFields();
      this.customer_data = {};
    },
    handleAddTicket(e) {
      e.preventDefault();
      this.form.validateFields(async (err, values) => {
        if (!err) {
          this.addLoading = true;
          const cus_id = this.checkCusForm.getFieldValue("cus_id");

          let body = {
            cus_id,
            created_time: new Date().getTime(),
            created_by: this.user.data_token.username, // create on server
          };

          body = Object.assign(body, values);
          const data = await ticket.createTicket(body);
          if (data) {
            this.$message.success(`Add successfully`);
            this.onCancel();
            this.$store.dispatch("ticket/getAllTickets", {
              limit: this.pageSize,
              offset: 0,
              event_id: this.eventId,
            });
          }

          this.addLoading = false;
        }
      });
    },
    handleCheckCustomer(e) {
      e.preventDefault();
      this.checkCusForm.validateFields(async (err, values) => {
        if (!err) {
          this.customer_data = {};
          const data = await customer.getCustomerByEventId(values);
          if (data) {
            this.customer_data = data;
            let { cus_phone, cus_fullname, cus_type } = this.customer_data;

            cus_phone = hidePhone(cus_phone);

            this.form.setFieldsValue({
              cus_phone: cus_phone,
              cus_fullname: cus_fullname,
              cus_type: cus_type,
            });
          }
        }
      });
    },
  },
};
</script>
