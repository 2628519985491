<template>
  <div>
    <a-row>
      <a-col :span="6" class="left">
        <h2 style="margin-bottom: 27px">Upload Voucher</h2>
        <a-upload
          :fileList="fileList"
          :remove="handleRemove"
          @change="handleChangeFile"
          :beforeUpload="beforeUpload"
        >
          <a-button> <a-icon type="upload" /> Select File </a-button>
        </a-upload>
        <a-button
          type="primary"
          @click="handleUploadFile"
          :disabled="fileList.length === 0"
          :loading="fileUploading"
          style="margin: 16px 0px 27px 0px"
        >
          {{ fileUploading ? "Uploading" : "Start Upload" }}
        </a-button>

        <h2>Create Voucher</h2>
        <a-form :form="form" @submit="handleUploadVoucher">
          <a-form-item label="Event">
            <a-select
              v-decorator="[
                'event',
                {
                  rules: [{ required: true, message: 'Please select event!' }],
                },
              ]"
              placeholder="Select event"
            >
              <a-select-option
                v-for="val in events"
                :key="val.id"
                :value="val.id"
              >
                {{ val.id }} - {{ val.name }}</a-select-option
              >
            </a-select>
          </a-form-item>

          <a-form-item label="Start">
            <a-date-picker
              style="width: 100%"
              placeholder="Select start date"
              :format="dateFormat"
              :showTime="true"
              v-decorator="[
                'start',
                {
                  initialValue: undefined,
                  rules: [
                    {
                      type: 'object',
                      required: true,
                      message: 'Please input start date!',
                    },
                  ],
                },
              ]"
            />
          </a-form-item>

          <a-form-item label="End">
            <a-date-picker
              :showTime="true"
              style="width: 100%"
              placeholder="Select end date"
              :format="dateFormat"
              v-decorator="[
                'end',
                {
                  initialValue: undefined,
                  rules: [
                    {
                      type: 'object',
                      required: true,
                      message: 'Please input end date!',
                    },
                  ],
                },
              ]"
            />
          </a-form-item>

          <a-form-item label="Status">
            <a-select
              v-decorator="[
                'status',
                {
                  rules: [{ required: true, message: 'Please select status!' }],
                },
              ]"
              placeholder="Select status"
            >
              <a-select-option
                v-for="status in statusArray"
                :key="status.value"
                :value="status.value"
                >{{ status.text }}
              </a-select-option>
            </a-select>
          </a-form-item>

          <a-button
            style="margin-top: 16px"
            type="primary"
            key="submit"
            @click="handleUploadVoucher"
            :disabled="vouchers.length === 0"
            :loading="voucherUploading"
          >
            Create Vouchers
          </a-button>
        </a-form>
      </a-col>

      <a-col :span="18" class="right">
        <Table
          title="Voucher"
          :pagination="pagination"
          :data="vouchers"
          :columns="columns"
          :isDelete="false"
          :isUpdate="false"
          :isAdd="false"
          :handleAddVisible="() => null"
          :scrollX="2500"
          :isShowError="createError"
          :errorMessage="errorMessage"
          :handleSettingColunmVisible="() => null"
        />
      </a-col>
    </a-row>
  </div>
</template>
<script>
/* eslint-disable no-console */
import upload from "../../utils/upload";
import { mapState } from "vuex";
import { code } from "../../api";
import Table from "../../components/Table";
import voucher from "../../api/voucher";
import { getCurrentUser } from "../../utils/authentication";
import {
  statusArray,
  dateFormat,
  renderTableColumns,
} from "../../utils/help";

export default {
  components: {
    Table,
  },
  computed: mapState({
    events: (state) => state.event.data,
  }),
  data() {
    return {
      statusArray,
      user: getCurrentUser(),
      dateFormat,
      form: this.$form.createForm(this, { name: "coordinated" }),
      fileList: [],
      fileUploading: false,
      voucherUploading: false,
      file: null,
      pagination: { pageSize: 10 },
      vouchers: [],
      columns: [],
      uploadVoucherFile: null,
      createError: false,
      errorMessage: "",
    };
  },
  methods: {
    handleRemove(file) {
      const index = this.fileList.indexOf(file);
      const newFileList = this.fileList.slice();
      newFileList.splice(index, 1);
      this.fileList = newFileList;
    },
    beforeUpload(file) {
      this.fileList = [...this.fileList, file];
      return false;
    },
    handleChangeFile(info) {
      let fileList = [...info.fileList];
      this.fileList = fileList.slice(-1); //limit file uplaod
      this.file = fileList[0].originFileObj;
      this.createError = false;
    },
    async handleUploadFile() {
      const { file } = this;
      var formData = new FormData();
      formData.append("file", file);
      this.fileUploading = true;

      const response = await upload(formData);
      if (response.code === code.success) {
        this.vouchers = response.data.data;
        this.vouchers.map((customer) => (customer["key"] = customer.code));
        this.uploadVoucherFile = response.data.file;
        this.fileUploading = false;
        this.columns = renderTableColumns(response.data.data);
      }
    },
    async handleUploadVoucher(e) {
      e.preventDefault();
      this.form.validateFields(async (err, values) => {
        if (!err) {
          this.voucherUploading = true;
          const data = {
            file: this.uploadVoucherFile,
            type: "ticket",
            event_id: values.event,
            status: values.status,
            created_time: new Date().getTime(),
            created_by: this.user.data_token.username,
            start: new Date(values.start._d).getTime(),
            end: new Date(values.end._d).getTime(),
          };
          const response = await voucher.uploadVoucher(data);
          if (response.code === code.success) {
            this.voucherUploading = false;
            this.createError = false;
            this.$message.success(`Add successfully`);
          } else {
            this.voucherUploading = false;
            this.createError = true;
            this.ticketUploading = false;
            this.errorMessage = response.message;
          }
        }
      });
    },
  },
};
</script>

<style scoped>
.left {
  padding: 24px !important;
  background: #f9f0ff;
  border: 1px solid #f9f0ff;
  border-radius: 6px;
  border-left: 10px solid #391085;
  min-height: 88.1vh;
  max-height: 150vh;
}
.right {
  border-left: 10px solid #391085;
  border-radius: 6px;
  padding-left: 16px;
  min-height: 88.1vh;
  background: #fff;
}
h2 {
  color: #391085;
}
</style>
