<template>
  <div class="clearfix" style="margin-top: 12px">
    <div class="upload-image-container" v-for=" image in image_urls" :key="image">
      <div class="image-container">
        <img :src="image" style="height: 82px; width:82px" />
      </div>
      <h4 style="text-align: center;">
        <a style="color: red" @click="removeImage(image)">remove</a>
      </h4>
    </div>
    <a-upload
      style="display:inline"
      list-type="picture-card"
      :file-list="fileList"
      @preview="handlePreview"
      :before-upload="beforeUpload"
    >
      <div v-if="fileList.length < 8">
        <a-icon type="plus" />
        <div class="ant-upload-text">Upload</div>
      </div>
    </a-upload>

    <a-modal :visible="previewVisible" :footer="null" @cancel="handleCancel">
      <img alt="example" style="width: 100%" :src="previewImage" />
    </a-modal>
  </div>
</template>
<script>
/* eslint-disable no-console */

import { UPLOAD_IMAGE_URL } from "../config";
import Api from "../api";

export default {
  data() {
    return {
      UPLOAD_IMAGE_URL,
      previewVisible: false,
      previewImage: "",
      fileList: [],
      image_urls: []
    };
  },
  props: {
    urls: Array,
    handleGetUrls: Function
  },
  computed: {
    imageUrls: {
      get: function() {
        return this.image_urls;
      },
      set: function(urls) {
        this.image_urls = urls;
      }
    }
  },
  watch: {
    urls: {
      // the callback will be called immediately after the start of the observation
      immediate: true,
      handler(nextProps) {
        this.imageUrls = nextProps;
      }
    }
  },
  methods: {
    async beforeUpload(file) {
      const url = await Api.uploadImage(file);
      this.imageUrls = this.imageUrls.concat(url);
      this.$props.handleGetUrls(this.imageUrls);

      return Promise.reject();
    },
    handleCancel() {
      this.previewVisible = false;
    },
    handlePreview(file) {
      this.previewImage = file.thumbUrl;
      this.previewVisible = true;
    },
    removeImage(image) {
      this.imageUrls = this.imageUrls.filter(item => item !== image);
      this.$props.handleGetUrls(this.imageUrls);
    }
  }
};
</script>
<style>
/* you can make up upload button and sample style by using stylesheets */
.ant-upload-select-picture-card i {
  font-size: 32px;
  color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}

.upload-image-container {
  float: left;
  margin-right: 10px;
}
.image-container {
  position: relative;
  padding: 10px;
  border: 1px dashed #d9d9d9;
}
.remove-image {
  position: absolute;
  top: 0px;
  right: 0px;
  color: red;
}
</style>