<template>
  <div class="updateEvent">
    <a-modal
      :width="900"
      :visible="visible"
      title="Add event"
      onOk="handleSubmit"
      :keyboard="true"
      :maskClosable="false"
      @change="onChangeModal"
    >
      <template slot="footer">
        <a-button key="back" @click="onCancel">Cancel</a-button>
        <a-button
          key="submit"
          type="primary"
          :loading="loading"
          @click="handleSubmit"
          >Add</a-button
        >
      </template>

      <a-form :form="form" @submit="handleSubmit">
        <a-row :gutter="16">
          <a-col :span="8">
            <a-form-item label="Event name">
              <a-input
                v-decorator="[
                  'name',
                  {
                    rules: [
                      { required: true, message: 'Please input event name!' },
                    ],
                  },
                ]"
                placeholder="Input event name"
              />
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="Code">
              <a-input
                style="width: 100%"
                placeholder="Input code"
                v-decorator="[
                  'code',
                  {
                    rules: [{ required: true, message: 'Please input code!' }],
                  },
                ]"
              />
            </a-form-item>
          </a-col>

          <a-col :span="8">
            <a-form-item label="Event type">
              <a-select
                v-decorator="[
                  'type',
                  {
                    rules: [
                      { required: true, message: 'Please select event type!' },
                    ],
                  },
                ]"
                placeholder="Select event type"
              >
                <a-select-option
                  v-for="val in EVENT_TYPE"
                  :key="val.type"
                  :value="val.type"
                  >{{ val.title }}</a-select-option
                >
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>

        <a-row :gutter="16">
          <a-col :span="8">
            <a-form-item label="Campaign code">
              <a-select
                showSearch
                optionFilterProp="children"
                :filterOption="filterOption"
                v-decorator="[
                  'campaign_code',
                  {
                    rules: [
                      {
                        required: true,
                        message: 'Please select campaign code!',
                      },
                    ],
                  },
                ]"
                placeholder="Select campaign code"
              >
                <a-select-option
                  v-for="val in campaigns"
                  :key="val.id"
                  :value="val.code"
                  >{{ val.id }} - {{ val.name }}</a-select-option
                >
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="Department">
              <a-input
                v-decorator="[
                  'department',
                  {
                    rules: [
                      { required: true, message: 'Please input department!' },
                    ],
                  },
                ]"
                placeholder="Input department"
              />
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="Team">
              <a-input
                placeholder="Input team"
                v-decorator="[
                  'team',
                  {
                    rules: [{ required: true, message: 'Please input team!' }],
                  },
                ]"
              />
            </a-form-item>
          </a-col>
        </a-row>

        <a-row :gutter="16">
          <a-col :span="8">
            <a-form-item label="Ticket Prefix">
              <a-input
                placeholder="Input ticket prefix"
                v-decorator="[
                  'ticket_prefix',
                  {
                    rules: [
                      {
                        required: true,
                        message: 'Please input ticket prefix!',
                      },
                    ],
                  },
                ]"
              />
            </a-form-item>
          </a-col>

          <a-col :span="8">
            <a-form-item label="Ticket Number Digit Incr">
              <a-input-number
                placeholder="Input ticket number digit incr"
                style="width: 100%"
                v-decorator="[
                  'ticket_number_digit_incr',
                  {
                    rules: [
                      {
                        required: true,
                        message: 'Please input ticket number digit incr!',
                      },
                    ],
                  },
                ]"
                :min="1"
              />
            </a-form-item>
          </a-col>

          <a-col :span="8">
            <a-form-item label="Ticket New Offset">
              <a-input-number
                style="width: 100%"
                placeholder="Input ticket new offset"
                v-decorator="[
                  'ticket_new_offset',
                  {
                    rules: [
                      {
                        required: true,
                        message: 'Please input ticket new offset!',
                      },
                    ],
                  },
                ]"
              />
            </a-form-item>
          </a-col>
        </a-row>

        <a-row :gutter="16">
          <a-col :span="8">
            <a-form-item label="Start">
              <a-date-picker
                style="width: 100%"
                :showTime="true"
                :format="dateFormat"
                placeholder="Select start date"
                v-decorator="[
                  'start',
                  {
                    rules: [
                      {
                        type: 'object',
                        required: true,
                        message: 'Please input start date!',
                      },
                    ],
                  },
                ]"
              />
            </a-form-item>
          </a-col>

          <a-col :span="8">
            <a-form-item label="End">
              <a-date-picker
                style="width: 100%"
                :format="dateFormat"
                placeholder="Select end date"
                :showTime="true"
                v-decorator="[
                  'end',
                  {
                    rules: [
                      {
                        type: 'object',
                        required: true,
                        message: 'Please input end date!',
                      },
                    ],
                  },
                ]"
              />
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="Status">
              <a-select
                v-decorator="[
                  'status',
                  {
                    rules: [
                      { required: true, message: 'Please select status!' },
                    ],
                  },
                ]"
                placeholder="Select status"
              >
                <a-select-option
                  v-for="status in statusArray"
                  :key="status.value"
                  :value="status.value"
                  >{{ status.text }}</a-select-option
                >
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>

        <a-row :gutter="16">
          <a-col :span="8">
            <a-form-item label="Location">
              <a-input
                v-decorator="['location']"
                placeholder="Input location"
              />
            </a-form-item>
          </a-col>

          <a-col :span="16">
            <a-form-item>
              <label>Actions </label>
              <Tag :data="tags" :handleGetTags="handleGetActionTags" />
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="16">
          <a-col :span="8">
            <a-form-item>
              <a-checkbox
                v-decorator="[
                  'display_public',
                  {
                    valuePropName: 'checked',
                    initialValue: false,
                  },
                ]"
              >
                Display public
              </a-checkbox>
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item>
              <a-checkbox
                v-decorator="[
                  'display_internal',
                  {
                    valuePropName: 'checked',
                    initialValue: false,
                  },
                ]"
              >
                Display internal
              </a-checkbox>
            </a-form-item>
          </a-col>
        </a-row>
        <label>
          Image
        </label>
        <UploadMultipleImage :urls="urls" :handleGetUrls="handleGetUrls" />
        <br />

        <label>
          Content
        </label>
        <ckeditor v-model="content" :config="editorConfig"></ckeditor>
      </a-form>
    </a-modal>
  </div>
</template>
<script>
/* eslint-disable no-console */
import Tag from "../Tag";
import event from "../../api/event";
import moment from "moment";
import { mapState } from "vuex";
import { getCurrentUser } from "../../utils/authentication";
import { statusArray, dateFormat, EVENT_TYPE } from "../../utils/help";
import CKEditor from "ckeditor4-vue";
import UploadMultipleImage from "../UploadMultipleImage";

export default {
  components: {
    Tag,
    UploadMultipleImage,
    ckeditor: CKEditor.component,
  },
  data() {
    return {
      content: "",
      editorConfig: {},
      urls: [],
      EVENT_TYPE,
      statusArray,
      user: getCurrentUser(),
      dateFormat,
      isUpdate: false,
      tags: [],
      loading: false,
      form: this.$form.createForm(this, { name: "add_event_form" }),
    };
  },
  computed: {
    ...mapState({
      campaigns: (state) => state.campaign.data,
    }),
  },
  model: {
    prop: "visible",
    event: "change",
  },
  props: {
    visible: Boolean,
    handleCancel: Function,
    showModal: Function,
  },
  methods: {
    moment,
    onChangeModal() {
      this.form.resetFields();
      this.tags = [];
      this.urls = [];
      this.$emit("change", false);
    },
    onCancel() {
      this.handleCancel();
      this.form.resetFields();
      this.tags = [];
      this.urls = [];
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    handleGetActionTags(tags) {
      this.tags = tags;
    },
    handleGetUrls(urls) {
      this.urls = urls;
    },
    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields(async (err, values) => {
        if (!err) {
          this.loading = true;
          let body = {
            created_by: this.user.data_token.username, // create on server
            actions: this.tags,
            html_content: this.content,
            url_banners: this.urls,
          };
          values["start"] = new Date(values.start._d).getTime();
          values["end"] = new Date(values.end._d).getTime();

          body = Object.assign(body, values);
          const data = await event.createEvent(body);

          if (data) {
            this.$message.success(`Add successfully`);
            this.onCancel();
            this.$store.dispatch("event/getAllEvents");
          }

          this.loading = false;
        }
      });
    },
  },
};
</script>
