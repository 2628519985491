<script>
import LoginForm from "../components/LoginForm";
export default {
  name: "Login",
  components: {
    LoginForm,
  },
};
</script>

<template>
  <div class="login">
    <a-col span="5" offset="9">
      <h1>VOUCHER ADMIN</h1>
      <LoginForm />
    </a-col>
  </div>
</template>

<style scoped>
.login {
  height: 100vh;
  background: url("https://gw.alipayobjects.com/zos/rmsportal/TVYTbAXWheQpRcWDaDMu.svg");
  padding-top: 10vh;
  font-family: "Segoe UI";
}
.login h1 {
  text-align: center;
  padding-bottom: 1rem;
}
</style>
