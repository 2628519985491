<template>
  <div>
    <a-modal
      :visible="visible"
      title="Update ticket"
      :closable="false"
      :maskClosable="false"
      @change="onChangeModal"
    >
      <!-- Footer Button -->
      <template slot="footer">
        <a-button key="back" @click="onCancel">Cancel</a-button>
        <a-button
          key="submit"
          type="primary"
          :loading="loading"
          @click="handleUpdateTicket"
          >Update</a-button
        >
      </template>
      <!-- Update ticket form -->
      <a-form :form="form" @submit="handleUpdateTicket">
        <a-row :gutter="16">
          <a-col :span="12">
            <a-form-item label="Event id">
              <a-select
                :disabled="true"
                v-decorator="[
                  'event_id',
                  {
                    rules: [
                      { required: true, message: 'Please select event_id!' },
                    ],
                    initialValue: item.event_id,
                  },
                ]"
                placeholder="Select event"
              >
                <a-select-option
                  v-for="val in events"
                  :key="val.id"
                  :value="val.id"
                >
                  {{ val.id }} - {{ val.name }}</a-select-option
                >
              </a-select>
            </a-form-item>
          </a-col>

          <a-col :span="12">
            <a-form-item label="Campaign code">
              <a-select
                showSearch
                optionFilterProp="children"
                :filterOption="filterOption"
                v-decorator="[
                  'campaign_code',
                  {
                    rules: [
                      {
                        required: true,
                        message: 'Please select campaign code!',
                      },
                    ],
                    initialValue: item.campaign_id,
                  },
                ]"
                placeholder="Select campaign code"
              >
                <a-select-option
                  v-for="val in campaigns"
                  :key="val.id"
                  :value="val.id.toString()"
                >
                  {{ val.id }} - {{ val.name }}</a-select-option
                >
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>

        <a-row :gutter="16">
          <a-col :span="12">
            <a-form-item label="Phone">
              <a-input
                placeholder="Input customer phone"
                v-decorator="[
                  'cus_phone',
                  {
                    initialValue: item.cus_phone,
                  },
                ]"
              />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="Fullname">
              <a-input
                placeholder="Input customer fullname"
                v-decorator="[
                  'cus_fullname',
                  {
                    initialValue: item.cus_fullname,
                    rules: [
                      {
                        required: true,
                        message: 'Please input customer fullname!',
                      },
                    ],
                  },
                ]"
              />
            </a-form-item>
          </a-col>
        </a-row>

        <a-row :gutter="16">
          <a-col :span="12">
            <a-form-item label="Customer Type">
              <a-input
                placeholder="Input customer type"
                v-decorator="['cus_type', { initialValue: item.cus_type }]"
              />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="Status">
              <a-select
                v-decorator="[
                  'status',
                  {
                    rules: [
                      { required: true, message: 'Please select status!' },
                    ],
                    initialValue: item.status,
                  },
                ]"
                placeholder="Select status"
              >
                <a-select-option
                  v-for="status in statusArray"
                  :key="status.value"
                  :value="status.value"
                  >{{ status.text }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </a-modal>
  </div>
</template>
<script>
/* eslint-disable no-console */
import ticket from "../../api/ticket";
import moment from "moment";
import { mapState } from "vuex";
import { getCurrentUser } from "../../utils/authentication";
import { statusArray, dateFormat } from "../../utils/help";

export default {
  data() {
    return {
      statusArray,
      user: getCurrentUser(),
      dateFormat,
      loading: false,
      form: this.$form.createForm(this, { name: "update_ticket_form" }),
    };
  },
  computed: {
    ...mapState({
      events: (state) => state.event.data,
      campaigns: (state) => state.campaign.data,
      error: (state) => state.ticket.error,
    }),
  },
  model: {
    prop: "visible",
    event: "change",
  },
  props: {
    visible: Boolean,
    handleCancel: Function,
    showModal: Function,
    item: Object,
    pageSize: Number,
  },
  methods: {
    moment,
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    onChangeModal() {
      this.form.resetFields();
      this.$emit("change", false);
    },
    onCancel() {
      this.handleCancel();
      this.form.resetFields();
    },
    handleUpdateTicket(e) {
      e.preventDefault();
      this.form.validateFields(async (err, values) => {
        if (!err) {
          this.loading = true;
          let body = {
            id: this.item.id,
            created_by: this.item.created_by,
          };

          body = Object.assign(body, values);
          const data = await ticket.updateTicket(body);

          if (data) {
            this.$message.success(`Update successfully`);
            this.onCancel();
            this.$store.dispatch("ticket/getAllTickets", {
              limit: this.pageSize,
              offset: 0,
              event_id: this.item.event_id,
            });
          }

          this.loading = false;
        }
      });
    },
  },
};
</script>
