var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-form',{staticClass:"login-form",attrs:{"form":_vm.form},on:{"submit":_vm.handleSubmit}},[_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
        'userName',
        {
          rules: [{ required: true, message: 'Please input your username!' }],
        } ]),expression:"[\n        'userName',\n        {\n          rules: [{ required: true, message: 'Please input your username!' }],\n        },\n      ]"}],attrs:{"placeholder":"Username"},on:{"focus":_vm.inputFocus}},[_c('a-icon',{staticStyle:{"color":"rgba(0, 0, 0, 0.25)"},attrs:{"slot":"prefix","type":"user"},slot:"prefix"})],1)],1),_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
        'password',
        {
          rules: [{ required: true, message: 'Please input your Password!' }],
        } ]),expression:"[\n        'password',\n        {\n          rules: [{ required: true, message: 'Please input your Password!' }],\n        },\n      ]"}],attrs:{"type":"password","placeholder":"Password"},on:{"focus":_vm.inputFocus}},[_c('a-icon',{staticStyle:{"color":"rgba(0, 0, 0, 0.25)"},attrs:{"slot":"prefix","type":"lock"},slot:"prefix"})],1)],1),_c('a-form-item',[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
        'product',
        { rules: [{ required: true, message: 'Please select product!' }] } ]),expression:"[\n        'product',\n        { rules: [{ required: true, message: 'Please select product!' }] },\n      ]"}],attrs:{"placeholder":"Select product"}},[_c('a-select-option',{attrs:{"value":"ngocdung"}},[_vm._v("Ngoc Dung")]),_c('a-select-option',{attrs:{"value":"zema"}},[_vm._v("Zema")]),_c('a-select-option',{attrs:{"value":"laciana"}},[_vm._v("Laciana")]),_c('a-select-option',{attrs:{"value":"zasaly"}},[_vm._v("Zasaly")])],1)],1),_c('a-form-item',[_c('a-checkbox',{directives:[{name:"decorator",rawName:"v-decorator",value:([
        'remember',
        {
          valuePropName: 'checked',
          initialValue: true,
        } ]),expression:"[\n        'remember',\n        {\n          valuePropName: 'checked',\n          initialValue: true,\n        },\n      ]"}]},[_vm._v("Remember me")]),_c('a',{staticClass:"login-form-forgot",attrs:{"href":""}},[_vm._v("Forgot password")]),_c('a-button',{staticClass:"login-form-button",attrs:{"type":"primary","html-type":"submit","loading":_vm.loading}},[_vm._v("Log in")]),_vm._v("Or "),_c('a',{attrs:{"href":""}},[_vm._v("register now!")])],1),(_vm.message !== '')?_c('a-alert',{attrs:{"type":"error","message":_vm.message,"banner":""}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }