import API from "./index";

export default {
  getRoles(cb) {
    const params = {};
    API.get("roles", params).then((response) => {
      if (!response) {
        return;
      }
      cb(response);
    });
  },
  async createRoles(body) {
    return await API.post("roles", body);
  },
  async updateRoles(name, body) {
    return await API.put(`roles/${name}`, body);
  },
  async deleteRoles(name) {
    return await API.delete_(`roles/${name}`);
  },
};
