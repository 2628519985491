<template>
  <div>
    <a-modal
      :visible="visible"
      title="Add voucher master"
      onOk="handleAddVoucher"
      :closable="false"
      @change="onChangeModal"
      :maskClosable="false"
    >
      <template slot="footer">
        <a-button key="back" @click="onCancel">Cancel</a-button>
        <a-button
          key="submit"
          type="primary"
          :loading="loading"
          @click="handleAddVoucher"
          >Add</a-button
        >
      </template>

      <a-form :form="form" @submit="handleAddVoucher">
        <a-row :gutter="16">
          <a-col :span="12">
            <a-form-item label="Event id">
              <a-input
                :disabled="true"
                v-decorator="[
                  'event_id',
                  {
                    initialValue: this.eventId,
                    rules: [{ required: true, message: 'Please input event!' }],
                  },
                ]"
              />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="Code (Not update)">
              <a-input
                placeholder="Code"
                v-decorator="[
                  'code',
                  {
                    rules: [{ required: true, message: 'Please input code!' }],
                  },
                ]"
              />
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="16">
          <a-col :span="12">
            <a-form-item label="Slave number digit incr">
              <a-input-number
                style="width:100%"
                placeholder="Slave number digit incr"
                :min="1"
                v-decorator="[
                  'slave_number_digit_incr',
                  {
                    rules: [
                      {
                        required: true,
                        message: 'Please input slave number digit incr!',
                      },
                    ],
                  },
                ]"
              />
            </a-form-item>
          </a-col>

          <a-col :span="12">
            <a-form-item label="Slave code total">
              <a-input-number
                placeholder="Slave code total"
                style="width:100%"
                v-decorator="[
                  'slave_code_total',
                  {
                    rules: [
                      {
                        required: true,
                        message: 'Please input slave code total!',
                      },
                    ],
                  },
                ]"
              />
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="16">
          <a-col :span="12">
            <a-form-item label="Voucher Type">
              <a-select
                @change="handleChangeVoucherType"
                v-decorator="['voucher_type']"
                placeholder="Select voucher type"
              >
                <a-select-option key="default" value="default"
                  >Default</a-select-option
                >
                <a-select-option
                  key="slave_code_equals_master"
                  value="slave_code_equals_master"
                  >Slave code equals master</a-select-option
                >
                <a-select-option key="slave_encode" value="slave_encode"
                  >Slave encode</a-select-option
                >
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="Voucher Preview">
              <a-input
                placeholder="Voucher Preview"
                v-decorator="['voucher_preview']"
              />
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="16">
          <a-col :span="12">
            <a-form-item label="Discount amount">
              <a-input-number
                style="width: 100%"
                placeholder="Discount amount"
                v-decorator="[
                  'discount_amount',
                  {
                    rules: [
                      {
                        required: true,
                        message: 'Please input discount amount!',
                      },
                    ],
                  },
                ]"
                :min="0"
              />
            </a-form-item>
          </a-col>

          <a-col :span="12">
            <a-form-item label="Discount percent">
              <a-input
                placeholder="Discount percent"
                v-decorator="[
                  'discount_percent',
                  {
                    rules: [
                      {
                        required: true,
                        message: 'Please input discount percent!',
                      },
                    ],
                  },
                ]"
              />
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="16">
          <a-col :span="12">
            <a-form-item>
              <a-checkbox
                v-decorator="[
                  'cus_using_match_cus_voucher',
                  {
                    valuePropName: 'checked',
                    initialValue: false,
                  },
                ]"
                >Cus using match cus voucher</a-checkbox
              >
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item>
              <a-checkbox
                v-decorator="[
                  'dynamic_discount',
                  {
                    valuePropName: 'checked',
                    initialValue: false,
                  },
                ]"
                >Dynamic discount</a-checkbox
              >
            </a-form-item>
          </a-col>
        </a-row>
        <a-form-item label="Title">
          <a-input
            placeholder="Title"
            v-decorator="[
              'title',
              {
                rules: [{ required: true, message: 'Please input title' }],
              },
            ]"
          />
        </a-form-item>
        <a-row :gutter="16">
          <a-col :span="12">
            <a-form-item label="Partner">
              <a-input
                placeholder="Partner"
                v-decorator="['partner', { initialValue: '' }]"
              />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="Status">
              <a-select
                v-decorator="[
                  'status',
                  {
                    rules: [
                      { required: true, message: 'Please select status!' },
                    ],
                  },
                ]"
                placeholder="Select status"
              >
                <a-select-option
                  v-for="status in statusArray"
                  :key="status.value"
                  :value="status.value"
                  >{{ status.text }}</a-select-option
                >
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>

        <a-row :gutter="16">
          <a-col :span="12">
            <a-form-item label="Start">
              <a-date-picker
                style="width: 100%"
                :showTime="true"
                placeholder="Select start date"
                :format="dateFormat"
                v-decorator="[
                  'start',
                  {
                    rules: [
                      {
                        type: 'object',
                        required: true,
                        message: 'Please input start date!',
                      },
                    ],
                  },
                ]"
              />
            </a-form-item>
          </a-col>

          <a-col :span="12">
            <a-form-item label="End">
              <a-date-picker
                style="width: 100%"
                placeholder="Select end date"
                :showTime="true"
                :format="dateFormat"
                v-decorator="[
                  'end',
                  {
                    rules: [
                      {
                        type: 'object',
                        required: true,
                        message: 'Please input end date!',
                      },
                    ],
                  },
                ]"
              />
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="16">
          <a-col :span="12">
            <a-form-item label="Expire after received (day)">
              <a-input
                style="width: 100%"
                placeholder="Expire after received"
                v-decorator="[
                  'expire_after_received',
                  {
                    rules: [
                      {
                        required: false,
                        message: 'Please expire after received!',
                      },
                      { pattern: /\d+/, message: 'Please input number!' },
                    ],
                  },
                ]"
              />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="Customer receive limit">
              <a-input
                v-decorator="[
                  'cus_receive_limit',
                  {
                    rules: [
                      {
                        required: false,
                        message: 'Please input customer receive limit!',
                      },
                      { pattern: /\d+/, message: 'Please input number!' },
                    ],
                  },
                ]"
              />
            </a-form-item>
          </a-col>
        </a-row>

        <a-row :gutter="16">
          <a-col :span="12">
            <a-form-item label="Rule">
              <a-textarea
                placeholder="Add rule"
                v-decorator="['rule', { initialValue: '' }]"
              ></a-textarea>
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="Note">
              <a-textarea
                placeholder="Add note"
                v-decorator="['note', { initialValue: '' }]"
              ></a-textarea>
            </a-form-item>
          </a-col>
        </a-row>
        <a-form-item label="Promotion">
          <a-input placeholder="Add promotion" v-decorator="['promotion']" />
        </a-form-item>
        <a-form-item label="Promotion service">
          <a-select
            mode="multiple"
            v-decorator="[
              'promo_services',
              {
                rules: [
                  {
                    required: false,
                    message: 'Please select promotion services!',
                  },
                ],
                initialValue: [],
              },
            ]"
            placeholder="Promotion services"
          >
            <a-select-option
              v-for="item in promotion_services"
              :key="item.code"
              :value="item.code"
              >{{ item.code }} - {{ item.name }}</a-select-option
            >
          </a-select>
        </a-form-item>
        <a-form-item label="Promomotion services limit">
          <a-input
            placeholder="Promomotion services limit"
            v-decorator="[
              'promo_services_limit',
              {
                rules: [
                  {
                    required: false,
                    message: 'Please select promomotion services limit!',
                  },
                ],
              },
            ]"
          />
        </a-form-item>
        <a-row :gutter="16">
          <a-col :span="6">
            <a-form-item label="App Image">
              <UploadSingleImage :url="url" :handleGetUrl="handleGetUrl" />
              <span v-show="isSubmit && url === ''" style="color: #f5222d"
                >Please upload app image!</span
              >
            </a-form-item>
          </a-col>
          <a-col :span="6">
            <a-form-item label="Image Web Source">
              <UploadSingleImage
                :url="image_web_source_url"
                :handleGetUrl="handleGetImageWebUrl"
              />
              <span
                v-show="isSubmit && image_web_source_url === ''"
                style="color: #f5222d"
                >Please upload web image!</span
              >
            </a-form-item>
          </a-col>
        </a-row>

        <label>
          <span style="color: #f5222d; font-size: 12px; margin-bottom: 12px"
            >*</span
          >
          Content
        </label>
        <ckeditor v-model="content" :config="editorConfig"></ckeditor>
        <span v-show="isSubmit && content === ''" style="color: #f5222d"
          >Please input content!</span
        >
      </a-form>
    </a-modal>
  </div>
</template>
<script>
/* eslint-disable no-console */
import voucher from "../../api/voucher";
import moment from "moment";
import { mapState } from "vuex";
import { getCurrentUser } from "../../utils/authentication";
import {
  statusArray,
  dateFormat,
  convertDayToMillisecond,
  convertMillisecondToDay,
  randomString,
} from "../../utils/help";
import CKEditor from "ckeditor4-vue";
import UploadSingleImage from "../UploadSingleImage";

export default {
  components: {
    ckeditor: CKEditor.component,
    UploadSingleImage,
  },
  data() {
    return {
      url: "",
      content: "",
      editorConfig: {},
      statusArray,
      user: getCurrentUser(),
      dateFormat,
      loading: false,
      form: this.$form.createForm(this, { name: "add_voucher_form" }),
      isSubmit: false,
      image_web_source_url: "",
    };
  },

  computed: {
    ...mapState({
      total: (state) => state.voucher.total,
      promotion_services: (state) => state.voucher.promotions,
    }),
  },
  model: {
    prop: "visible",
    event: "change",
  },
  props: {
    visible: Boolean,
    handleCancel: Function,
    showModal: Function,
    pageSize: Number,
    eventId: Number,
  },
  methods: {
    moment,
    convertMillisecondToDay,
    handleGetPromotionServices(code_list) {
      const data = [];
      for (let index = 0; index < code_list.length; index++) {
        const element = code_list[index];
        const detail = this.promotion_services.find(
          (item) => item.code === element
        );
        if (detail.code) {
          data.push(detail);
        }
      }
      return data;
    },
    handleGetUrl(url) {
      this.url = url;
    },
    handleGetImageWebUrl(url) {
      this.image_web_source_url = url;
    },
    handleChangeVoucherType(type) {
      if (type === "slave_code_equals_master") {
        this.form.validateFields(["code"], { force: true }, (err) => {
          if (!err) {
            this.form.setFieldsValue({
              voucher_preview: this.form.getFieldValue("code"),
            });
          }
        });
      } else if (type === "slave_encode") {
        this.form.validateFields(
          ["code", "slave_number_digit_incr"],
          { force: true },
          (err) => {
            if (!err) {
              const number_digit_incr = this.form.getFieldValue(
                "slave_number_digit_incr"
              );
              const preview =
                this.form.getFieldValue("code") +
                randomString(number_digit_incr);
              this.form.setFieldsValue({
                voucher_preview: preview,
              });
            }
          }
        );
      } else {
        this.form.validateFields(["code"], { force: true }, (err) => {
          if (!err) {
            this.form.setFieldsValue({
              voucher_preview: this.form.getFieldValue("code") + "0001",
            });
          }
        });
      }
    },
    onChangeModal() {
      this.url = "";
      this.image_web_source_url = "";
      this.content = "";
      this.isSubmit = false;
      this.form.resetFields();
      this.$emit("change", false);
    },
    onCancel() {
      this.url = "";
      this.image_web_source_url = "";
      this.content = "";
      this.isSubmit = false;
      this.handleCancel();
      this.form.resetFields();
    },

    handleAddVoucher(e) {
      e.preventDefault();
      this.isSubmit = true;
      this.form.validateFields(async (err, values) => {
        if (!err) {
          if (
            this.content === "" ||
            this.url === "" ||
            this.image_web_source_url === ""
          ) {
            return;
          }
          this.loading = true;
          const promo_services = this.handleGetPromotionServices(
            values.promo_services
          );
          let body = {
            event_id: values.event_id,
            code: values.code,
            slave_number_digit_incr: values.slave_number_digit_incr,
            slave_code_total: values.slave_code_total,
            discount_amount: values.discount_amount,
            discount_percent: values.discount_percent,
            cus_using_match_cus_voucher: values.cus_using_match_cus_voucher,
            dynamic_discount: values.dynamic_discount,
            title: values.title,
            partner: values.partner,
            status: values.status,

            start: new Date(values.start._d).getTime(),
            end: new Date(values.end._d).getTime(),
            expire_after_received: convertDayToMillisecond(
              values.expire_after_received
            ),
            cus_receive_limit: values.cus_receive_limit,
            rule: values.rule,
            note: values.note,
            promotion: values.promotion,
            promo_services,
            promo_services_limit: values.promo_services_limit,
            description_html: this.content,
            image_source: this.url,
            image_web_source: this.image_web_source_url,
            created_by: this.user.data_token.username,
          };
          if (values.voucher_type) {
            if (values.voucher_type === "slave_code_equals_master") {
              body.slave_code_equals_master = true;
              body.slave_encode = false;
            } else if (values.voucher_type === "slave_encode") {
              body.slave_encode = true;
              body.slave_code_equals_master = false;
            } else {
              body.slave_encode = false;
              body.slave_code_equals_master = false;
            }
          }

          const data = await voucher.createVoucherMaster(body);
          if (data) {
            this.$message.success(`Add successfully`);
            this.onCancel();
            this.$store.dispatch("voucher/getAllVouchersMaster", {
              limit: this.pageSize,
              offset: 0,
              event_id: this.eventId,
            });
          }
          this.loading = false;
        }
      });
    },
  },
};
</script>
