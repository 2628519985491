import API from "./index";
import store from "../store";

/* eslint-disable no-console */

export default {
  getVouchersMaster(params, cb) {
    API.get("vouchermasters/all", params).then((response) => {
      if (!response) {
        return;
      }
      cb(response);
    });
  },
  async getServicePromotion(cb) {
    const data = await API.getServicePromotion();
    cb(data);
  },
  async getVouchers(params, cb) {
    const response = await API.get("vouchers/all", params);
    if (!response) {
      store.commit("voucher/voucherError");
      return;
    }
    cb(response.data);
  },
  async getVouchersByCode(params, cb) {
    const response = await API.get("vouchers", params);
    const logs = response && response.data !== undefined ? [response.data] : [];
    const data = {
      logs: logs,
      total: logs.length,
    };
    cb(data);
  },
  async createVoucherMaster(values) {
    return await API.post("vouchermasters", values);
  },

  async updateVoucherMaster(values) {
    return await API.put("vouchermasters", values);
  },
  async uploadVoucher(values) {
    return await API.post("vouchers/upload/creation", values);
  },
  async getVoucherDetail(params) {
    return await API.get("vouchers/all", params);
  },
  async createVoucher(values) {
    return await API.post("vouchers", values);
  },
  async createMultipleVoucher(values) {
    return await API.post("vouchers/list", values);
  },
  async updateVoucher(values) {
    return await API.put("vouchers", values);
  },
};
