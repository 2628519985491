<template>
  <a-spin tip="Loading..." :spinning="fetching">
    <div>
      <Table
        style="padding:0 25px 0 25px"
        title="Role"
        :pagination="pagination"
        :data="roles"
        :columns="columns"
        :scrollX="scrollX"
        :isDelete="true"
        :isUpdate="true"
        :isAdd="true"
        :isResetPassword="false"
        :handleUpdateVisible="handleUpdateVisible"
        :handleAddVisible="handleAddVisible"
        :handleDeleteVisible="handleDeleteVisible"
        :isShowError="error !== null"
        :errorMessage="error"
        :handleSettingColunmVisible="handleSettingColunmVisible"
      />

      <UpdateRole
        v-model="updateModalVisible"
        :handleCancel="handleUpdateCancel"
        :showModal="handleUpdateVisible"
        :item="setItem"
      />

      <AddRole
        v-model="addModalVisible"
        :handleCancel="handleAddModalCancel"
        :showModal="handleAddVisible"
      />

      <SettingColumn
        v-model="settingColumnModalVisible"
        :handleCancel="handleSettingColunmCancel"
        :showModal="handleSettingColunmVisible"
      />
    </div>
  </a-spin>
</template>

<script>
/* eslint-disable no-console */

import { mapState } from "vuex";
import Table from "../components/Table";
import UpdateRole from "../components/Role/UpdateRole";
import AddRole from "../components/Role/AddRole";
import SettingColumn from "../components/Role/SettingColumn";
import role from "../api/role";
import { message } from "ant-design-vue";

export default {
  name: "role",
  components: {
    Table,
    UpdateRole,
    AddRole,
    SettingColumn,
  },
  created() {},
  computed: {
    ...mapState({
      roles: (state) => state.role.data,
      fetching: (state) => state.role.fetching,
      columns: (state) => state.role.columns,
      error: (state) => state.role.error,
    }),
    scrollX: function() {
      return this.columns.length * 150 + 50;
    },
    setItem: function() {
      return this.item;
    },
  },
  data() {
    return {
      form: this.$form.createForm(this, { name: "search_form" }),
      item: {},
      accessControlVisible: false,
      updateModalVisible: false,
      addModalVisible: false,
      settingColumnModalVisible: false,
      pagination: { pageSize: 10 },
    };
  },
  methods: {
    handleUpdateVisible(item) {
      this.updateModalVisible = true;
      this.item = item;
    },
    handleUpdateCancel() {
      this.updateModalVisible = false;
    },

    handleAddVisible() {
      this.addModalVisible = true;
    },
    handleAddModalCancel() {
      this.addModalVisible = false;
    },

    handleSettingColunmVisible() {
      this.settingColumnModalVisible = true;
    },
    handleSettingColunmCancel() {
      this.settingColumnModalVisible = false;
    },
    async handleDeleteVisible(item) {
      const $store = this.$store;
      this.$confirm({
        title: "Confrim",
        content: "Do you want to delete?",
        cancelText: "Cancel",
        async onOk() {
          const response = await role.deleteRoles(item.name);
          if (response) {
            message.success("Delete successfully");
            $store.dispatch("role/getAllRoles", {});
          }
        },
        onCancel() {},
      });
    },
  },
};
</script>
