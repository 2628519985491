<template>
  <div class="clearfix">
    <div class="upload-image-container" v-if="image_url !== ''">
      <div class="image-container">
        <img :src="image_url" style="height: 82px; width:82px" />
      </div>
      <h4 style="text-align: center;">
        <a style="color: red" @click="removeImage">remove</a>
      </h4>
    </div>
    <a-upload
      style="display:inline"
      list-type="picture-card"
      :file-list="fileList"
      @preview="handlePreview"
      :before-upload="beforeUpload"
    >
      <div v-if="image_url === ''">
        <a-icon type="plus" />
        <div class="ant-upload-text">Upload</div>
      </div>
    </a-upload>

    <a-modal :visible="previewVisible" :footer="null" @cancel="handleCancel">
      <img alt="example" style="width: 100%" :src="previewImage" />
    </a-modal>
  </div>
</template>
<script>
/* eslint-disable no-console */

import { UPLOAD_IMAGE_URL } from "../config";
import Api from "../api";

export default {
  data() {
    return {
      UPLOAD_IMAGE_URL,
      previewVisible: false,
      previewImage: "",
      fileList: [],
      image_url: ""
    };
  },
  props: {
    url: String,
    handleGetUrl: Function
  },
  computed: {
    imageUrl: {
      get: function() {
        return this.image_url;
      },
      set: function(url) {
        this.image_url = url;
      }
    }
  },
  watch: {
    url: {
      // the callback will be called immediately after the start of the observation
      immediate: true,
      handler(nextProps) {
        this.imageUrl = nextProps;
      }
    }
  },
  methods: {
    async beforeUpload(file) {
      const url = await Api.uploadImage(file);
      this.imageUrl = url;
      this.$props.handleGetUrl(this.imageUrl);

      return Promise.reject();
    },
    handleCancel() {
      this.previewVisible = false;
    },
    handlePreview(file) {
      this.previewImage = file.thumbUrl;
      this.previewVisible = true;
    },
    removeImage() {
      this.imageUrl = "";
      this.$props.handleGetUrl(this.imageUrl);
    }
  }
};
</script>
<style>
/* you can make up upload button and sample style by using stylesheets */
.ant-upload-select-picture-card i {
  font-size: 32px;
  color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}

.upload-image-container {
  float: left;
  margin-right: 10px;
}
.image-container {
  position: relative;
  padding: 10px;
  border: 1px dashed #d9d9d9;
}
.remove-image {
  position: absolute;
  top: 0px;
  right: 0px;
  color: red;
}
</style>