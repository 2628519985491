<template>
  <a-spin tip="Loading..." :spinning="fetching">
    <div class="base">
      <Table
        title="Campaigns"
        :pagination="pagination"
        :data="campaigns"
        :columns="columns"
        :scrollX="scrollX"
        :scrollY="700"
        :isDelete="false"
        :isApprove="false"
        :isAdd="true"
        :isUpdate="true"
        :handleUpdateVisible="handleUpdateVisible"
        :handleApprove="handleApprove"
        :handleAddVisible="handleAddVisible"
        :handleSettingColunmVisible="handleSettingColunmVisible"
        :isShowError="error !== null"
        :errorMessage="error"
      />

      <UpdateCampaign
        v-model="updateModalVisible"
        :handleCancel="handleUpdateCancel"
        :showModal="handleUpdateVisible"
        :item="setItem"
      />

      <AddCampaign
        v-model="addModalVisible"
        :handleCancel="handleAddModalCancel"
        :showModal="handleAddVisible"
      />

      <SettingColumn
        v-model="settingColumnModalVisible"
        :handleCancel="handleSettingColunmCancel"
        :showModal="handleSettingColunmVisible"
      />
    </div>
  </a-spin>
</template>

<script>
/* eslint-disable no-console */

import { mapState } from "vuex";
import { STATUS } from "../utils/help";
import Table from "../components/Table";
import UpdateCampaign from "../components/Campaign/UpdateCampaign";
import AddCampaign from "../components/Campaign/AddCampaign";
import SettingColumn from "../components/Campaign/SettingColumn";
import campaign from "../api/campaign";

export default {
  name: "campaign",
  components: {
    Table,
    UpdateCampaign,
    AddCampaign,
    SettingColumn,
  },
  computed: {
    ...mapState({
      campaigns: (state) => state.campaign.data,
      fetching: (state) => state.campaign.fetching,
      columns: (state) => state.campaign.columns,
      error: (state) => state.campaign.error,
    }),
    setItem: function() {
      return this.item;
    },
    scrollX: function() {
      return this.columns.length * 150 + 200;
    },
  },
  data() {
    return {
      item: {},
      pagination: { pageSize: 10 },
      updateModalVisible: false,
      addModalVisible: false,
      settingColumnModalVisible: false,
    };
  },
  methods: {
    handleUpdateVisible(item) {
      this.updateModalVisible = true;
      this.item = item;
    },
    handleUpdateCancel() {
      this.updateModalVisible = false;
    },

    handleAddVisible() {
      this.addModalVisible = true;
    },
    handleAddModalCancel() {
      this.addModalVisible = false;
    },
    handleSettingColunmVisible() {
      this.settingColumnModalVisible = true;
    },
    handleSettingColunmCancel() {
      this.settingColumnModalVisible = false;
    },

    renderStatus(status) {
      if (status === STATUS.ACTIVE.VALUE) return STATUS.ACTIVE.TEXT;
      else if (status === STATUS.DEACTIVE.VALUE) return STATUS.DEACTIVE.TEXT;
      else return "Waiting Approval";
    },
    async handleApprove(item) {
      const data = {
        id: item.id,
        name: item.name,
        code: item.code,
        start: item.start,
        end: item.end,
        status: "active",
        created_by: item.created_by,
      };
      const isSuccess = await campaign.updateCampaign(data);
      if (isSuccess) {
        this.$message.success(`Approve successfully`);
        this.$store.dispatch("campaign/getAllCampaigns");
      }
    },
  },
};
</script>
