<script>
/* eslint-disable no-console */
export default {
  props: {
    title: String,

    pagination: Object,
    data: Array,
    columns: Array,
    scrollX: Number,
    scrollY: Number,

    isAdd: Boolean,
    handleAddVisible: Function,

    isUpdate: Boolean,
    handleUpdateVisible: Function,

    isDelete: Boolean,
    handleDeleteVisible: Function,

    isApprove: Boolean,
    handleApprove: Function,

    isResetPassword: Boolean,
    handleResetPassword: Function,

    isShowError: Boolean,
    errorMessage: String,

    isShowInfor: Boolean,
    handleShowInforVisible: Function,

    handleSettingColunmVisible: Function,

    rowSelection: Object,
    isUpdateMultiple: Boolean,
    handleUpdatetMultipleVisible: Function,
  },
};
</script>

<template>
  <a-table
    :columns="columns"
    :dataSource="data"
    :scroll="{ y: scrollY, x: scrollX }"
    :pagination="pagination !== undefined ? pagination : false"
    :row-selection="rowSelection"
  >
    <!-- bordered -->
    <template slot="title">
      {{ title }}
      <a-tooltip placement="top">
        <template slot="title">
          <span>Add</span>
        </template>
        <a-button
          class="add-button"
          type="primary"
          shape="circle"
          icon="plus"
          size="small"
          @click="handleAddVisible"
          v-show="isAdd"
        />
      </a-tooltip>

      <a-tooltip placement="top">
        <template slot="title">
          <span>Setting</span>
        </template>
        <a-button
          class="add-button"
          type="primary"
          shape="circle"
          icon="setting"
          size="small"
          @click="handleSettingColunmVisible"
        />
      </a-tooltip>

      <a-tooltip placement="top">
        <template slot="title">
          <span>Edit Multiple</span>
        </template>
        <a-button
          type="primary"
          shape="circle"
          icon="edit"
          size="small"
          class="add-button"
          v-show="isUpdateMultiple"
          @click="
            () => {
              if (isUpdateMultiple) handleUpdatetMultipleVisible();
            }
          "
        />
      </a-tooltip>
      <br />
      <h4 class="error" v-show="isShowError">{{ errorMessage }}</h4>
    </template>

    <a href="javascript:" slot="action" slot-scope="text, record">
      <a-tooltip placement="top">
        <template slot="title">
          <span>View</span>
        </template>
        <a-icon
          type="eye"
          @click="handleShowInforVisible(record)"
          v-show="isShowInfor"
        />
      </a-tooltip>
      <a-divider type="vertical" v-show="isShowInfor" slot="action" />

      <a-tooltip placement="top">
        <template slot="title">
          <span>Edit</span>
        </template>
        <a-icon
          type="edit"
          @click="handleUpdateVisible(record)"
          v-show="isUpdate"
        />
      </a-tooltip>

      <a-divider type="vertical" v-show="isDelete" slot="action" />

      <a-icon
        type="delete"
        v-show="isDelete"
        @click="handleDeleteVisible(record)"
      />

      <a-divider
        type="vertical"
        slot="action"
        v-show="record.status === 'waiting_approval' && isApprove"
      />
      <a-tooltip placement="top">
        <template slot="title">
          <span>Approve</span>
        </template>
        <a-icon
          type="check"
          @click="handleApprove(record)"
          v-show="record.status === 'waiting_approval' && isApprove"
        />
      </a-tooltip>

      <a-divider type="vertical" v-show="isResetPassword" slot="action" />
      <a-tooltip placement="top">
        <template slot="title">
          <span>Reset Password</span>
        </template>
        <a-icon
          type="reload"
          @click="handleResetPassword(record)"
          v-show="isResetPassword"
        />
      </a-tooltip>
    </a>
  </a-table>
</template>

<style scoped>
.error {
  display: inline;
  color: red;
}
</style>
