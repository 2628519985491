import API from "./index"

/* eslint-disable no-console */

export default {
  getTickets(params, cb) { 
    API.get(`tickets/all`, params) //tickets/all
    .then((response) => {
      if (!response) {
        return
      }
      cb(response)
    })
  },
  
  async createTicket(values) {
    return await API.post('tickets', values)
  },

  async updateTicket(values) {
    return await API.put('tickets', values)
  },
  async uploadTicket(values) {
    return await API.post('tickets/upload/creation', values)
  }

}