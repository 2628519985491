/* eslint-disable no-console */
import events from "../../api/event";
import { renderTableColumns } from "../../utils/help";
import { code } from "../../api";

// initial state
const state = {
  data: [],
  fetching: false,
  logs: [],
  logsFetching: false,
  error: null,
  allEventColumns: [],
  eventColumns: [],

  logsColumns: [],
  allLogsColumns: [],
  actionsEvent: [],
  errorMessage: null,
};

// getters
const getters = {};

// actions
const actions = {
  getAllEvents({ commit }, params) {
    commit("eventFetching");
    events.getEvents(params, (response) => {
      if (response.code === code.success) commit("setEvents", response.data);
      else commit("setError", response);
    });
  },
  // event logs
  getEventLogs({ commit }, params) {
    commit("eventLogsFetching");
    events.getEventLogs(params, (response) => {
      if (response.code === code.success) commit("setLogs", response.data);
      else commit("setErrorLogs", response);
    });
  },
  getActionEvents({ commit }, params) {
    events.getActionEvents(params, (response) => {
      commit("setActionEvents", response);
    });
  },
};

// mutations
const mutations = {
  eventFetching(state) {
    (state.data = []), (state.fetching = true);
  },
  setEvents(state, events) {
    state.allEventColumns = renderTableColumns(events);
    state.eventColumns =
      state.eventColumns.length > 0
        ? state.eventColumns
        : state.allEventColumns;
    events.map((b) => (b["key"] = b.id)); //set key to render table
    (state.data = events), (state.fetching = false);
  },
  setError(state, response) {
    state.allEventColumns = [];
    state.eventColumns = [];
    state.data = [];
    state.fetching = false;
    state.error = response.message;
  },

  eventLogsFetching(state) {
    state.logs = [];
    state.logsFetching = true;
  },
  setLogs(state, data) {
    state.allLogsColumns = renderTableColumns(data.logs);
    state.logsColumns =
      state.logsColumns.length > 0 ? state.logsColumns : state.allLogsColumns;
    data.logs.map((b) => (b["key"] = b._id)); //set key to render table
    state.logs = data.logs;
    state.logsFetching = false;
  },
  setErrorLogs(state, response) {
    state.logsColumns = [];
    state.allLogsColumns = [];
    state.logs = [];
    state.logsFetching = false;
    state.error = response.message;
  },
  setActionEvents(state, response) {
    const { data, message } = response;
    if (response.code === code.success) {
      state.actionsEvent = data;
      state.errorMessage = null;
    } else {
      state.actionsEvent = [];
      state.errorMessage = message;
    }
  },
  setEventColumns(state, eventColumns) {
    state.eventColumns = eventColumns;
  },
  setEventLogsColumns(state, eventLogsColumns) {
    state.logsColumns = eventLogsColumns;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
