import API from "./index";

/* eslint-disable no-console */

export default {
  getRules(cb) {
    const params = {};
    API.get("rules", params).then((response) => {
      if (!response) {
        return;
      }
      cb(response);
    });
  },
  async createRule(body) {
    return await API.post("rules", body);
  },
  async updateRules(name, body) {
    return await API.put(`rules/${name}`, body);
  },
  async deleteRules(name) {
    return await API.delete_(`rules/${name}`);
  },
};
