import { sortString } from "./help";

export const INPUT_TYPE = {
  TEXT: "input_text",
  NUMBER: "input_number",
  TEXTAREA: "textarea",
  CHECKBOX: "checkbox",
  SELECT: "select",
  DATETIME: "input_date_time",
  EDITOR: "editor",
};

export const VOUCHER_MASTER_UPDATE_FIELD = [
  { text: "Prefix", value: "prefix", input_type: INPUT_TYPE.TEXT },
  {
    text: "Discount amount",
    value: "discount_amount",
    input_type: INPUT_TYPE.NUMBER,
  },
  {
    text: "Discount percent",
    value: "discount_percent",
    input_type: INPUT_TYPE.TEXT,
  },
  {
    text: "Slave number digit incr",
    value: "slave_number_digit_incr",
    input_type: INPUT_TYPE.NUMBER,
  },
  {
    text: "Slave code total",
    value: "slave_code_total",
    input_type: INPUT_TYPE.NUMBER,
  },
  { text: "Slave prefix", value: "slave_prefix", input_type: INPUT_TYPE.TEXT },
  { text: "Partner", value: "partner", input_type: INPUT_TYPE.TEXT },
  { text: "Status", value: "status", input_type: INPUT_TYPE.SELECT },
  { text: "Start", value: "start", input_type: INPUT_TYPE.DATETIME },
  { text: "End", value: "end", input_type: INPUT_TYPE.DATETIME },
  {
    text: "Expire after received (day)",
    value: "expire_after_received",
    input_type: INPUT_TYPE.NUMBER,
  },
  {
    text: "Customer receive limit",
    value: "cus_receive_limit",
    input_type: INPUT_TYPE.TEXT,
  },
  {
    text: "Slave encode",
    value: "slave_encode",
    input_type: INPUT_TYPE.CHECKBOX,
  },
  {
    text: "Slave code equals master",
    value: "slave_code_equals_master",
    input_type: INPUT_TYPE.CHECKBOX,
  },
  {
    text: "Cus using match cus voucher",
    value: "cus_using_match_cus_voucher",
    input_type: INPUT_TYPE.CHECKBOX,
  },
  { text: "Rule", value: "rule", input_type: INPUT_TYPE.TEXTAREA },
  { text: "Note", value: "note", input_type: INPUT_TYPE.TEXTAREA },
  { text: "Content", value: "description_html", input_type: INPUT_TYPE.EDITOR },
  { text: "Image", value: "image_source", input_type: INPUT_TYPE.TEXT },
].sort((a, b) => sortString(a.text, b.text));
//image_source
