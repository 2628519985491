import Vue from "vue";
import Vuex from "vuex";
import createLogger from "../plugins/logger";
import event from "./modules/event";
import voucher from "./modules/voucher";
import user from "./modules/user";
import campaign from "./modules/campaign";
import ticket from "./modules/ticket";
import rule from "./modules/rule";
import role from "./modules/role";

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== "production";

export default new Vuex.Store({
  modules: {
    event,
    voucher,
    user,
    campaign,
    ticket,
    rule,
    role,
  },
  strict: debug,
  plugins: debug ? [createLogger()] : [],
});
