/* eslint-disable no-console */
import roles from "../../api/role";
import { renderTableColumns } from "../../utils/help";
import { code } from "../../api";

// initial state
const state = {
  data: [],
  fetching: false,
  columns: [],
  allColumns: [],
  error: null,
};

// getters
const getters = {};

// actions
const actions = {
  getAllRoles({ commit }) {
    commit("rolesFetching", []);
    roles.getRoles((response) => {
      if (response.code === code.success) commit("setRole", response.data);
      else commit("setError", response);
    });
  },
};

// mutations
const mutations = {
  rolesFetching(state, roles) {
    state.data = roles;
    state.fetching = true;
  },
  setRole(state, data) {
    const { items } = data;
    state.allColumns = renderTableColumns(items);
    state.columns = state.columns.length > 0 ? state.columns : state.allColumns;
    items.map((b) => (b["key"] = b.name)); //set key to render table
    state.data = items;
    state.fetching = false;
  },
  setError(state, response) {
    state.allColumns = [];
    state.columns = [];
    state.data = [];
    state.fetching = false;
    state.error = response.message;
  },
  setRoleColumns(state, columns) {
    state.columns = columns;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
