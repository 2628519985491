var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('a-spin',{attrs:{"tip":"Loading...","spinning":_vm.fileUploading || _vm.ticketUploading}},[_c('a-row',{staticStyle:{"background-color":"rgb(241,242,245)"}},[_c('a-col',{staticClass:"left",attrs:{"span":6}},[_c('h2',[_vm._v("Upload Ticket")]),_c('a-upload',{attrs:{"fileList":_vm.fileList,"remove":_vm.handleRemove,"beforeUpload":_vm.beforeUpload},on:{"change":_vm.handleChangeFile}},[_c('a-button',[_c('a-icon',{attrs:{"type":"upload"}}),_vm._v("Select File ")],1)],1),_c('a-button',{staticStyle:{"margin":"16px 0px 16px 0px"},attrs:{"type":"primary","disabled":_vm.fileList.length === 0,"loading":_vm.fileUploading},on:{"click":_vm.handleUploadFile}},[_vm._v(_vm._s(_vm.fileUploading ? "Uploading" : "Start Upload"))]),_c('h2',[_vm._v("Create Ticket")]),_c('a-form',{attrs:{"form":_vm.form},on:{"submit":_vm.handleUploadTicket}},[_c('a-form-item',{attrs:{"label":"Event"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'event',
                {
                  rules: [
                    { required: true, message: 'Please select event!' } ],
                } ]),expression:"[\n                'event',\n                {\n                  rules: [\n                    { required: true, message: 'Please select event!' },\n                  ],\n                },\n              ]"}],attrs:{"placeholder":"Select event"}},_vm._l((_vm.events),function(val){return _c('a-select-option',{key:val.id,attrs:{"value":val.id}},[_vm._v(_vm._s(val.id)+" - "+_vm._s(val.name))])}),1)],1),_c('a-form-item',[_c('a-checkbox',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'sync_phone',
                { valuePropName: 'checked', initialValue: false } ]),expression:"[\n                'sync_phone',\n                { valuePropName: 'checked', initialValue: false },\n              ]"}]},[_vm._v("Sync phone")])],1),_c('a-form-item',{attrs:{"label":"Status"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'status',
                {
                  rules: [
                    { required: true, message: 'Please select status!' } ],
                } ]),expression:"[\n                'status',\n                {\n                  rules: [\n                    { required: true, message: 'Please select status!' },\n                  ],\n                },\n              ]"}],attrs:{"placeholder":"Select status"}},_vm._l((_vm.statusArray),function(status){return _c('a-select-option',{key:status.value,attrs:{"value":status.value}},[_vm._v(_vm._s(status.text))])}),1)],1),_c('a-button',{key:"submit",staticStyle:{"margin-top":"16px"},attrs:{"type":"primary","disabled":_vm.customers.length === 0,"loading":_vm.ticketUploading},on:{"click":_vm.handleUploadTicket}},[_vm._v("Create Ticket")])],1)],1),_c('a-col',{staticClass:"right",attrs:{"span":18}},[_c('Table',{attrs:{"title":"Customers","pagination":_vm.pagination,"data":_vm.customers,"columns":_vm.columns,"isDelete":false,"isUpdate":false,"isAdd":false,"handleAddVisible":function () { return null; },"scrollX":2000,"isShowError":_vm.createError,"errorMessage":_vm.errorMessage,"handleSettingColunmVisible":function () { return null; }}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }