/* eslint-disable no-console */
import vouchers from "../../api/voucher";
import { renderTableColumns } from "../../utils/help";
import { code } from "../../api";

// initial state
const state = {
  data: [],
  fetching: false,
  total: 0,
  allVoucherMasterColumns: [],
  voucherMasterColumns: [],

  allVouchersDetailColumns: [],
  vouchersDetailColumns: [],
  vouchersDetailData: [],
  vouchersDetailTotal: 0,
  vouchersDetailFetching: false,
  error: null,

  promotions: [],
};

// getters
const getters = {};

// actions
const actions = {
  getAllVouchersMaster({ commit }, params) {
    commit("voucherMasterFetching");
    vouchers.getVouchersMaster(params, (response) => {
      if (response.code === code.success)
        commit("setVouchersMaster", response.data);
      else commit("setErrorVouchersMaster", response);
    });
  },
  getAllServicePromotion({ commit }) {
    vouchers.getServicePromotion((_data) => {
      commit("setServicePromotion", _data);
    });
  },
  getVouchersByVoucherMaster({ commit }, params) {
    commit("voucherFetching");
    vouchers.getVouchers(params, (voucher) => commit("setVouchers", voucher));
  },
  getVouchersByCode({ commit }, params) {
    commit("voucherFetching");
    vouchers.getVouchersByCode(params, (voucher) =>
      commit("setVouchers", voucher)
    );
  },
  resetVoucherMaster({ commit }) {
    commit("resetVoucherMaster");
  },
  resetVoucher({ commit }) {
    commit("resetVoucher");
  },
};

// mutations
const mutations = {
  setServicePromotion(state, data) {
    state.promotions = data;
  },
  voucherMasterFetching(state) {
    state.data = [];
    state.fetching = true;
    state.total = 0;
  },

  setVouchersMaster(state, vouchers) {
    state.allVoucherMasterColumns = renderTableColumns(vouchers.logs);
    state.voucherMasterColumns =
      state.voucherMasterColumns.length > 0
        ? state.voucherMasterColumns
        : state.allVoucherMasterColumns;
    vouchers.logs.map((b) => (b["key"] = b.id)); //set key for render table
    state.data = vouchers.logs;
    state.fetching = false;
    state.total = vouchers.total;
  },
  setErrorVouchersMaster(state, response) {
    state.allVoucherMasterColumns = [];
    state.voucherMasterColumns = [];
    state.data = [];
    state.fetching = false;
    state.total = 0;
    state.error = response.message;
  },
  voucherFetching(state) {
    state.vouchersDetailData = [];
    state.vouchersDetailFetching = true;
    state.vouchersDetailTotal = 0;
  },
  setVouchers(state, vouchers) {
    state.allVouchersDetailColumns = renderTableColumns(vouchers.logs);
    state.vouchersDetailColumns =
      state.vouchersDetailColumns.length > 0
        ? state.vouchersDetailColumns
        : state.allVouchersDetailColumns;
    vouchers.logs.map((b) => (b["key"] = b.id)); //set key for render table
    state.vouchersDetailData = vouchers.logs;
    state.vouchersDetailTotal = vouchers.total;
    state.vouchersDetailFetching = false;
  },
  resetVoucherMaster(state) {
    state.data = [];
  },
  resetVoucher(state) {
    state.allVouchersDetailColumns = [];
    (state.vouchersDetailData = []), (state.vouchersDetailColumns = []);
    state.vouchersDetailTotal = 0;
  },
  setVoucherMasterColumns(state, columns) {
    state.voucherMasterColumns = columns;
  },
  setVoucherColumns(state, columns) {
    state.vouchersDetailColumns = columns;
  },
  voucherError(state) {
    state.vouchersDetailData = [];
    state.vouchersDetailFetching = false;
    state.vouchersDetailTotal = 0;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
