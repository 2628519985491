<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
import { getCurrentUser } from "./utils/authentication";
/* eslint-disable no-console */

export default {
  name: "app",
  created() {
    const user = getCurrentUser();
    if (user) {
      this.$store.dispatch("event/getAllEvents");
      this.$store.dispatch("campaign/getAllCampaigns");
      this.$store.dispatch("role/getAllRoles", {});
      this.$store.dispatch("rule/getAllRules", {});
      this.$store.dispatch("voucher/getAllServicePromotion");
    }
  },
};
</script>

<style>
#app {
  /* font-family: 'Avenir', Helvetica, Arial, sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.ant-table-title {
  font-size: 1.5em;
  font-weight: 500;
  color: #391085;
}
.ant-drawer-title {
  color: #391085 !important;
}
.ant-advanced-search-form {
  padding: 24px !important;
  background: #f9f0ff;
  border-left: 10px solid #391085;
  border-radius: 6px;
}
.add-button {
  margin-left: 10px;
}
.base {
  padding: 0px 24px 0px 24px;
}
/* ::-webkit-scrollbar {
  display: inline;
} */
.ant-pagination {
  margin-bottom: 25px !important;
  margin-left: 25px !important;
}
.ant-modal-title {
  color: #391085 !important;
}
</style>
