<template>
  <div>
    <a-spin tip="Loading..." :spinning="fetching">
      <a-form
        class="ant-advanced-search-form"
        :form="form"
        @submit="handleFilter"
      >
        <a-row :gutter="16">
          <a-col :span="6">
            <a-form-item label="Event">
              <a-select
                allowClear
                v-decorator="[
                  'event_id',
                  {
                    rules: [
                      { required: false, message: 'Please select event' },
                    ],
                  },
                ]"
                placeholder="Select event"
              >
                <a-select-option
                  v-for="val in events"
                  :key="val.id"
                  :value="val.id"
                  >{{ val.id }} - {{ val.name }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="6">
            <a-form-item label="Status">
              <a-select v-decorator="['status']" placeholder="Select status">
                <a-select-option
                  v-for="status in statusArray"
                  :key="status.value"
                  :value="status.value"
                  >{{ status.text }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="6">
            <a-form-item label="Code">
              <a-input v-decorator="['code']" placeholder="Code" />
            </a-form-item>
          </a-col>
          <a-col :span="6">
            <a-form-item label="Discount amount">
              <a-input
                v-decorator="['discount_amount']"
                placeholder="Discount amount"
              />
            </a-form-item>
          </a-col>
        </a-row>

        <a-row>
          <a-col :span="24" :style="{ textAlign: 'right' }">
            <a-button type="primary" html-type="submit">Search</a-button>
            <a-button
              :style="{ marginLeft: '8px' }"
              @click="handleExport"
              :disabled="eventId === null"
              >Export</a-button
            >
            <a-button :style="{ marginLeft: '8px' }" @click="handleReset"
              >Clear</a-button
            >
          </a-col>
        </a-row>
      </a-form>
      <Table
        style="padding:0 25px 0 25px"
        title="Vouchers Master"
        :data="vouchers"
        :columns="voucherMasterColumns"
        :scrollX="scrollX"
        :scrollY="700"
        :isDelete="false"
        :isApprove="false"
        :isUpdate="true"
        :isAdd="eventId !== null"
        :isShowInfor="true"
        :handleUpdateVisible="handleUpdateVisible"
        :handleApprove="handleApprove"
        :handleAddVisible="handleAddVisible"
        :handleShowInforVisible="goToVoucherDetail"
        :handleSettingColunmVisible="handleSettingColunmVisible"
        :isShowError="error !== null"
        :errorMessage="error"
        :rowSelection="rowSelection"
        :isUpdateMultiple="isUpdateMultiple"
        :handleUpdatetMultipleVisible="handleUpdatetMultipleVisible"
      />
      <br />
      <a-pagination
        v-show="vouchers.length > 0"
        :current="current"
        :pageSizeOptions="pageSizeOptions"
        showSizeChanger
        :pageSize="pageSize"
        @showSizeChange="onShowSizeChange"
        @change="handleChangePage"
        :total="total"
      >
        <template slot="buildOptionText" slot-scope="props">
          <span>{{ props.value }} / page</span>
        </template>
      </a-pagination>

      <UpdateVoucherMaster
        v-model="updateModalVisible"
        :handleCancel="handleUpdateCancel"
        :showModal="handleUpdateVisible"
        :item="setItem"
        :currentPage="current"
        :handleChangePage="handleChangePage"
      />

      <AddVoucherMaster
        v-model="addModalVisible"
        :handleCancel="handleAddModalCancel"
        :showModal="handleAddVisible"
        :pageSize="pageSize"
        :eventId="eventId"
      />
      <SettingColumn
        v-model="settingColumnModalVisible"
        :handleCancel="handleSettingColunmCancel"
        :showModal="handleSettingColunmVisible"
      />

      <UpdateMultipleVoucherMaster
        v-model="updateMultipleVisible"
        :handleCancel="handleUpdatetMultipleCancel"
        :showModal="handleUpdatetMultipleVisible"
        :item="{}"
        :currentPage="current"
        :handleChangePage="handleChangePage"
        :selectedItems="selectedItems"
      />
    </a-spin>
  </div>
</template>

<script>
/* eslint-disable no-console */
import Table from "../components/Table";
import AddVoucherMaster from "../components/VoucherMaster/AddVoucherMaster";
import SettingColumn from "../components/VoucherMaster/SettingColumn";
import UpdateVoucherMaster from "../components/VoucherMaster/UpdateVoucherMaster";
import UpdateMultipleVoucherMaster from "../components/VoucherMaster/UpdateMultipleVoucherMaster";
import { mapState } from "vuex";
import { statusArray, removeUndefined } from "../utils/help";
import { exportFile } from "../api";

export default {
  name: "voucher",
  components: {
    Table,
    UpdateVoucherMaster,
    AddVoucherMaster,
    SettingColumn,
    UpdateMultipleVoucherMaster,
  },
  computed: {
    ...mapState({
      vouchers: (state) => state.voucher.data,
      fetching: (state) => state.voucher.fetching,
      allVoucherMasterColumns: (state) => state.voucher.allVoucherMasterColumns,
      voucherMasterColumns: (state) => state.voucher.voucherMasterColumns,
      total: (state) => state.voucher.total,
      events: (state) => state.event.data,
      actionsEvent: (state) => state.event.actionsEvent,
      error: (state) => state.voucher.error,
    }),
    scrollX: function() {
      return this.voucherMasterColumns.length * 150 + 150;
    },
    setItem: function() {
      return this.item;
    },
  },
  data() {
    return {
      statusArray,
      expand: false,
      form: this.$form.createForm(this, { name: "search_form" }),
      pageSizeOptions: ["10", "20", "30", "40", "50"],
      current: 1,
      pageSize: 10,
      eventId: null,
      item: {},
      updateModalVisible: false,
      addModalVisible: false,
      showInforVisible: false,
      settingColumnModalVisible: false,
      isUpdateMultiple: false,
      updateMultipleVisible: false,
      selectedItems: [],
      rowSelection: {
        onChange: (selectedRowKeys) => {
          this.selectedItems = selectedRowKeys;
        },
        onSelect: (record, selected) => {
          this.isUpdateMultiple = selected;
        },
        onSelectAll: (selected) => {
          this.isUpdateMultiple = selected;
        },
        fixed: true,
      },
    };
  },
  methods: {
    handleUpdateVisible(item) {
      this.updateModalVisible = true;
      this.item = item;
    },
    handleUpdateCancel() {
      this.updateModalVisible = false;
    },

    handleAddVisible() {
      this.addModalVisible = true;
    },
    handleAddModalCancel() {
      this.addModalVisible = false;
    },
    handleSettingColunmVisible() {
      this.settingColumnModalVisible = true;
    },
    handleSettingColunmCancel() {
      this.settingColumnModalVisible = false;
    },

    goToVoucherDetail(item) {
      this.$router.push({
        name: "voucher",
        params: { code: item.code, event: item.event_id },
      });
    },
    handleShowInforCancel() {
      this.showInforVisible = false;
    },

    handleChangePage(currentPage) {
      this.current = currentPage;
      const params = {
        limit: this.pageSize,
        offset: (currentPage - 1) * this.pageSize,
        event_id: this.eventId,
      };

      this.$store.dispatch("voucher/getAllVouchersMaster", params);
    },
    onShowSizeChange(currentPage, pageSize) {
      this.current = 1;
      this.pageSize = pageSize;
      const params = {
        limit: pageSize,
        offset: 0,
        event_id: this.eventId,
      };
      this.form.resetFields();
      this.form.setFieldsValue({
        event_id: this.eventId,
      });
      this.$store.dispatch("voucher/getAllVouchersMaster", params);
    },
    handleApprove() {},
    // handleChangeEvent(event) {
    //   this.eventId = event;
    //   this.current = 1;
    //   const params = {
    //     limit: this.pageSize,
    //     offset: 0,
    //     event_id: event,
    //   };
    //   this.$store.dispatch("voucher/getAllVouchersMaster", params);
    //   this.$store.dispatch("event/getActionEvents", { id: event });
    // },
    handleFilter(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          this.eventId = values.event_id;
          this.current = 1;
          let params = {
            limit: this.pageSize,
            offset: 0,
          };
          values = removeUndefined(values); //remove undefined value[key]
          params = Object.assign(params, values);

          this.$store.dispatch("voucher/getAllVouchersMaster", params);
        }
      });
    },
    handleReset() {
      this.form.resetFields();
    },

    toggle() {
      this.expand = !this.expand;
    },
    handleExport(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          values = removeUndefined(values);
          exportFile("vouchers/export_file_data", values);
        }
      });
    },
    handleUpdatetMultipleVisible() {
      this.updateMultipleVisible = true;
    },
    handleUpdatetMultipleCancel() {
      this.updateMultipleVisible = false;
    },
  },
  destroyed() {
    this.$store.dispatch("voucher/resetVoucherMaster");
  },
};
</script>
