var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"updateEvent"},[_c('a-modal',{attrs:{"visible":_vm.visible,"title":"Add user","onOk":"handleAddUser","closable":false,"maskClosable":false},on:{"change":_vm.onChangeModal}},[_c('template',{slot:"footer"},[_c('a-button',{key:"back",on:{"click":_vm.onCancel}},[_vm._v("Cancel")]),_c('a-button',{key:"submit",attrs:{"type":"primary","loading":_vm.loading},on:{"click":_vm.handleAddUser}},[_vm._v("Add")])],1),_c('a-form',{attrs:{"form":_vm.form},on:{"submit":_vm.handleAddUser}},[_c('a-form-item',{attrs:{"label":"User name"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'username',
            {
              rules: [{ required: true, message: 'Please input username!' }],
            } ]),expression:"[\n            'username',\n            {\n              rules: [{ required: true, message: 'Please input username!' }],\n            },\n          ]"}],attrs:{"autocomplete":"new-user","placeholder":"Input username"}})],1),_c('a-form-item',{attrs:{"label":"Password"}},[_c('a-input-password',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'password',
            {
              rules: [{ required: true, message: 'Please input password' }],
            } ]),expression:"[\n            'password',\n            {\n              rules: [{ required: true, message: 'Please input password' }],\n            },\n          ]"}],attrs:{"type":"password","autocomplete":"new-password","placeholder":"Input password"}})],1),_c('a-form-item',{attrs:{"label":"Full name"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'fullname',
            {
              rules: [{ required: true, message: 'Please input fullname' }],
            } ]),expression:"[\n            'fullname',\n            {\n              rules: [{ required: true, message: 'Please input fullname' }],\n            },\n          ]"}],attrs:{"placeholder":"Input full name"}})],1),_c('a-form-item',{attrs:{"label":"Email"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'email',
            { rules: [{ required: true, message: 'Please input email' }] } ]),expression:"[\n            'email',\n            { rules: [{ required: true, message: 'Please input email' }] },\n          ]"}],attrs:{"placeholder":"Input email"}})],1),_c('a-form-item',{attrs:{"label":"Phone"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'phone',
            { rules: [{ required: true, message: 'Please input phone' }] } ]),expression:"[\n            'phone',\n            { rules: [{ required: true, message: 'Please input phone' }] },\n          ]"}],attrs:{"placeholder":"Input phone"}})],1),_c('a-form-item',{attrs:{"label":"Address"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'address',
            {
              rules: [{ required: true, message: 'Please input address' }],
            } ]),expression:"[\n            'address',\n            {\n              rules: [{ required: true, message: 'Please input address' }],\n            },\n          ]"}],attrs:{"placeholder":"Input address"}})],1),_c('a-row',{attrs:{"gutter":16}},[_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"Team"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'team',
                {
                  rules: [{ required: true, message: 'Please input team' }],
                } ]),expression:"[\n                'team',\n                {\n                  rules: [{ required: true, message: 'Please input team' }],\n                },\n              ]"}],attrs:{"placeholder":"Input team"}})],1)],1),_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"Department"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'department',
                {
                  rules: [
                    { required: true, message: 'Please input department' } ],
                } ]),expression:"[\n                'department',\n                {\n                  rules: [\n                    { required: true, message: 'Please input department' },\n                  ],\n                },\n              ]"}],attrs:{"placeholder":"Input department"}})],1)],1)],1),_c('a-row',{attrs:{"gutter":16}},[_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"Scope"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'scope',
                {
                  rules: [{ required: true, message: 'Please input scope' }],
                } ]),expression:"[\n                'scope',\n                {\n                  rules: [{ required: true, message: 'Please input scope' }],\n                },\n              ]"}],attrs:{"placeholder":"Input scope"}})],1)],1),_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"Status"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'status',
                {
                  rules: [
                    { required: true, message: 'Please select status!' } ],
                } ]),expression:"[\n                'status',\n                {\n                  rules: [\n                    { required: true, message: 'Please select status!' },\n                  ],\n                },\n              ]"}],attrs:{"placeholder":"Select status"}},_vm._l((_vm.statusArray),function(status){return _c('a-select-option',{key:status.value,attrs:{"value":status.value}},[_vm._v(" "+_vm._s(status.text)+" ")])}),1)],1)],1)],1),_c('a-form-item',{attrs:{"label":"Rules"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'rules',
            {
              rules: [{ required: false, message: 'Please select rules!' }],
            } ]),expression:"[\n            'rules',\n            {\n              rules: [{ required: false, message: 'Please select rules!' }],\n            },\n          ]"}],attrs:{"mode":"tags","placeholder":"rules"}},_vm._l((_vm.rules),function(item){return _c('a-select-option',{key:item.name,attrs:{"value":item.name}},[_vm._v(_vm._s(item.name))])}),1)],1),_c('a-form-item',{attrs:{"label":"Role"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'role',
            {
              rules: [{ required: true, message: 'Please select roles!' }],
            } ]),expression:"[\n            'role',\n            {\n              rules: [{ required: true, message: 'Please select roles!' }],\n            },\n          ]"}],attrs:{"placeholder":"role"}},_vm._l((_vm.roles),function(item){return _c('a-select-option',{key:item.name,attrs:{"value":item.name}},[_vm._v(_vm._s(item.name))])}),1)],1)],1)],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }