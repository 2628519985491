import API from "./index";

/* eslint-disable no-console */

export default {
  getEvents(params, cb) {
    API.get("events/all", params).then((response) => {
      if (!response) {
        return;
      }
      cb(response);
    });
  },
  async getActionEvents(params, cb) {
    const response = await API.get("events/actions", params);
    cb(response);
  },
  async createEvent(values) {
    return await API.post("events", values);
  },

  async updateEvent(values) {
    return await API.put("events", values);
  },

  getEventLogs(params, cb) {
    API.get(`events/tickets/history`, params).then((response) => {
      if (!response) {
        return;
      }
      cb(response);
    });
  },
};
