var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('a-modal',{attrs:{"visible":_vm.visible,"title":"Add campaign","onOk":"handleAddCampaign","closable":false,"maskClosable":false},on:{"change":_vm.onChangeModal}},[_c('template',{slot:"footer"},[_c('a-button',{key:"back",on:{"click":_vm.onCancel}},[_vm._v("Cancel")]),_c('a-button',{key:"submit",attrs:{"type":"primary","loading":_vm.loading},on:{"click":_vm.handleAddCampaign}},[_vm._v("Add")])],1),_c('a-form',{attrs:{"form":_vm.form},on:{"submit":_vm.handleAddCampaign}},[_c('a-row',{attrs:{"gutter":16}},[_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"Code"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'code',
                {
                  rules: [{ required: true, message: 'Please input code!' }],
                } ]),expression:"[\n                'code',\n                {\n                  rules: [{ required: true, message: 'Please input code!' }],\n                },\n              ]"}],attrs:{"placeholder":"Input code"}})],1)],1),_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"Campaign name"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'name',
                {
                  rules: [{ required: true, message: 'Please input name!' }],
                } ]),expression:"[\n                'name',\n                {\n                  rules: [{ required: true, message: 'Please input name!' }],\n                },\n              ]"}],attrs:{"placeholder":"Input campaign name"}})],1)],1)],1),_c('a-row',{attrs:{"gutter":16}},[_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"Start"}},[_c('a-date-picker',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'start',
                {
                  rules: [
                    {
                      type: 'object',
                      required: true,
                      message: 'Please input start date!',
                    } ],
                } ]),expression:"[\n                'start',\n                {\n                  rules: [\n                    {\n                      type: 'object',\n                      required: true,\n                      message: 'Please input start date!',\n                    },\n                  ],\n                },\n              ]"}],staticStyle:{"width":"100%"},attrs:{"format":_vm.dateFormat,"showTime":true}})],1)],1),_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"End"}},[_c('a-date-picker',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'end',
                {
                  rules: [
                    {
                      type: 'object',
                      required: true,
                      message: 'Please input end date!',
                    } ],
                } ]),expression:"[\n                'end',\n                {\n                  rules: [\n                    {\n                      type: 'object',\n                      required: true,\n                      message: 'Please input end date!',\n                    },\n                  ],\n                },\n              ]"}],staticStyle:{"width":"100%"},attrs:{"showTime":true,"format":_vm.dateFormat}})],1)],1)],1)],1)],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }