<template>
  <div>
    <a-modal
      :visible="visible"
      title="Update multiple voucher master"
      onOk="handleUpdateMultiple"
      :closable="false"
      :maskClosable="false"
      @change="onChangeModal"
    >
      <template slot="footer">
        <a-button key="back" @click="onCancel">Cancel</a-button>
        <a-button
          key="submit"
          type="primary"
          :loading="loading"
          @click="handleUpdateMultiple"
          >Update</a-button
        >
      </template>
      <a-form :form="form" @submit="handleUpdateMultiple">
        <a-form-item label="Field">
          <a-select
            v-decorator="[
              'field',
              {
                rules: [{ required: true, message: 'Please select field!' }],
              },
            ]"
            placeholder="Select field"
            @change="handleChangeField"
          >
            <a-select-option
              v-for="field in VOUCHER_MASTER_UPDATE_FIELD"
              :key="field.value"
              :value="field.value"
              >{{ field.text }}</a-select-option
            >
          </a-select>
        </a-form-item>
        <a-form-item :label="inputLabel" v-if="inputType !== INPUT_TYPE.EDITOR">
          <a-input
            v-if="inputType === INPUT_TYPE.TEXT"
            v-decorator="[
              'value',
              {
                rules: [{ required: true, message: 'Please input value!' }],
              },
            ]"
          />
          <a-input-number
            style="width: 100%"
            v-if="inputType === INPUT_TYPE.NUMBER"
            v-decorator="[
              'value',
              {
                rules: [{ required: true, message: 'Please input value!' }],
              },
            ]"
          />
          <a-select
            v-if="inputType === INPUT_TYPE.SELECT"
            v-decorator="[
              'value',
              {
                rules: [{ required: true, message: 'Please input value!' }],
              },
            ]"
          >
            <a-select-option
              v-for="status in statusArray"
              :key="status.value"
              :value="status.value"
            >
              {{ status.text }}
            </a-select-option>
          </a-select>
          <a-checkbox
            v-if="inputType === INPUT_TYPE.CHECKBOX"
            v-decorator="[
              'value',
              {
                valuePropName: 'checked',
              },
            ]"
          >
          </a-checkbox>
          <a-textarea
            v-if="inputType === INPUT_TYPE.TEXTAREA"
            v-decorator="['value']"
          />
          <a-date-picker
            v-if="inputType === INPUT_TYPE.DATETIME"
            style="width: 100%"
            :showTime="true"
            :format="dateFormat"
            v-decorator="[
              'value',
              {
                rules: [
                  {
                    type: 'object',
                    required: true,
                    message: 'Please input start date!',
                  },
                ],
              },
            ]"
          />
        </a-form-item>
        <div v-if="inputType === INPUT_TYPE.EDITOR">
          <label>
            <span style="color: #f5222d; font-size: 12px; margin-bottom: 12px"
              >*</span
            >
            Content
          </label>
          <ckeditor v-model="description_html"></ckeditor>
        </div>
      </a-form>
    </a-modal>
  </div>
</template>
<script>
/* eslint-disable no-console */
import voucher from "../../api/voucher";
import { mapState } from "vuex";
import { getCurrentUser } from "../../utils/authentication";
import {
  statusArray,
  dateFormat,
  convertDayToMillisecond,
} from "../../utils/help";
import {
  VOUCHER_MASTER_UPDATE_FIELD,
  INPUT_TYPE,
} from "../../utils/voucher-master-helper";
import CKEditor from "ckeditor4-vue";

export default {
  components: {
    ckeditor: CKEditor.component,
  },
  data() {
    return {
      content: "",
      editorConfig: {},
      statusArray,
      user: getCurrentUser(),
      dateFormat,
      loading: false,
      form: this.$form.createForm(this, { name: "update_voucher_form" }),
      isSubmit: false,
      VOUCHER_MASTER_UPDATE_FIELD,
      INPUT_TYPE,
      inputType: "",
      inputLabel: "",
    };
  },

  computed: {
    ...mapState({
      total: (state) => state.voucher.total,
      events: (state) => state.event.data,
    }),
    description_html: {
      get: function() {
        return this.content;
      },
      set: function(content) {
        this.content = content;
      },
    },
  },
  model: {
    prop: "visible",
    event: "change",
  },
  props: {
    visible: Boolean,
    handleCancel: Function,
    showModal: Function,
    handleChangePage: Function,
    currentPage: Number,
    selectedItems: Array,
  },
  methods: {
    onChangeModal() {
      this.isSubmit = false;
      this.form.resetFields();
      this.$emit("change", false);
    },
    onCancel() {
      this.isSubmit = false;
      this.handleCancel();
      this.form.resetFields();
    },
    handleChangeField(value) {
      const field = VOUCHER_MASTER_UPDATE_FIELD.find(
        (item) => item.value === value
      );
      this.inputType = field.input_type;
      this.inputLabel = field.text;
    },
    handleUpdateMultiple(e) {
      e.preventDefault();
      this.isSubmit = true;
      this.form.validateFields(async (err, values) => {
        if (!err) {
          this.loading = true;
          const body = {};
          body[values.field] = values.value;
          if (this.inputType === INPUT_TYPE.EDITOR) {
            body[values.field] = this.description_html;
          }
          if (this.inputType === INPUT_TYPE.DATETIME) {
            body[values.field] = new Date(values.value._d).getTime();
          }
          if (body.expire_after_received) {
            body.expire_after_received = convertDayToMillisecond(
              body.expire_after_received
            );
          }

          var i = 0;

          this.selectedItems.forEach((item) => {
            setTimeout(async () => {
              body.id = item;
              const data = await voucher.updateVoucherMaster(body);
              if (data) {
                this.$message.success(`Update successfully ${item}`);
              }
            }, i * 500);
            i++;
          });
          setTimeout(() => {
            this.onCancel();
            this.handleChangePage(this.currentPage);
            this.loading = false;
          }, i * 500);
        }
      });
    },
  },
};
</script>
