<template>
  <div>
    <a-modal
      :visible="visible"
      title="Add campaign"
      onOk="handleAddCampaign"
      :closable="false"
      :maskClosable="false"
      @change="onChangeModal"
    >
      <template slot="footer">
        <a-button key="back" @click="onCancel">Cancel</a-button>
        <a-button
          key="submit"
          type="primary"
          :loading="loading"
          @click="handleAddCampaign"
          >Add</a-button
        >
      </template>

      <a-form :form="form" @submit="handleAddCampaign">
        <a-row :gutter="16">
          <a-col :span="12">
            <a-form-item label="Code">
              <a-input
                placeholder="Input code"
                v-decorator="[
                  'code',
                  {
                    rules: [{ required: true, message: 'Please input code!' }],
                  },
                ]"
              />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="Campaign name">
              <a-input
                placeholder="Input campaign name"
                v-decorator="[
                  'name',
                  {
                    rules: [{ required: true, message: 'Please input name!' }],
                  },
                ]"
              />
            </a-form-item>
          </a-col>
        </a-row>

        <a-row :gutter="16">
          <a-col :span="12">
            <a-form-item label="Start">
              <a-date-picker
                style="width: 100%"
                :format="dateFormat"
                :showTime="true"
                v-decorator="[
                  'start',
                  {
                    rules: [
                      {
                        type: 'object',
                        required: true,
                        message: 'Please input start date!',
                      },
                    ],
                  },
                ]"
              />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="End">
              <a-date-picker
                style="width: 100%"
                :showTime="true"
                :format="dateFormat"
                v-decorator="[
                  'end',
                  {
                    rules: [
                      {
                        type: 'object',
                        required: true,
                        message: 'Please input end date!',
                      },
                    ],
                  },
                ]"
              />
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </a-modal>
  </div>
</template>
<script>
/* eslint-disable no-console */
import campaign from "../../api/campaign";
import moment from "moment";
import { mapState } from "vuex";
import { getCurrentUser } from "../../utils/authentication";
import { dateFormat } from "../../utils/help";

export default {
  data() {
    return {
      user: getCurrentUser(),
      dateFormat,
      loading: false,
      form: this.$form.createForm(this, { name: "add_campaign_form" }),
    };
  },
  computed: mapState({}),
  model: {
    prop: "visible",
    event: "change",
  },
  props: {
    visible: Boolean,
    handleCancel: Function,
    showModal: Function,
  },
  methods: {
    moment,
    onCancel() {
      this.handleCancel();
      this.form.resetFields();
    },
    onChangeModal() {
      this.form.resetFields();
      this.$emit("change", false);
    },
    handleAddCampaign(e) {
      e.preventDefault();
      this.form.validateFields(async (err, values) => {
        if (!err) {
          this.loading = true;
          let body = {
            created_by: this.user.data_token.username, // create on server
            status: "waiting_approval",
          };
          values["start"] = new Date(values.start._d).getTime();
          values["end"] = new Date(values.end._d).getTime();

          body = Object.assign(body, values);
          const data = await campaign.createCampaign(body);

          if (data) {
            this.$message.success(`Add successfully`);
            this.onCancel();
            this.$store.dispatch("campaign/getAllCampaigns");
          }

          this.loading = false;
        }
      });
    },
  },
};
</script>
