/* eslint-disable no-console */
import API from "./index";

export default {
  getUsers(params, cb) {
    API.get("users/all", params).then((response) => {
      response.data = response.data.filter(
        (item) => item.username !== "superadmin"
      );
      cb(response);
    });
  },
  async createUser(values) {
    return await API.post("users", values);
  },

  async updateUser(values) {
    return await API.put("users", values);
  },
  async changePassword(data) {
    return await API.put("users/reset_password", data);
  },
};
