<template>
  <div class="updateEvent">
    <a-modal
      :visible="visible"
      title="Update rule"
      onOk="handleUpdate"
      :closable="false"
      :maskClosable="false"
      @change="onChangeModal"
    >
      <template slot="footer">
        <a-button key="back" @click="onCancel">Cancel</a-button>
        <a-button
          key="submit"
          type="primary"
          :loading="loading"
          @click="handleUpdate"
          >Update</a-button
        >
      </template>

      <a-form :form="form" @submit="handleUpdate">
        <a-form-item label="Name">
          <a-input
            placeholder="name"
            v-decorator="[
              'name',
              {
                initialValue: item.name,
                rules: [
                  {
                    required: true,
                    message: 'Please input name!',
                  },
                ],
              },
            ]"
          />
        </a-form-item>

        <a-form-item label="Actions">
          <a-select
            v-decorator="[
              'actions',
              {
                initialValue: item.actions || undefined,
                rules: [{ required: true, message: 'Please select actions!' }],
              },
            ]"
            placeholder="actions"
          >
            <a-select-option
              v-for="item in RULE_ACTIONS"
              :key="item.key"
              :value="item.key"
              >{{ item.text }}</a-select-option
            >
          </a-select>
        </a-form-item>
        <a-form-item label="Subject">
          <a-select
            v-decorator="[
              'subject',
              {
                initialValue: item.subject,
                rules: [{ required: true, message: 'Please select subject!' }],
              },
            ]"
            placeholder="subject"
          >
            <a-select-option
              v-for="item in RULE_SUBJECT"
              :key="item.key"
              :value="item.key"
              >{{ item.text }}</a-select-option
            >
          </a-select>
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>
<script>
/* eslint-disable no-console */
import rule from "../../api/rule";
import { mapState } from "vuex";
import { getCurrentUser } from "../../utils/authentication";
import { statusArray, dateFormat } from "../../utils/help";
import { RULE_ACTIONS, RULE_SUBJECT } from "../../utils/constant";

export default {
  data() {
    return {
      RULE_ACTIONS,
      RULE_SUBJECT,
      statusArray,
      currentUser: getCurrentUser(),
      dateFormat,
      loading: false,
      form: this.$form.createForm(this, { name: "update_user_form" }),
    };
  },

  computed: {
    ...mapState({}),
  },
  model: {
    prop: "visible",
    event: "change",
  },
  props: {
    visible: Boolean,
    handleCancel: Function,
    showModal: Function,
    item: Object,
  },
  methods: {
    onChangeModal() {
      this.form.resetFields();
      this.$emit("change", false);
    },
    onCancel() {
      this.handleCancel();
      this.form.resetFields();
    },
    handleUpdate(e) {
      e.preventDefault();
      this.form.validateFields(async (err, values) => {
        if (!err) {
          this.loading = true;
          const body = {
            name: values.name,
            actions: values.actions,
            subject: values.subject,
          };

          const data = await rule.updateRules(this.item.name, body);

          if (data) {
            this.$message.success(`Update successfully`);
            this.onCancel();
            this.$store.dispatch("rule/getAllRules", {});
          }

          this.loading = false;
        }
      });
    },
  },
};
</script>
