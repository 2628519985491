/* eslint-disable no-console */
import Vue from "vue";
import Router from "vue-router";

import Home from "../views/Home";
import Login from "../views/Login";
import PortalLogin from "../views/PortalLogin";
import PageNotFound from "../views/PageNotFound";
import Event from "../views/Event";
import ActionLogs from "../views/EventActionLogs";
import VoucherMaster from "../views/VoucherMaster";
import UploadVoucher from "../components/Voucher/Upload";
import Voucher from "../views/Voucher";
import User from "../views/User";
import Role from "../views/Role";
import Rule from "../views/Rule";
import Campaign from "../views/Campaign";
import Ticket from "../views/Ticket";
import UploadTicket from "../components/Ticket/Upload";
import Report from "../views/Report";

import {
  isAuthenticated,
  getCurrentUser,
  ROLES,
} from "../utils/authentication";

Vue.use(Router);

export const route = {
  home: "/",
  notFound: "*",
  notAccess: "/page_not_access",
  login: "/login",
  portal_login: "/portal-login",
  event: "/event",
  voucher_master: "/voucher-master",
  voucher: "/voucher",
  upload_voucher: "/upload-voucher",
  user: "/user",
  role: "/role",
  campaign: "/campaign",
  ticket: "/ticket",
  upload_ticket: "/ticket/upload",
  event_action_logs: "/event-action-logs",
  report: "/report",
  rule: "/rule",
};
export const menu = [
  {
    name: "event",
    path: route.event,
    component: Event,
    // alias: route.home
  },
  {
    name: "event_action_logs",
    path: route.event_action_logs,
    component: ActionLogs,
  },
  {
    name: "voucher_master",
    path: route.voucher_master,
    component: VoucherMaster,
  },
  {
    name: "voucher",
    path: route.voucher,
    component: Voucher,
  },
  {
    name: "upload_voucher",
    path: route.upload_voucher,
    component: UploadVoucher,
  },
  {
    name: "user",
    path: route.user,
    component: User,
  },
  {
    name: "role",
    path: route.role,
    component: Role,
  },
  {
    name: "rule",
    path: route.rule,
    component: Rule,
  },
  {
    name: "campaign",
    path: route.campaign,
    component: Campaign,
  },
  {
    name: "ticket",
    path: route.ticket,
    component: Ticket,
  },
  {
    name: "upload_ticket",
    path: route.upload_ticket,
    component: UploadTicket,
  },
  {
    name: "report",
    path: route.report,
    component: Report,
  },
];
const routers = new Router({
  // mode: "history",
  routes: [
    {
      path: route.home,
      props: {
        route,
      },
      name: "Home",
      component: Home,
      beforeEnter: (to, from, next) => {
        if (!isAuthenticated()) {
          next(route.login);
        } else next();
      },
      // Nav Menu
      children: menu,
    },
    {
      path: route.login,
      name: "Login",
      component: Login,
      beforeEnter: (to, from, next) => {
        if (!isAuthenticated()) {
          next();
        } else {
          next(route.home);
        }
      },
    },
    {
      path: route.portal_login,
      name: "portal_login",
      component: PortalLogin,
    },
    {
      path: route.notFound,
      name: "NotFound",
      component: PageNotFound,
    },
  ],
});

routers.beforeEach((to, from, next) => {
  const user = getCurrentUser();

  if (user) {
    const {
      data_token: { role },
    } = user;

    const { path } = to;

    const notAccess = path === "/role";

    if (notAccess && role !== ROLES.SUPER_ADMIN && isAuthenticated()) {
      return next({ path: "/" });
    }
  }

  next();
});

export default routers;
