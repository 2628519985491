<template>
  <a-spin tip="Loading..." :spinning="fetching">
    <div class="base">
      <Table
        title="Events"
        :pagination="pagination"
        :data="events"
        :columns="columns"
        :scrollX="scrollX"
        :scrollY="700"
        :isDelete="false"
        :isApprove="false"
        :isUpdate="true"
        :isAdd="true"
        :handleApprove="handleApprove"
        :handleUpdateVisible="handleUpdateVisible"
        :handleAddVisible="handleAddVisible"
        :handleSettingColunmVisible="handleSettingColunmVisible"
        :isShowError="error !== null"
        :errorMessage="error"
      />
      <UpdateEvent
        v-model="updateModalVisible"
        :handleCancel="handleUpdateCancel"
        :showModal="handleUpdateVisible"
        :item="setItem"
      />
      <AddEvent
        v-model="addModalVisible"
        :handleCancel="handleAddModalCancel"
        :showModal="handleAddVisible"
      />
      <SettingColumn
        v-model="settingColumnModalVisible"
        :handleCancel="handleSettingColunmCancel"
        :showModal="handleSettingColunmVisible"
      />
    </div>
  </a-spin>
</template>

<script>
/* eslint-disable no-console */

import { mapState } from "vuex";
import Table from "../components/Table";
import UpdateEvent from "../components/Event/UpdateEvent";
import AddEvent from "../components/Event/AddEvent";
import SettingColumn from "../components/Event/SettingColumn";

export default {
  name: "event",
  components: {
    Table,
    UpdateEvent,
    AddEvent,
    SettingColumn,
  },
  computed: {
    ...mapState({
      events: (state) => state.event.data,
      fetching: (state) => state.event.fetching,
      allColumns: (state) => state.event.allLogsColumns,
      columns: (state) => state.event.eventColumns,
      error: (state) => state.event.error,
    }),

    scrollX: function() {
      return this.columns.length * 150 + 200;
    },
    setItem: function() {
      return this.item;
    },
  },
  data() {
    return {
      item: {},
      updateModalVisible: false,
      addModalVisible: false,
      settingColumnModalVisible: false,
      pagination: { pageSize: 10 },
    };
  },
  methods: {
    handleUpdateVisible(item) {
      this.updateModalVisible = true;
      this.item = item;
    },
    handleUpdateCancel() {
      this.updateModalVisible = false;
      this.item = {};
    },
    handleAddVisible() {
      this.addModalVisible = true;
    },
    handleAddModalCancel() {
      this.addModalVisible = false;
    },
    handleApprove() {},
    handleSettingColunmVisible() {
      this.settingColumnModalVisible = true;
    },
    handleSettingColunmCancel() {
      this.settingColumnModalVisible = false;
    },
  },
};
</script>
