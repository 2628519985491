<template>
  <a-spin tip="Loading..." :spinning="fetching">
    <div>
      <a-form
        class="ant-advanced-search-form"
        :form="form"
        @submit="handleFilter"
      >
        <a-row :gutter="16">
          <a-col :span="6">
            <a-form-item label="Username">
              <a-input v-decorator="['username']" placeholder="Username" />
            </a-form-item>
          </a-col>
          <a-col :span="6">
            <a-form-item label="Email">
              <a-input v-decorator="['email']" placeholder="Email" />
            </a-form-item>
          </a-col>
          <a-col :span="6">
            <a-form-item label="Team">
              <a-input v-decorator="['team']" placeholder="Team" />
            </a-form-item>
          </a-col>
          <a-col :span="6">
            <a-form-item label="Status">
              <a-input v-decorator="['status']" placeholder="Status" />
            </a-form-item>
          </a-col>
        </a-row>

        <a-row>
          <a-col :span="24" :style="{ textAlign: 'right' }">
            <a-button type="primary" html-type="submit">Search</a-button>
            <a-button :style="{ marginLeft: '8px' }" @click="handleReset"
              >Clear</a-button
            >
          </a-col>
        </a-row>
      </a-form>

      <Table
        style="padding:0 25px 0 25px"
        title="Danh sách users"
        :pagination="pagination"
        :data="users"
        :columns="columns"
        :scrollX="scrollX"
        :isDelete="false"
        :isUpdate="true"
        :isAdd="true"
        :isResetPassword="true"
        :handleUpdateVisible="handleUpdateVisible"
        :handleAddVisible="handleAddVisible"
        :handleResetPassword="handleResetPassword"
        :isShowError="error !== null"
        :errorMessage="error"
        :handleSettingColunmVisible="handleSettingColunmVisible"
      />

      <UpdateUser
        v-model="updateModalVisible"
        :handleCancel="handleUpdateCancel"
        :showModal="handleUpdateVisible"
        :item="setItem"
      />

      <AddUser
        v-model="addModalVisible"
        :handleCancel="handleAddModalCancel"
        :showModal="handleAddVisible"
      />

      <SettingColumn
        v-model="settingColumnModalVisible"
        :handleCancel="handleSettingColunmCancel"
        :showModal="handleSettingColunmVisible"
      />
    </div>
  </a-spin>
</template>

<script>
/* eslint-disable no-console */

import { mapState } from "vuex";
import Table from "../components/Table";
import UpdateUser from "../components/User/UpdateUser";
import AddUser from "../components/User/AddUser";
import _ from "lodash";
import SettingColumn from "../components/User/SettingColumn";
import user from "../api/user";
import { code } from "../api";

export default {
  name: "user",
  components: {
    Table,
    UpdateUser,
    AddUser,
    SettingColumn,
  },
  created() {
    this.$store.dispatch("user/getAllUsers", {});
  },
  computed: {
    ...mapState({
      users: (state) => state.user.data,
      fetching: (state) => state.user.fetching,
      columns: (state) => state.user.columns,
      error: (state) => state.user.error,
    }),
    scrollX: function() {
      return this.columns.length * 150 + 50;
    },
    setItem: function() {
      return this.item;
    },
  },
  data() {
    return {
      form: this.$form.createForm(this, { name: "search_form" }),
      item: {},
      accessControlVisible: false,
      updateModalVisible: false,
      addModalVisible: false,
      settingColumnModalVisible: false,
      pagination: { pageSize: 10 },
    };
  },
  methods: {
    handleUpdateVisible(item) {
      this.updateModalVisible = true;
      this.item = item;
    },
    handleUpdateCancel() {
      this.updateModalVisible = false;
    },

    handleAddVisible() {
      this.addModalVisible = true;
    },
    handleAddModalCancel() {
      this.addModalVisible = false;
    },

    handleResetPassword(item) {
      const { $message } = this;
      this.$confirm({
        title: "Do you want to reset password?",
        onOk() {
          let data = {
            id: item.id,
            created_by: item.created_by,
            password: "123456",
          };
          user.updateUser(data).then((response) => {
            if (response.code === code.success) {
              $message.success(`Reset password successfully`);
            } else {
              $message.error(`Reset password error: ${response.message}`);
            }
          });
        },
        onCancel() {
          // console.log("Cancel");
        },
      });
    },
    handleFilter(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          values = _.omit(
            values,
            _.filter(_.keys(values), function(key) {
              return _.isUndefined(values[key]) || values[key] === "";
            })
          );
          this.$store.dispatch("user/getAllUsers", values);
        }
      });
    },
    handleReset() {
      this.form.resetFields();
    },
    handleSettingColunmVisible() {
      this.settingColumnModalVisible = true;
    },
    handleSettingColunmCancel() {
      this.settingColumnModalVisible = false;
    },
  },
};
</script>
