/* eslint-disable no-console */
import tickets from '../../api/ticket'
import { renderTableColumns } from '../../utils/help'

// initial state
const state = {
  data: [],
  fetching: false,
  total: 0,
  allColumns: [],
  columns: []
}

// getters
const getters = {}

// actions
const actions = {
  getAllTickets ({ commit }, params) {
    commit('ticketsFetching')
    tickets.getTickets(
      params,
      ticket => {
        commit('setTickets', ticket.data);     
    })
  },
  resetTicket({commit}) {
    commit('resetTicket')
  },
}

// mutations
const mutations = {
  ticketsFetching (state) {
    state.data = [],
    state.fetching = true,
    state.total = 0
  },
  setTickets (state, tickets) {
    state.allColumns = renderTableColumns(tickets.logs)
    state.columns = state.columns.length > 0 ? state.columns : state.allColumns
    tickets.logs.map((b) => b['key'] = b.id) //set key to render table
    state.data = tickets.logs,
    state.fetching = false,
    state.total = tickets.total
  },
  resetTicket (state) {
    state.data = [],
    state.allColumns = []
    state.columns = []
  },
  setTicketColumns(state, columns) {
    state.columns = columns
  }
   

}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
