/* eslint-disable no-console */
import _ from "lodash";

export const statusArray = [
  {
    text: "Active",
    value: "active",
  },
  {
    text: "Deactive",
    value: "deactive",
  },
  {
    text: "Waiting Approve",
    value: "waiting_approve",
  },
];

export const registerStatusArray = [
  {
    value: 0,
    text: "Hide",
    message: "",
  },
  {
    value: 1,
    text: "Can Register",
    message: "Đăng ký",
  },
  {
    value: 2,
    text: "Registered",
    message: "Đã đăng ký",
  },
  {
    value: 3,
    text: "Expired",
    message: "Hết hạn đăng ký",
  },
];
export const STATUS = {
  ACTIVE: {
    TEXT: "Active",
    VALUE: "active",
  },
  DEACTIVE: {
    TEXT: "Deactive",
    VALUE: "deactive",
  },
  WAITING_APPROVE: {
    TEXT: "Waiting Approve",
    VALUE: "waiting_approve",
  },
};

export const EVENT_TYPE = {
  PUBLIC: {
    title: "Public",
    type: 0,
  },
  PROTECTED: {
    title: "Protected",
    type: 1,
  },
  PRIVATE: {
    title: "Private",
    type: 2,
  },
};

export const dateColumns = [
  "start",
  "end",
  "updated_time",
  "created_time",
  "checkin_time",
  "checkout_time",
];
export const boolColumns = ["slave_encode", "slave_create_implicit"];

export const sizeM = [
  "ticket_new_offset",
  "voucher_new_offset",
  "fullname",
  "code",
  "cus_fullname",
];
export const sizeL = [
  "ticket_number_digit_incr",
  "voucher_number_digit_incr",
  "id",
];
export const dateFormat = "DD/MM/YYYY  HH:mm:ss";

export function upperCaseFirstLetter(string) {
  let upper = string.charAt(0).toUpperCase() + string.slice(1);
  upper = upper.replace(/_/g, " ");
  return upper;
}
export function hidePhone(phone) {
  return phone && "******" + phone.slice(-4);
}

export function convertDate(date) {
  if (date === undefined) return "";
  date = new Date(date);

  let hour = date.getHours().toString();
  if (hour.length === 1) hour = `0${hour}`;
  let minutes = date.getMinutes().toString();
  if (minutes.length === 1) minutes = `0${minutes}`;
  return `${date.getDate()}/${date.getMonth() +
    1}/${date.getFullYear()} ${hour}:${minutes} `;
}

export function convertDayToMillisecond(day) {
  if (day === undefined || day === "") return "";
  return day * (24 * 60 * 60 * 1000);
}
export function convertMillisecondToDay(millisecond) {
  if (millisecond === undefined || millisecond === "" || millisecond === null)
    return "";
  return millisecond / (24 * 60 * 60 * 1000);
}
export function sortString(a, b) {
  return a.localeCompare(b);
}

export function sortDate(a, b) {
  return a - b;
}

export function renderTitle(val) {
  let title = val.charAt(0).toUpperCase() + val.slice(1);
  title = title.replace(/_/g, " ");
  return title;
}

export function removeUndefined(values) {
  return _.omit(
    values,
    _.filter(_.keys(values), function(key) {
      return _.isUndefined(values[key]) || values[key] === "";
    })
  );
}

export function randomString(length) {
  var result           = '';
  var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
  var charactersLength = characters.length;
  for ( var i = 0; i < length; i++ ) {
     result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

export function getIndexHasMaxKeys(array) {
  let maxIndex = 0;
  let max = Object.keys(array[0]).length;

  for (let index = 1; index < array.length; index++) {
    const element = array[index];
    const count = Object.keys(element).length;
    if (count > max) maxIndex = index;
  }

  return maxIndex;
}

export function renderTableColumns(data) {
  if (data === undefined || data.length === 0) return [];
  const columns = []; // array columns of table
  const index = getIndexHasMaxKeys(data); // get the most key object in array
  let names = Object.keys(data[index]); // convert object to array dataIndex

  names = names.filter(
    (element) => element.search("_") !== 0 && element !== "password"
  ); //filter dataIndex use

  // swap name columns to top
  const nameColumnIndex = names.indexOf("name");

  if (nameColumnIndex !== -1) {
    var b = names[nameColumnIndex];
    names[nameColumnIndex] = names[0];
    names[0] = b;
  }

  // swap fullname columns to top
  // const fullNameColumnIndex = names.indexOf('fullname')

  // if(fullNameColumnIndex !== -1){
  //   var t = names[fullNameColumnIndex];
  //   names[fullNameColumnIndex] = names[0];
  //   names[0] = t;
  // }

  // swap ticket code columns to top

  const codeColumnIndex = names.indexOf("code");

  if (codeColumnIndex !== -1) {
    var t = names[codeColumnIndex];
    names[codeColumnIndex] = names[0];
    names[0] = t;
  }

  names.forEach((val) => {
    const base = {
      title: renderTitle(val),
      dataIndex: val,
      key: val,
      width: 150,
      align: "center",
      ellipsis: true,
    };
    if (dateColumns.indexOf(val) !== -1) {
      // check columns convert to date string
      if (val === "updated_time") {
        columns.push(
          Object.assign(base, {
            sorter: (a, b) => sortDate(a.updated_time, b.updated_time),
            customRender: (date) => convertDate(date),
          })
        );
      } else
        columns.push(
          Object.assign(base, {
            customRender: (date) => convertDate(date),
          })
        );
    } else if (val === "name") {
      base.width = 300;
      columns.push(
        Object.assign(base, {
          sorter: (a, b) => sortString(a.name, b.name),
        })
      );
    } else if (val === "scope") {
      base.width = 300;
      columns.push(
        Object.assign(base, {
          customRender: (scope) =>
            Array.isArray(scope) ? scope.join("\n") : "",
        })
      );
    } else if (val === "actions") {
      columns.push(
        Object.assign(base, {
          customRender: (actions) =>
            Array.isArray(actions) ? actions.join(",") : actions,
        })
      );
    } else if (val === "voucher_code") {
      columns.push(
        Object.assign(base, {
          customRender: (voucher_code) => {
            return Array.isArray(voucher_code) ? voucher_code.join("\n") : "";
          },
        })
      );
    } else if (boolColumns.indexOf(val) !== -1) {
      columns.push(
        Object.assign(base, {
          customRender: (data) => JSON.stringify(data),
        })
      );
    } else {
      // columns doesn't convert to date string
      if (sizeL.indexOf(val) !== -1) {
        base.width = 300;
      } else if (sizeM.indexOf(val) !== -1) {
        base.width = 200;
      }
      columns.push(base);
    }
  });

  columns.push({
    // add action columns
    title: "Action",
    key: "operation",
    align: "center",
    width: 120,
    fixed: "right",
    scopedSlots: {
      customRender: "action",
    },
  });

  return columns;
}
