var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"updateEvent"},[_c('a-modal',{attrs:{"width":700,"visible":_vm.visible,"title":"Update Event","onOk":"handleSubmit","closable":false,"maskClosable":false},on:{"change":_vm.onChangeModal}},[_c('template',{slot:"footer"},[_c('a-button',{key:"back",on:{"click":_vm.onCancel}},[_vm._v("Cancel")]),_c('a-button',{key:"submit",attrs:{"type":"primary","loading":_vm.loading},on:{"click":_vm.handleSubmit}},[_vm._v("Update")])],1),_c('a-form',{attrs:{"form":_vm.form},on:{"submit":_vm.handleSubmit}},[_c('a-row',{attrs:{"gutter":16}},[_c('a-col',{attrs:{"span":8}},[_c('a-form-item',{attrs:{"label":"Tên Event"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'name',
                {
                  rules: [
                    { required: true, message: 'Please input event name!' } ],
                  initialValue: _vm.item.name,
                } ]),expression:"[\n                'name',\n                {\n                  rules: [\n                    { required: true, message: 'Please input event name!' },\n                  ],\n                  initialValue: item.name,\n                },\n              ]"}],attrs:{"placeholder":"Input event name"}})],1)],1),_c('a-col',{attrs:{"span":8}},[_c('a-form-item',{attrs:{"label":"Code"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'code',
                {
                  initialValue: _vm.item.code,
                  rules: [{ required: true, message: 'Please input code!' }],
                } ]),expression:"[\n                'code',\n                {\n                  initialValue: item.code,\n                  rules: [{ required: true, message: 'Please input code!' }],\n                },\n              ]"}],attrs:{"placeholder":"Input code"}})],1)],1),_c('a-col',{attrs:{"span":8}},[_c('a-form-item',{attrs:{"label":"Event type"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'type',
                {
                  initialValue: _vm.item.type,
                  rules: [
                    { required: true, message: 'Please select event type!' } ],
                } ]),expression:"[\n                'type',\n                {\n                  initialValue: item.type,\n                  rules: [\n                    { required: true, message: 'Please select event type!' },\n                  ],\n                },\n              ]"}],attrs:{"placeholder":"Select event type"}},_vm._l((_vm.EVENT_TYPE),function(val){return _c('a-select-option',{key:val.type,attrs:{"value":val.type}},[_vm._v(_vm._s(val.title))])}),1)],1)],1)],1),_c('a-row',{attrs:{"gutter":16}},[_c('a-col',{attrs:{"span":8}},[_c('a-form-item',{attrs:{"label":"Campaign code"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'campaign_code',
                {
                  rules: [
                    {
                      required: true,
                      message: 'Please select campaign code!',
                    } ],
                  initialValue: _vm.item.campaign_code,
                } ]),expression:"[\n                'campaign_code',\n                {\n                  rules: [\n                    {\n                      required: true,\n                      message: 'Please select campaign code!',\n                    },\n                  ],\n                  initialValue: item.campaign_code,\n                },\n              ]"}],attrs:{"showSearch":"","optionFilterProp":"children","filterOption":_vm.filterOption,"placeholder":"Select campaign code"}},_vm._l((_vm.campaigns),function(val){return _c('a-select-option',{key:val.id,attrs:{"value":val.name}},[_vm._v(_vm._s(val.id)+" - "+_vm._s(val.name))])}),1)],1)],1),_c('a-col',{attrs:{"span":8}},[_c('a-form-item',{attrs:{"label":"Department"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'department',
                {
                  rules: [
                    { required: true, message: 'Please input department!' } ],
                  initialValue: _vm.item.department,
                } ]),expression:"[\n                'department',\n                {\n                  rules: [\n                    { required: true, message: 'Please input department!' },\n                  ],\n                  initialValue: item.department,\n                },\n              ]"}],attrs:{"placeholder":"Input department"}})],1)],1),_c('a-col',{attrs:{"span":8}},[_c('a-form-item',{attrs:{"label":"Team"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'team',
                {
                  initialValue: _vm.item.team,
                  rules: [{ required: true, message: 'Please input team!' }],
                } ]),expression:"[\n                'team',\n                {\n                  initialValue: item.team,\n                  rules: [{ required: true, message: 'Please input team!' }],\n                },\n              ]"}],attrs:{"placeholder":"Input team"}})],1)],1)],1),_c('a-row',{attrs:{"gutter":16}},[_c('a-col',{attrs:{"span":8}},[_c('a-form-item',{attrs:{"label":"Ticket Prefix"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'ticket_prefix',
                {
                  initialValue: _vm.item.ticket_prefix,
                  rules: [
                    {
                      required: true,
                      message: 'Please input ticket prefix!',
                    } ],
                } ]),expression:"[\n                'ticket_prefix',\n                {\n                  initialValue: item.ticket_prefix,\n                  rules: [\n                    {\n                      required: true,\n                      message: 'Please input ticket prefix!',\n                    },\n                  ],\n                },\n              ]"}],attrs:{"placeholder":"Input ticket prefix"}})],1)],1),_c('a-col',{attrs:{"span":8}},[_c('a-form-item',{attrs:{"label":"Ticket Number Digit Incr"}},[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'ticket_number_digit_incr',
                {
                  initialValue: _vm.item.ticket_number_digit_incr,
                  rules: [
                    {
                      required: true,
                      message: 'Please input ticket number digit incr!',
                    } ],
                } ]),expression:"[\n                'ticket_number_digit_incr',\n                {\n                  initialValue: item.ticket_number_digit_incr,\n                  rules: [\n                    {\n                      required: true,\n                      message: 'Please input ticket number digit incr!',\n                    },\n                  ],\n                },\n              ]"}],staticStyle:{"width":"100%"},attrs:{"placeholder":"Input ticket number digit incr","min":1}})],1)],1),_c('a-col',{attrs:{"span":8}},[_c('a-form-item',{attrs:{"label":"Ticket New Offset"}},[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'ticket_new_offset',
                {
                  initialValue: _vm.item.ticket_new_offset,
                  rules: [
                    {
                      required: true,
                      message: 'Please input ticket new offset!',
                    } ],
                } ]),expression:"[\n                'ticket_new_offset',\n                {\n                  initialValue: item.ticket_new_offset,\n                  rules: [\n                    {\n                      required: true,\n                      message: 'Please input ticket new offset!',\n                    },\n                  ],\n                },\n              ]"}],staticStyle:{"width":"100%"},attrs:{"placeholder":"Input ticket new offset"}})],1)],1)],1),_c('a-row',{attrs:{"gutter":16}},[_c('a-col',{attrs:{"span":8}},[_c('a-form-item',{attrs:{"label":"Start"}},[_c('a-date-picker',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'start',
                {
                  initialValue: _vm.item.start
                    ? _vm.moment(new Date(_vm.item.start), _vm.dateFormat)
                    : null,
                  rules: [
                    {
                      type: 'object',
                      required: true,
                      message: 'Please input start date!',
                    } ],
                } ]),expression:"[\n                'start',\n                {\n                  initialValue: item.start\n                    ? moment(new Date(item.start), dateFormat)\n                    : null,\n                  rules: [\n                    {\n                      type: 'object',\n                      required: true,\n                      message: 'Please input start date!',\n                    },\n                  ],\n                },\n              ]"}],staticStyle:{"width":"100%"},attrs:{"showTime":true,"format":_vm.dateFormat,"placeholder":"Select start date"}})],1)],1),_c('a-col',{attrs:{"span":8}},[_c('a-form-item',{attrs:{"label":"End"}},[_c('a-date-picker',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'end',
                {
                  initialValue: _vm.item.end
                    ? _vm.moment(new Date(_vm.item.end), _vm.dateFormat)
                    : null,
                  rules: [
                    {
                      type: 'object',
                      required: true,
                      message: 'Please input end date!',
                    } ],
                } ]),expression:"[\n                'end',\n                {\n                  initialValue: item.end\n                    ? moment(new Date(item.end), dateFormat)\n                    : null,\n                  rules: [\n                    {\n                      type: 'object',\n                      required: true,\n                      message: 'Please input end date!',\n                    },\n                  ],\n                },\n              ]"}],staticStyle:{"width":"100%"},attrs:{"format":_vm.dateFormat,"placeholder":"Select end date","showTime":true}})],1)],1),_c('a-col',{attrs:{"span":8}},[_c('a-form-item',{attrs:{"label":"Status"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'status',
                {
                  rules: [
                    { required: true, message: 'Please select status!' } ],
                  initialValue: _vm.item.status,
                } ]),expression:"[\n                'status',\n                {\n                  rules: [\n                    { required: true, message: 'Please select status!' },\n                  ],\n                  initialValue: item.status,\n                },\n              ]"}],attrs:{"placeholder":"Select status"}},_vm._l((_vm.statusArray),function(status){return _c('a-select-option',{key:status.value,attrs:{"value":status.value}},[_vm._v(_vm._s(status.text))])}),1)],1)],1)],1),_c('a-row',{attrs:{"gutter":16}},[_c('a-col',{attrs:{"span":8}},[_c('a-form-item',{attrs:{"label":"Button action"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'button_action',
                {
                  rules: [
                    {
                      required: true,
                      message: 'Please select button action!',
                    } ],
                  initialValue: _vm.item.button_action,
                } ]),expression:"[\n                'button_action',\n                {\n                  rules: [\n                    {\n                      required: true,\n                      message: 'Please select button action!',\n                    },\n                  ],\n                  initialValue: item.button_action,\n                },\n              ]"}],attrs:{"placeholder":"Select button_action"}},_vm._l((_vm.registerStatusArray),function(status){return _c('a-select-option',{key:status.value,attrs:{"value":status.value}},[_vm._v(_vm._s(status.text))])}),1)],1)],1),_c('a-col',{attrs:{"span":8}},[_c('a-form-item',{attrs:{"label":"End Register"}},[_c('a-date-picker',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'end_register_date',
                {
                  initialValue:
                    _vm.item.end_register_date == null
                      ? _vm.moment(new Date())
                      : _vm.moment(new Date(_vm.item.end_register_date), _vm.dateFormat),
                  rules: [
                    {
                      type: 'object',
                      required: true,
                      message: 'Please input end date!',
                    } ],
                } ]),expression:"[\n                'end_register_date',\n                {\n                  initialValue:\n                    item.end_register_date == null\n                      ? moment(new Date())\n                      : moment(new Date(item.end_register_date), dateFormat),\n                  rules: [\n                    {\n                      type: 'object',\n                      required: true,\n                      message: 'Please input end date!',\n                    },\n                  ],\n                },\n              ]"}],staticStyle:{"width":"100%"},attrs:{"format":_vm.dateFormat,"placeholder":"Select end date","showTime":true}})],1)],1)],1),_c('a-row',{attrs:{"gutter":16}},[_c('a-col',{attrs:{"span":8}},[_c('a-form-item',{attrs:{"label":"Location"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'location',
                {
                  initialValue: _vm.item.location,
                } ]),expression:"[\n                'location',\n                {\n                  initialValue: item.location,\n                },\n              ]"}],attrs:{"placeholder":"Input location"}})],1)],1),_c('a-col',{attrs:{"span":16}},[_c('a-form-item',[_c('label',[_vm._v(" Actions ")]),_c('Tag',{attrs:{"data":_vm.getTags,"handleGetTags":_vm.handleGetActionTags}})],1)],1)],1),_c('a-row',{attrs:{"gutter":16}},[_c('a-col',{attrs:{"span":8}},[_c('a-form-item',[_c('a-checkbox',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'display_public',
                {
                  valuePropName: 'checked',
                  initialValue: _vm.item.display_public,
                } ]),expression:"[\n                'display_public',\n                {\n                  valuePropName: 'checked',\n                  initialValue: item.display_public,\n                },\n              ]"}]},[_vm._v(" Display public ")])],1)],1),_c('a-col',{attrs:{"span":8}},[_c('a-form-item',[_c('a-checkbox',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'display_internal',
                {
                  valuePropName: 'checked',
                  initialValue: _vm.item.display_internal,
                } ]),expression:"[\n                'display_internal',\n                {\n                  valuePropName: 'checked',\n                  initialValue: item.display_internal,\n                },\n              ]"}]},[_vm._v(" Display internal ")])],1)],1)],1),_c('label',[_vm._v(" Image ")]),_c('UploadMultipleImage',{attrs:{"urls":_vm.urls,"handleGetUrls":_vm.handleGetUrls}}),_c('br'),_c('label',[_vm._v(" Content ")]),_c('ckeditor',{attrs:{"config":_vm.editorConfig},model:{value:(_vm.content),callback:function ($$v) {_vm.content=$$v},expression:"content"}})],1)],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }