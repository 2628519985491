<script>
/* eslint-disable no-console */

import { logout, getCurrentUser, ROLES } from "../utils/authentication";
import { route } from "../router";
import ChangePassword from "../components/ChangePassword";
import nd_small_logo from "../assets/images/small-logo.png";
import nd_large_logo from "../assets/images/large-logo.png";
import zema_large_logo from "../assets/images/zema-logo.png";

const ticket_sub_text = "ticket_sub";
const voucher_sub_text = "voucher_sub";
const event_sub_text = "event_sub";
const user_sub_text = "user_sub";

export default {
  components: {
    ChangePassword,
  },
  data() {
    const { route } = this.$props;
    return {
      collapsed: false,
      user: getCurrentUser(),
      currentRoutePath: this.$router.currentRoute.path,
      openKeys: [],
      rootSubmenuKeys: [
        ticket_sub_text,
        voucher_sub_text,
        event_sub_text,
        user_sub_text,
      ], //array of root submenu
      ticket_sub: [route.upload_ticket, route.ticket], // array ticket submenu
      voucher_sub: [route.upload_voucher, route.voucher_master, route.voucher], // array voucher submenu
      event_sub: [route.event_action_logs, route.event], // array event submenu
      user_sub: [route.user, route.role, route.rule], // array event submenu
      // data to render UI
      ticket_sub_text,
      voucher_sub_text,
      event_sub_text,
      user_sub_text,
      ROLES,
      changePasswordVisible: false,
      nd_small_logo,
      nd_large_logo,
      zema_large_logo,
      // zema_small_logo,
    };
  },
  created() {
    this.handleOpenKeys();
  },
  beforeRouteUpdate(to, from, next) {
    this.currentRoutePath = to.path;
    //open key when user open not access page => page direct
    // this.openKeys = to.path === route.home ? [event_sub_text] : this.openKeys
    next();
  },
  props: {
    route: Object,
  },
  methods: {
    handleChangePasswordVisible() {
      this.changePasswordVisible = true;
    },
    handleChangePasswordInvisible() {
      this.changePasswordVisible = false;
    },
    handleUserActionMenuClick(e) {
      var router = this.$router;
      if (e.key === "1") {
        //
        this.changePasswordVisible = true;
      }
      if (e.key === "3") {
        this.$confirm({
          title: "Logout",
          content: "Do you want to logout ?",
          onOk() {
            return new Promise((resolve) => {
              setTimeout(resolve, 1000);
            })
              .then(() => {
                logout();
                router.replace(route.login);
              })
              .catch((e) => console.log(e));
          },
          onCancel() {},
        });
      }
    },
    onOpenChange(openKeys) {
      this.openKeys = openKeys;
    },
    handleMenuClick(e) {
      const sub = e.keyPath[e.keyPath.length - 1];
      if (sub === ticket_sub_text) {
        this.openKeys = [ticket_sub_text];
      } else if (sub === voucher_sub_text) {
        this.openKeys = [voucher_sub_text];
      } else if (sub === event_sub_text) {
        this.openKeys = [event_sub_text];
      } else if (sub === user_sub_text) {
        this.openKeys = [user_sub_text];
      } else {
        this.openKeys = [];
      }
    },
    handleOpenKeys() {
      if (this.ticket_sub.indexOf(this.currentRoutePath) !== -1) {
        this.openKeys = [ticket_sub_text];
      } else if (this.voucher_sub.indexOf(this.currentRoutePath) !== -1) {
        this.openKeys = [voucher_sub_text];
      } else if (this.event_sub.indexOf(this.currentRoutePath) !== -1) {
        this.openKeys = [event_sub_text];
      } else if (this.user_sub.indexOf(this.currentRoutePath) !== -1) {
        this.openKeys = [user_sub_text];
      }
    },
  },
};
</script>
<template>
  <a-layout id="admin-layout">
    <a-layout-sider :trigger="null" collapsible v-model="collapsed">
      <div id="logo" v-if="user.data_token.product === 'ngocdung'">
        <img :src="nd_small_logo" alt="error" :height="50" v-if="collapsed" />
        <img :src="nd_large_logo" alt="error" :height="50" v-else />
      </div>
      <div id="logo" v-else>
        <img
          src="https://zema.com.vn/wp-content/uploads/2018/12/cropped-logo-thien-nga-02-1-32x32.png"
          alt="error"
          v-if="collapsed"
          style="padding-bottom: 17px"
        />
        <img :src="zema_large_logo" alt="error" :height="50" v-else />
      </div>
      <a-menu
        theme="dark"
        mode="inline"
        :selectedKeys="[this.currentRoutePath]"
        :openKeys="openKeys"
        @openChange="onOpenChange"
        @click="handleMenuClick"
      >
        <!-- HOME -->
        <a-menu-item :key="route.home">
          <router-link :to="route.home">
            <a-icon type="home" />
            <span>Home</span>
          </router-link>
        </a-menu-item>

        <!-- EVENT -->
        <a-sub-menu :key="event_sub_text">
          <span slot="title">
            <a-icon type="experiment" />
            <span>Event</span>
          </span>
          <a-menu-item :key="route.event">
            <router-link :to="route.event">
              <a-icon type="book" />
              <span>Event List</span>
            </router-link>
          </a-menu-item>
          <a-menu-item :key="this.route.event_action_logs">
            <router-link :to="this.route.event_action_logs">
              <a-icon type="solution" />
              <span>Event Action Logs</span>
            </router-link>
          </a-menu-item>
        </a-sub-menu>

        <!-- CAMPAIGN -->
        <a-menu-item :key="route.campaign">
          <router-link :to="route.campaign">
            <a-icon type="tag" />
            <span>Campaign</span>
          </router-link>
        </a-menu-item>

        <!-- VOUCHER -->
        <a-sub-menu :key="voucher_sub_text">
          <span slot="title">
            <a-icon type="crown" />
            <span>Voucher</span>
          </span>

          <a-menu-item :key="route.voucher_master">
            <router-link :to="route.voucher_master">
              <a-icon type="book" />
              <span>Voucher Master</span>
            </router-link>
          </a-menu-item>

          <a-menu-item :key="route.voucher">
            <router-link :to="route.voucher">
              <a-icon type="book" />
              <span>Vouchers</span>
            </router-link>
          </a-menu-item>
          <a-menu-item :key="this.route.upload_voucher">
            <router-link :to="this.route.upload_voucher">
              <a-icon type="upload" />
              <span>Upload Voucher</span>
            </router-link>
          </a-menu-item>
        </a-sub-menu>

        <!-- TICKET -->

        <a-sub-menu :key="ticket_sub_text">
          <span slot="title">
            <a-icon type="fire" />
            <span>Ticket</span>
          </span>

          <a-menu-item :key="route.ticket">
            <router-link :to="route.ticket">
              <a-icon type="book" />
              <span>Ticket List</span>
            </router-link>
          </a-menu-item>

          <a-menu-item :key="this.route.upload_ticket">
            <router-link :to="this.route.upload_ticket">
              <a-icon type="upload" />
              <span>Upload Ticket</span>
            </router-link>
          </a-menu-item>
        </a-sub-menu>

        <!-- USER -->
        <a-sub-menu :key="user_sub_text">
          <span slot="title">
            <a-icon type="fire" />
            <span>User & Role</span>
          </span>

          <a-menu-item :key="route.user">
            <router-link :to="route.user">
              <a-icon type="user" />
              <span>User</span>
            </router-link>
          </a-menu-item>

          <a-menu-item
            :key="route.role"
            v-show="user.data_token.role === ROLES.SUPER_ADMIN"
          >
            <router-link :to="route.role">
              <a-icon type="star" />
              <span>Role</span>
            </router-link>
          </a-menu-item>
          <a-menu-item
            :key="route.rule"
            v-show="user.data_token.role === ROLES.SUPER_ADMIN"
          >
            <router-link :to="route.rule">
              <a-icon type="star" />
              <span>Rule</span>
            </router-link>
          </a-menu-item>
        </a-sub-menu>

        <!-- REPORT -->
        <a-menu-item :key="route.report">
          <router-link :to="route.report">
            <a-icon type="line-chart" />
            <span>Report</span>
          </router-link>
        </a-menu-item>
      </a-menu>
    </a-layout-sider>
    <a-layout>
      <a-layout-header
        style="background: #fff; padding: 0; border-bottom: 2px solid #391085"
      >
        <a-row>
          <!-- collapsed icon-->
          <a-col span="12">
            <a-icon
              class="trigger"
              :type="collapsed ? 'menu-unfold' : 'menu-fold'"
              @click="
                () => {
                  collapsed = !collapsed;
                  if (collapsed) openKeys = [];
                  else {
                    this.handleOpenKeys();
                  }
                }
              "
            />
          </a-col>
          <!-- end collapsed icon-->

          <!-- User Action -->
          <a-col
            span="3"
            offset="9"
            :style="{ textAlign: 'right', paddingRight: '16px' }"
          >
            <a-dropdown>
              <a class="ant-dropdown-link" href="#">
                <a-row justify="end">
                  <a-avatar icon="user" />
                  <span className="ant-menu-submenu-title">
                    Hi, <b>{{ user.data_token.username }} </b>
                  </span>
                </a-row>
              </a>

              <a-menu slot="overlay" @click="handleUserActionMenuClick">
                <a-menu-item key="1"
                  ><a-icon type="key" />Đổi mật khẩu</a-menu-item
                >
                <a-menu-item key="2"
                  ><a-icon type="user" />Thông tin tài khoản</a-menu-item
                >
                <a-menu-divider />
                <a-menu-item key="3"
                  ><a-icon type="logout" />Đăng xuất</a-menu-item
                >
              </a-menu>
            </a-dropdown>
          </a-col>
          <!-- End User Action -->
          <ChangePassword
            v-model="changePasswordVisible"
            :handleCancel="handleChangePasswordInvisible"
            :showModal="handleChangePasswordVisible"
            :user="user"
          />
        </a-row>
      </a-layout-header>
      <a-layout-content id="admin-layout-content">
        <div v-if="this.currentRoutePath === route.home">
          Home
        </div>
        <router-view v-else />
      </a-layout-content>
    </a-layout>
  </a-layout>
</template>

<style>
#admin-layout {
  min-height: 100vh;
}
#admin-layout .trigger {
  font-size: 18px;
  line-height: 64px;
  padding: 0 24px;
  cursor: pointer;
  transition: color 0.3s;
}

#admin-layout .trigger:hover {
  color: #1890ff;
}

#logo {
  margin: 10px 0 0 0;
  text-align: center;
}
#admin-layout-content {
  margin: 24px;
  background: #fff;
  min-height: 280px;
}
</style>
