/* eslint-disable no-console */
let ENV_API_URL = "https://imapidev.ngocdunggroup.com.vn";
let ENV_PORTAL_WEB_URL = "https://dev.trangnha.com";

const environment = process.env.VUE_APP_ENV;

if (environment === "production") {
  ENV_API_URL = "https://imapi.ngocdunggroup.com.vn";
  ENV_PORTAL_WEB_URL = "https://trangnha.com";
}

export let API_URL = ENV_API_URL;
export let PORTAL_WEB_URL = ENV_PORTAL_WEB_URL;

export const UPLOAD_IMAGE_URL =
  "https://upload.ngocdunggroup.com.vn/upload/images?product=MA&token=yBsmex2QT3YcSsAN5WmD9A";
export const NEWS_IMAGE_MAX_SIZE_UPLOAD = 153600; // 150KB
export const BANNER_IMAGE_MAX_SIZE_UPLOAD = 256000; // 250KB
export const MAX_SIZE_UPLOAD = 5242880;
