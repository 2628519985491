<template>
  <div>
    <a-modal
      :visible="visible"
      title="Update campaign"
      onOk="handleUpdateCampaign"
      :closable="false"
      @change="onChangeModal"
      :maskClosable="false"
    >
      <template slot="footer">
        <a-button key="back" @click="onCancel">Cancel</a-button>
        <a-button
          key="submit"
          type="primary"
          :loading="loading"
          @click="handleUpdateCampaign"
          >Update</a-button
        >
      </template>

      <a-form :form="form" @submit="handleUpdateCampaign">
        <a-row :gutter="16">
          <a-col :span="12">
            <a-form-item label="Code">
              <a-input
                v-decorator="[
                  'code',
                  {
                    initialValue: item.code,
                    rules: [{ required: true, message: 'Please input code!' }],
                  },
                ]"
              />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="Campaign name">
              <a-input
                v-decorator="[
                  'name',
                  {
                    initialValue: item.name,
                    rules: [{ required: true, message: 'Please input name!' }],
                  },
                ]"
              />
            </a-form-item>
          </a-col>
        </a-row>

        <a-row :gutter="16">
          <a-col :span="12">
            <a-form-item label="Start">
              <a-date-picker
                style="width: 100%"
                :format="dateFormat"
                :showTime="true"
                v-decorator="[
                  'start',
                  {
                    initialValue: moment(new Date(item.start), dateFormat),
                    rules: [
                      {
                        type: 'object',
                        required: true,
                        message: 'Please input start date!',
                      },
                    ],
                  },
                ]"
              />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="End">
              <a-date-picker
                style="width: 100%"
                :showTime="true"
                :format="dateFormat"
                v-decorator="[
                  'end',
                  {
                    initialValue: moment(new Date(item.end), dateFormat),
                    rules: [
                      {
                        type: 'object',
                        required: true,
                        message: 'Please input end date!',
                      },
                    ],
                  },
                ]"
              />
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </a-modal>
  </div>
</template>
<script>
/* eslint-disable no-console */

import campaign from "../../api/campaign";
import moment from "moment";
import { mapState } from "vuex";
import { getCurrentUser } from "../../utils/authentication";
import { dateFormat } from "../../utils/help";

export default {
  data() {
    return {
      user: getCurrentUser(),
      dateFormat,
      isUpdate: false,
      loading: false,
      form: this.$form.createForm(this, { name: "update_campaign_form" }),
    };
  },
  computed: {
    ...mapState({}),
  },
  model: {
    prop: "visible",
    event: "change",
  },
  props: {
    visible: Boolean,
    handleCancel: Function,
    showModal: Function,
    item: Object,
  },
  methods: {
    moment,
    onChangeModal() {
      this.form.resetFields();
      this.$emit("change", false);
    },
    onCancel() {
      this.handleCancel();
      this.form.resetFields();
    },
    handleUpdateCampaign(e) {
      e.preventDefault();
      this.form.validateFields(async (err, values) => {
        if (!err) {
          this.loading = true;
          let body = {
            id: this.item.id,
            created_by: this.item.created_by,
          };

          values["start"] = new Date(values.start._d).getTime();
          values["end"] = new Date(values.end._d).getTime();

          body = Object.assign(body, values);

          const data = await campaign.updateCampaign(body);

          if (data) {
            this.$message.success(`Update successfully`);
            this.onCancel();
            this.$store.dispatch("campaign/getAllCampaigns");
          }
        }

        this.loading = false;
      });
    },
  },
};
</script>
