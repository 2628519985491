<template>
  <div class="updateEvent">
    <a-modal
      :visible="visible"
      title="Add user"
      onOk="handleAddUser"
      :closable="false"
      :maskClosable="false"
      @change="onChangeModal"
    >
      <template slot="footer">
        <a-button key="back" @click="onCancel">Cancel</a-button>
        <a-button
          key="submit"
          type="primary"
          :loading="loading"
          @click="handleAddUser"
          >Add</a-button
        >
      </template>

      <a-form :form="form" @submit="handleAddUser">
        <a-form-item label="User name">
          <a-input
            autocomplete="new-user"
            placeholder="Input username"
            v-decorator="[
              'username',
              {
                rules: [{ required: true, message: 'Please input username!' }],
              },
            ]"
          />
        </a-form-item>
        <a-form-item label="Password">
          <a-input-password
            type="password"
            autocomplete="new-password"
            placeholder="Input password"
            v-decorator="[
              'password',
              {
                rules: [{ required: true, message: 'Please input password' }],
              },
            ]"
          />
        </a-form-item>
        <a-form-item label="Full name">
          <a-input
            placeholder="Input full name"
            v-decorator="[
              'fullname',
              {
                rules: [{ required: true, message: 'Please input fullname' }],
              },
            ]"
          />
        </a-form-item>
        <a-form-item label="Email">
          <a-input
            placeholder="Input email"
            v-decorator="[
              'email',
              { rules: [{ required: true, message: 'Please input email' }] },
            ]"
          />
        </a-form-item>
        <a-form-item label="Phone">
          <a-input
            placeholder="Input phone"
            v-decorator="[
              'phone',
              { rules: [{ required: true, message: 'Please input phone' }] },
            ]"
          />
        </a-form-item>
        <a-form-item label="Address">
          <a-input
            placeholder="Input address"
            v-decorator="[
              'address',
              {
                rules: [{ required: true, message: 'Please input address' }],
              },
            ]"
          />
        </a-form-item>

        <a-row :gutter="16">
          <a-col :span="12">
            <a-form-item label="Team">
              <a-input
                placeholder="Input team"
                v-decorator="[
                  'team',
                  {
                    rules: [{ required: true, message: 'Please input team' }],
                  },
                ]"
              />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="Department">
              <a-input
                placeholder="Input department"
                v-decorator="[
                  'department',
                  {
                    rules: [
                      { required: true, message: 'Please input department' },
                    ],
                  },
                ]"
              />
            </a-form-item>
          </a-col>
        </a-row>

        <a-row :gutter="16">
          <a-col :span="12">
            <a-form-item label="Scope">
              <a-input
                placeholder="Input scope"
                v-decorator="[
                  'scope',
                  {
                    rules: [{ required: true, message: 'Please input scope' }],
                  },
                ]"
              />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="Status">
              <a-select
                v-decorator="[
                  'status',
                  {
                    rules: [
                      { required: true, message: 'Please select status!' },
                    ],
                  },
                ]"
                placeholder="Select status"
              >
                <a-select-option
                  v-for="status in statusArray"
                  :key="status.value"
                  :value="status.value"
                >
                  {{ status.text }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>
        <a-form-item label="Rules">
          <a-select
            mode="tags"
            v-decorator="[
              'rules',
              {
                rules: [{ required: false, message: 'Please select rules!' }],
              },
            ]"
            placeholder="rules"
          >
            <a-select-option
              v-for="item in rules"
              :key="item.name"
              :value="item.name"
              >{{ item.name }}</a-select-option
            >
          </a-select>
        </a-form-item>
        <a-form-item label="Role">
          <a-select
            v-decorator="[
              'role',
              {
                rules: [{ required: true, message: 'Please select roles!' }],
              },
            ]"
            placeholder="role"
          >
            <a-select-option
              v-for="item in roles"
              :key="item.name"
              :value="item.name"
              >{{ item.name }}</a-select-option
            >
          </a-select>
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>
<script>
/* eslint-disable no-console */
import user from "../../api/user";
import { mapState } from "vuex";
import { getCurrentUser } from "../../utils/authentication";
import { statusArray, dateFormat } from "../../utils/help";

export default {
  data() {
    return {
      statusArray,
      currentUser: getCurrentUser(),
      dateFormat,
      loading: false,
      form: this.$form.createForm(this, { name: "add_user_form" }),
    };
  },

  computed: mapState({
    rules: (state) => state.rule.data,
    roles: (state) => state.role.data,
  }),
  model: {
    prop: "visible",
    event: "change",
  },
  props: {
    visible: Boolean,
    handleCancel: Function,
    showModal: Function,
  },
  methods: {
    onChangeModal() {
      this.form.resetFields();
      this.$emit("change", false);
    },
    onCancel() {
      this.handleCancel();
      this.form.resetFields();
    },
    handleAddUser(e) {
      e.preventDefault();
      this.form.validateFields(async (err, values) => {
        if (!err) {
          this.loading = true;
          let body = {
            created_by: this.currentUser.username, // create on server
          };
          body = Object.assign(body, values);

          const data = await user.createUser(body);
          if (data) {
            this.$message.success(`Add successfully`);
            this.onCancel();
            this.$store.dispatch("user/getAllUsers");
          }
          this.loading = false;
        }
      });
    },
  },
};
</script>
