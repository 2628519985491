<template>
  <div class="base">  
  <canvas ref="canvas" width="900" height="400" role="img">
    <p>11/02/2020</p>
  </canvas>

  </div>
</template>

<script>
import  { Bar } from 'vue-chartjs'
 
export default {
  created() {
    this.data.forEach((x) => {
      this.total += x
    })
  },
  extends: Bar,
  data () {
    return {
      chartOptions: {
        responsive: true, 
        maintainAspectRatio: false,
        animation: {duration: 1500},
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true     
            }
          }],
        }
      },
      data: [20, 39, 20, 10,10],
      total: 0
    }
  },
  mounted () {
    this.renderChart({
      labels: ['0915002721', '0988024602', '0365169795', '0971965596'],
      datasets: [
        {
          borderSkipped: false,
          label: `Total ticket today: ${this.total}`,
          backgroundColor: [
            'rgba(255, 99, 132, 0.2)',
            'rgba(54, 162, 235, 0.2)',
            'rgba(255, 206, 86, 0.2)',
            'rgba(75, 192, 192, 0.2)',
            'rgba(153, 102, 255, 0.2)',
            'rgba(255, 159, 64, 0.2)'
          ],
          borderColor: [
            'rgba(255, 99, 132, 1)',
            'rgba(54, 162, 235, 1)',
            'rgba(255, 206, 86, 1)',
            'rgba(75, 192, 192, 1)',
            'rgba(153, 102, 255, 1)',
            'rgba(255, 159, 64, 1)'
          ],
          borderWidth: 1,
          data: this.data,
          
          
        },
      ]
    }, this.chartOptions)
  }
  
}

</script>