<template>
  <div>
    <a-result
      status="success"
      title="Authentication Successfully!"
      v-if="status === 'success'"
    ></a-result>

    <a-result title="Validating" v-if="status === 'validating'">
      <template slot="icon">
        <a-icon type="loading" />
      </template>
    </a-result>

    <a-result
      status="error"
      title="Authentication Error!"
      v-if="status === 'error'"
    ></a-result>
  </div>
</template>

<script>
import { code } from "../api";
import { PORTAL_WEB_URL } from "../config";
/* eslint-disable no-console */

import { portalLogin } from "../utils/authentication";

export default {
  async created() {
    await this.login();
  },
  data() {
    return {
      status: "validating",
    };
  },
  methods: {
    async login() {
      const { access_token } = this.$route.query;
      const body = { access_token, product: "ngocdung" };
      const response = await portalLogin(body);
      if (response.code === code.success) {
        this.status = "success";

        localStorage.setItem("imapi_login_user", JSON.stringify(response.data));
        window.location.replace("/");
      } else {
        this.status = "error";
        const redirect_url = `${PORTAL_WEB_URL}/dashboard?code=${response.code}&message=${response.message}
        &product=ngocdung&domain=voucher`;
        window.location.replace(redirect_url);
      }
    },
  },
};
</script>

<style></style>
