<script>
export default {
  name: 'PageNotFound',
  methods: {
    goBack() {
      this.$router.replace('/')
    }
  }
}
</script>

<template>
  <a-row type="flex" justify="center" align="middle" class="container"> 
    <a-col>
      <h1>404</h1>
      <h2>Không tìm thấy trang! </h2>
      <p>Vui lòng thử lại</p>
      <a-button @click="goBack" type="primary">Quay về</a-button>
    </a-col>
  </a-row>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.container {
  text-align: center;
  height: 100vh;
  font-family: 'Times New Roman', Times, serif
}
.container h1 {
  font-size: 3.75rem;
  color: #cacedb;
  font-weight: 700;
  margin-bottom: 0;
}
.container h2 {
  font-size: 2.1875rem;
  color: #3d5170;
  font-weight: 500;
  margin-bottom: .625rem;

}
.container p {
  margin-bottom: 1.75rem ;
  color: #818ea3;
}
</style>