<template>
  <div>
    <a-modal
      :visible="visible"
      title="Add voucher"
      onOk="handleAddVoucher"
      :closable="false"
      @change="onChangeModal"
      :maskClosable="false"
    >
      <template slot="footer">
        <a-button key="back" @click="onCancel">Cancel</a-button>
        <a-button
          key="submit"
          type="primary"
          :loading="loading"
          @click="handleAddVoucher"
          >Add</a-button
        >
      </template>

      <a-form :form="form" @submit="handleAddVoucher">
        <a-form-item label="Total">
          <a-input
            v-decorator="[
              'total',
              {
                rules: [{ required: true, message: 'Please input code!' }],
              },
            ]"
          />
        </a-form-item>

        <a-row :gutter="16">
          <a-col :span="12">
            <a-form-item label="Master code">
              <a-input
                v-decorator="[
                  'master_code',
                  {
                    initialValue: master_code,
                    rules: [
                      { required: true, message: 'Please input master_code!' },
                    ],
                  },
                ]"
              />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="Status">
              <a-select
                v-decorator="[
                  'status',
                  {
                    rules: [
                      { required: true, message: 'Please select status!' },
                    ],
                  },
                ]"
                placeholder="Select status"
              >
                <a-select-option
                  v-for="status in statusArray"
                  :key="status.value"
                  :value="status.value"
                  >{{ status.text }}</a-select-option
                >
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>
        <a-form-item label="Note">
          <a-textarea
            placeholder="Add note"
            v-decorator="['note', { initialValue: '' }]"
          ></a-textarea>
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>
<script>
/* eslint-disable no-console */
import voucher from "../../api/voucher";
import moment from "moment";
import { mapState } from "vuex";
import { getCurrentUser } from "../../utils/authentication";
import { statusArray, dateFormat } from "../../utils/help";

export default {
  data() {
    return {
      isMultiple: false,
      statusArray,
      user: getCurrentUser(),
      dateFormat,
      loading: false,
      form: this.$form.createForm(this, { name: "add_voucher_form" }),
    };
  },

  computed: {
    ...mapState({
      total: (state) => state.voucher.vouchersDetailTotal,
    }),
  },
  model: {
    prop: "visible",
    event: "change",
  },
  props: {
    visible: Boolean,
    handleCancel: Function,
    showModal: Function,
    pageSize: Number,
    master_code: String,
    handleChangePage: Function,
  },
  methods: {
    moment,
    onChangeModal() {
      this.form.resetFields();
      this.$emit("change", false);
    },
    onCancel() {
      this.handleCancel();
      this.form.resetFields();
    },
    handleChangeMultiple(e) {
      this.isMultiple = e.target.checked;
    },
    handleAddVoucher(e) {
      e.preventDefault();
      this.form.validateFields(async (err, values) => {
        if (!err) {
          this.loading = true;
          var data;
          let body = {
            created_by: this.user.data_token.username, // create on server
          };
          body = Object.assign(body, values);

          if (this.isMultiple) {
            data = await voucher.createMultipleVoucher(body);
          } else {
            data = await voucher.createVoucher(body);
          }

          if (data) {
            this.$message.success(`Add successfully`);
            this.onCancel();
            let currentPage = Math.round(this.total / this.pageSize);
            if (currentPage <= 0) currentPage = 1;
            this.handleChangePage(currentPage);
          }

          this.loading = false;
        }
      });
    },
  },
};
</script>
