<template>
  <a-form :form="form" class="login-form" @submit="handleSubmit">
    <a-form-item>
      <a-input
        v-decorator="[
          'userName',
          {
            rules: [{ required: true, message: 'Please input your username!' }],
          },
        ]"
        placeholder="Username"
        @focus="inputFocus"
      >
        <a-icon slot="prefix" type="user" style="color: rgba(0, 0, 0, 0.25)" />
      </a-input>
    </a-form-item>
    <a-form-item>
      <a-input
        v-decorator="[
          'password',
          {
            rules: [{ required: true, message: 'Please input your Password!' }],
          },
        ]"
        type="password"
        placeholder="Password"
        @focus="inputFocus"
      >
        <a-icon slot="prefix" type="lock" style="color: rgba(0, 0, 0, 0.25)" />
      </a-input>
    </a-form-item>

    <a-form-item>
      <a-select
        v-decorator="[
          'product',
          { rules: [{ required: true, message: 'Please select product!' }] },
        ]"
        placeholder="Select product"
      >
        <a-select-option value="ngocdung">Ngoc Dung</a-select-option>
        <a-select-option value="zema">Zema</a-select-option>
        <a-select-option value="laciana">Laciana</a-select-option>
        <a-select-option value="zasaly">Zasaly</a-select-option>
      </a-select>
    </a-form-item>

    <a-form-item>
      <a-checkbox
        v-decorator="[
          'remember',
          {
            valuePropName: 'checked',
            initialValue: true,
          },
        ]"
        >Remember me</a-checkbox
      >
      <a class="login-form-forgot" href>Forgot password</a>
      <a-button
        type="primary"
        html-type="submit"
        :loading="loading"
        class="login-form-button"
        >Log in</a-button
      >Or
      <a href>register now!</a>
    </a-form-item>
    <a-alert
      type="error"
      v-bind:message="message"
      v-if="message !== ''"
      banner
    />
  </a-form>
</template>

<script>
/* eslint-disable no-console */

import login from "../utils/authentication";
import { code } from "../api";
import { route } from "../router";

export default {
  beforeCreate() {
    this.form = this.$form.createForm(this, { name: "normal_login" });
  },
  data: function () {
    return {
      message: "",
      loading: false,
    };
  },
  methods: {
    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields(async (err, values) => {
        if (!err) {
          this.loading = true;
          const product = values.product;
          let user = await login({
            username: values.userName,
            password: values.password,
            product,
            expiresIn: "30y",
          });
          if (user.code === code.success) {
            localStorage.setItem("imapi_login_user", JSON.stringify(user.data));
            window.location.reload();
            setTimeout(() => {
              this.$router.replace(route.home);
              this.loading = false;
            }, 1000);
          } else {
            this.message = user.message;
            this.loading = false;
          }
        }
      });
    },
    inputFocus() {
      this.message = "";
    },
  },
};
</script>
<style scoped>
.login-form-forgot {
  float: right;
}
.login-form-button {
  width: 100%;
}
</style>
