<template>
  <div>
    <a-modal
      :visible="visible"
      title="Change password"
      onOk="handleChangePassword"
      :closable="false"
      @change="onChangeModal"
      :maskClosable="false"
    >
      <template slot="footer">
        <a-button key="back" @click="onCancel">Cancel</a-button>
        <a-button
          key="submit"
          type="primary"
          :loading="loading"
          @click="handleChangePassword"
          >Submit</a-button
        >
      </template>
      <a-form :form="form" @submit="handleChangePassword">
        <a-form-item label="User name">
          <a-input
            autocomplete="new-user"
            :disabled="true"
            v-decorator="[
              'username',
              {
                initialValue: user.data_token.username,
                rules: [{ required: true, message: 'Please input username!' }],
              },
            ]"
          />
        </a-form-item>
        <a-form-item label="Old password">
          <a-input
            autocomplete="old-password"
            type="password"
            placeholder="Old password"
            v-decorator="[
              'old_password',
              {
                rules: [
                  { required: true, message: 'Please input old password!' },
                ],
              },
            ]"
          />
        </a-form-item>
        <a-form-item label="New password">
          <a-input
            autocomplete="new-password"
            type="password"
            placeholder="New password"
            v-decorator="[
              'new_password',
              {
                rules: [
                  { required: true, message: 'Please input new password!' },
                ],
              },
            ]"
          />
        </a-form-item>
        <a-form-item
          label="Confirm new password"
          :help="confirmPassError"
          :validateStatus="confirmPassError === undefined ? undefined : 'error'"
        >
          <a-input
            autocomplete="confirm-new-password"
            type="password"
            placeholder="Confirm new password"
            v-decorator="[
              'confirm_new_password',
              {
                rules: [
                  { required: true, message: 'Please confirm password!' },
                ],
              },
            ]"
          />
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>
<script>
/* eslint-disable no-console */
import { mapState } from "vuex";
import User from "../api/user";

export default {
  data() {
    return {
      isMultiple: false,
      loading: false,
      form: this.$form.createForm(this, { name: "change_password_form" }),
      confirmPassError: undefined,
    };
  },

  computed: {
    ...mapState({}),
  },
  model: {
    prop: "visible",
    event: "change",
  },
  props: {
    visible: Boolean,
    handleCancel: Function,
    showModal: Function,
    user: Object,
  },
  methods: {
    checkConfirmPassword(values) {
      if (values.new_password !== values.confirm_new_password) {
        this.confirmPassError = "Confirm password is incorrect";
        return false;
      } else {
        this.confirmPassError = undefined;
        return true;
      }
    },
    onChangeModal() {
      this.form.resetFields();
      this.$emit("change", false);
    },
    onCancel() {
      this.handleCancel();
      this.form.resetFields();
    },
    handleChangePassword(e) {
      e.preventDefault();
      this.form.validateFields(async (err, values) => {
        if (!err) {
          const isSuceess = this.checkConfirmPassword(values);
          if (!isSuceess) return;

          this.loading = true;
          const data = await User.changePassword({
            username: this.user.data_token.username,
            password: values.old_password,
            new_password: values.new_password,
          });
          if (data) {
            this.$message.success(`Change password successfully`);
          }

          this.loading = false;
        }
      });
    },
  },
};
</script>
